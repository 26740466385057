import { createGlobalStyle, css } from 'styled-components';

export default createGlobalStyle<{ $isPreviewMode?: boolean }>`
    :root {
        --font-size: 10px;
        --gutter-width: 2.4rem;
        --half-gutter-width: calc((var(--gutter-width) * 0.5));
        --gutter-compensation: calc(var(--half-gutter-width) * -1);

        @media screen and (min-width: ${({ theme }) => `${theme.breakpointSmall}px`}) {
            --gutter-width: 3.2rem;
        }
    }

    html {
        font-size: var(--font-size);
    }

    body {
        ${({ theme }) => css`
            font-size: ${theme.sizeTextBodySmall};
            font-family: ${theme.fontBodyFamily};
            font-weight: ${theme.fontBodyWeight};
            text-transform: ${theme.fontBodyTransform};
        `};

        max-width: 100vw;
        line-height: 1.5;
        margin: 0;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: ${({ theme }) => theme.colors.textBody};

        @media (min-width: ${({ theme }) => `${theme.breakpointExtraLarge}px`}) {
            font-size: ${({ theme }) => theme.sizeTextBodyMedium};
        }
    }

    a {
        color: inherit;
    }

    // Modifiers
    .overflow-hidden {
        overflow: hidden;
    }

    // this is for the inline code block for the salesforce form.
    .iframe-container {
        position: relative;
        overflow: hidden;
        width: 100%;
        padding-top: 60%;
        height: 24rem;

        @media (min-width: ${({ theme }) => `${theme.breakpointSmall}px`}) {
            padding-top: 30%;
            height: 14rem;
        }

        @media (min-width: ${({ theme }) => `${theme.breakpointMedium}px`}) {
            padding-top: 26%;
        }
    }

    // this is for the inline code block for the salesforce form.
    .responsive-iframe {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        border: 0 solid transparent;
    }

    // cookie consent
    ${({ $isPreviewMode }) =>
        $isPreviewMode &&
        css`
            #onetrust-consent-sdk {
                display: none;
            }
        `}

    .optanon-alert-box-wrapper .optanon-alert-box-button-middle {
        background: ${({ theme }) => theme.colors.wrapperBanner}!important;
        border: ${({ theme }) =>
            `${theme.sizeOtherBorderWidthS} solid ${theme.colors.wrapperBannerBorder}!important`};
    }

    .optanon-alert-box-wrapper .optanon-alert-box-button-middle button {
        font-size: ${({ theme }) => theme.sizeTextBodySmall}!important;
    }

    .optanon-alert-box-button-middle.accept-cookie-container {
        border-radius: ${({ theme }) => theme.sizeOtherBorderRadiusButton}!important;
    }

    .optanon-alert-box-wrapper .optanon-alert-box-button-middle button.optanon-allow-all.accept-cookies-button {
        color: ${({ theme }) => theme.colors.actionSecondaryText}!important;
    }

    @media (min-width: ${({ theme }) => `${theme.breakpointExtraLarge}px`}) {
        .optanon-alert-box-wrapper .optanon-alert-box-body {
            margin-left: calc((100% - ${({ theme }) =>
                theme.sizeGridLayoutMaxWidthXxl})/2)!important;
            margin-right: calc((100% - ${({ theme }) =>
                theme.sizeGridLayoutMaxWidthXxl})/2 + 40rem)!important;
        }
        .optanon-alert-box-bg .optanon-alert-box-button-container {
            right: calc((100% - ${({ theme }) =>
                theme.sizeGridLayoutMaxWidthXxl})/2 + 1.6rem)!important;
        }
        .optanon-alert-box-wrapper .optanon-alert-box-corner-close {
            right: calc((100% - ${({ theme }) => theme.sizeGridLayoutMaxWidthXxl})/2)!important;
        }
    }
    @media only screen and (max-width: 568px) {
        #survicate-fb-box .survicate_button {
            top: 50% !important;
            transform: translateY(-50%) !important;
            bottom: unset !important;
        }
    }
    
    // help icon on checkout
    @media only screen and (max-width: 992px) {
        #launcher {
            bottom: 7rem !important;
        }
    }
    
    // assigned style for help icon in /checkout to override null style from injected script
    // this happens when the help icon is being altered so we reset here.
    .launcher-style {
        color-scheme: light;
        width: 48px;
        height: 50px;
        padding: 0px;
        margin: 10px 20px;
        position: fixed;
        bottom: 0px;
        overflow: visible;
        opacity: 1;
        border: 0px;
        z-index: 999998;
        transition-duration: 250ms;
        transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
        transition-property: opacity, top, bottom;
        right: 0px;
    }
`;
