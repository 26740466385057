import { siteStructureRoute, ensureCorrectCountryLanguage, productLineRoute } from '../getRoute';
import { MultiLink } from '../types/storyBlokComponentTypes';
import isExternalUrl from './isExternalUrl';

const createLink = (
    link: MultiLink | undefined | null | string,
    country: string,
    language: string
): string | undefined => {
    if (!link) {
        return undefined;
    }

    if (typeof link === 'string') {
        return link;
    }

    const url = link.url ?? link.href;

    switch (link.linktype) {
        case 'email': {
            if (url) {
                return `mailto:${url}`;
            }
            return undefined;
        }
        case 'asset':
        case 'url': {
            if (url) {
                return isExternalUrl(url)
                    ? url
                    : ensureCorrectCountryLanguage(country, language, url);
            }
            return undefined;
        }
        case 'story': {
            // @TODO: fetch translated `story.full_slug` for story by `id` OR `uuid` for the selected language if story is not defined
            const storyFullSlug = link.story?.full_slug;
            if (storyFullSlug) {
                if (storyFullSlug.includes('productlines')) {
                    return productLineRoute(country, language, storyFullSlug); // e.g. /IT/productlines/xelius-sl/
                }
                return siteStructureRoute({ country, language, fullSlug: storyFullSlug });
            }

            if (url) {
                return ensureCorrectCountryLanguage(country, language, url);
            }

            return undefined;
        }
        default: {
            if (url) {
                return url;
            }
            return undefined;
        }
    }
};

export default createLink;
