import React from 'react';
import { Link, GatsbyLinkProps as LinkProps } from 'gatsby';
import isExternalUrl from '../services/isExternalUrl';

export interface GatsbyLinkProps<TState = {}> extends React.PropsWithoutRef<LinkProps<TState>> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ref?: React.LegacyRef<any>;
}

const GatsbyLink: React.FunctionComponent<GatsbyLinkProps> = ({
    to,
    children,
    ...props
}: GatsbyLinkProps) => {
    const isExternalLink = typeof to === 'string' && isExternalUrl(to ?? '');

    return isExternalLink ? (
        <a href={to ?? ''} rel="noreferrer" {...props}>
            {children}
        </a>
    ) : (
        <Link to={to ?? ''} {...props}>
            {children}
        </Link>
    );
};

export default GatsbyLink;
