// Generated file, do not edit.
// Edit the json files in the designTokens directory and run the build-design-tokens.js script.

export default {
    optionsBrandColorGrey: '#9A9B9D',
    optionsBrandColorColor3: '#0E74BA',
    optionsBrandColorColor4: '#2989F1',
    optionsBrandColorColor5: '#007B4D',
    optionsBrandColorColor6: '#20A954',
    optionsBrandColorColor7: '#63E761',
    optionsBrandColorColor8: '#F192BC',
    optionsBrandColorColor9: '#E74033',
    optionsBrandColorColor10: '#D0A045',
    optionsBrandColorColor11: '#F0CA00',
    optionsBrandColorColor12: '#FFEB3B',
    optionsBrandColorIndigoBlue: '#34428A',
    optionsBrandColorAzureBlue: '#0E74BA',
    optionsBrandColorForestGreen: '#007B4D',
    optionsBrandColorParakeetGreen: '#20A954',
    optionsBrandColorCrimsonRed: '#E74033',
    optionsBrandColorPunchPink: '#EB5B7F',
    optionsBrandColorPearlPink: '#F192BC',
    optionsBrandColorHoneyYellow: '#F0CA00',
    optionsBrandColorPumpkinOrange: '#F0821F',
    optionsBrandColorBlue: '#34428A',
    optionsBrandColorGreen: '#007B4D',
    optionsBrandColorRed: '#E74033',
    optionsBrandColorYellow: '#D0A045',
    optionsBrandColorWhite: '#FFFFFF',
    optionsBrandColorXlcSand: '#A1A1A1',
    optionsBrandColorXlcGrey: '#6A798C',
    optionsBrandColorBlueGradient: 'linear-gradient(180deg, #002AB0 0%, #002495 100%)',
    optionsBrandColorGreenGradient: 'linear-gradient(180deg, #019765 0%, #007B4D 100%)',
    optionsBrandColorYellowGradient: 'linear-gradient(180deg, #FCD200 0%, #FCC800 100%)',
    optionsBrandColorXlcBlue: '#0052cc',
    optionsBrandColorXlcBlack: '#000000',
    optionsBrandColorPrimaryDefault: '#0000FF',
    optionsBrandColorPrimaryDark: '#0000CD',
    optionsBrandColorSecondaryDefault: '#666699',
    optionsBrandColorSecondaryDark: '#52527b',
    optionsNeutralColorBlack: '#000000',
    optionsNeutralColorGrey900: '#121212',
    optionsNeutralColorGrey800: '#181818',
    optionsNeutralColorGrey750: '#1E1E1E',
    optionsNeutralColorGrey700: '#333333',
    optionsNeutralColorGrey600: '#4F4F4F',
    optionsNeutralColorGrey500: '#727272',
    optionsNeutralColorGrey400: '#999999',
    optionsNeutralColorGrey300: '#6A798C',
    optionsNeutralColorGrey200: '#E3E3E3',
    optionsNeutralColorGrey100: '#F3F3F3',
    optionsNeutralColorGrey50: '#F9F9F9',
    optionsNeutralColorGrey150: '#F8F8F8',
    optionsNeutralColorWhite: '#FFFFFF',
    optionsFormColorCheck: '#0073BE',
    optionsStatusColorSuccessLight: '#e7fef6',
    optionsStatusColorSuccessMain: '#08875b',
    optionsStatusColorSuccessDark: '#08875b',
    optionsStatusColorWarningLight: '#fff3cc',
    optionsStatusColorWarningMain: '#ffa100',
    optionsStatusColorWarningDark: '#ffa100',
    optionsStatusColorErrorLight: '#ffe5ea',
    optionsStatusColorErrorMain: '#e4002b',
    optionsStatusColorErrorDark: '#e4002b',
    optionsStatusColorInfoLight: '#EFF6FF',
    optionsStatusColorInfoMain: '#0073BE',
    optionsStatusColorInfoDark: '#1244CA',
    optionsFontFamilySansSerifOne: "'Inter', sans-serif;",
    optionsFontFamilySansSerifTwo: "'Inter', sans-serif;",
    optionsFontFamilySansSerifThree: "'Inter', sans-serif;",
    optionsFontWeightLight: '400',
    optionsFontWeightRegular: '400',
    optionsFontWeightBold: '500',
    optionsFontWeightExtraBold: '700',
    optionsTextTransformNormal: 'none',
    optionsTextTransformLowercase: 'lowercase',
    optionsTextTransformUppercase: 'uppercase',
    optionsLetterSpacingNormal: '0',
    optionsLetterSpacingWide: '.075em',
    optionsLetterSpacingMediumWide: '.1em',
    optionsLetterSpacingExtraWide: '.125em',
    optionsFontUrlGoogle: [{ family: 'Inter', variants: ['400', '700', '800'] }],
    optionsMediaQueryXs: 576,
    optionsMediaQueryS: 768,
    optionsMediaQueryM: 992,
    optionsMediaQueryL: 1200,
    optionsMediaQueryXl: 1600,
    additionalMotionEaseOut: 'cubic-bezier(0, 0, 0.25, 1)',
    additionalMotionEaseIn: 'cubic-bezier(0.50, 0, 1, 1)',
    additionalMotionEaseInOut: 'cubic-bezier(0.25, 0.75, 0.25, 0.75)',
    additionalCarouselMixBlendMode: 'multiply',
    additionalSmartfitCtaTextColor: '#FFFFFF',
    additionalSmartfitPrimaryColor: '#0052cc',
    additionalProductDescriptionTextColor: 'inherit',
    additionalProductDescriptionBackgroundColor: 'transparent',
    additionalKeyFeatureListSupertitleBorderColor: 'transparent',
    breakpointExtraSmall: 576,
    breakpointSmall: 768,
    breakpointMedium: 992,
    breakpointLarge: 1200,
    breakpointExtraLarge: 1600,
    fontTitleFamily: "'Inter', sans-serif;",
    fontTitleWeight: '400',
    fontTitleTransform: 'none',
    fontTitleLetterSpacing: '0',
    fontTitleHighlightFamily: "'Inter', sans-serif;",
    fontTitleHighlightWeight: '500',
    fontTitleHighlightTransform: 'none',
    fontTitleHighlightLetterSpacing: '0',
    fontHeadlineOneFamily: "'Inter', sans-serif;",
    fontHeadlineOneWeight: '500',
    fontHeadlineOneTransform: 'none',
    fontHeadlineOneLetterSpacing: '0',
    fontHeadlineTwoFamily: "'Inter', sans-serif;",
    fontHeadlineTwoWeight: '500',
    fontHeadlineTwoTransform: 'none',
    fontHeadlineTwoLetterSpacing: '0',
    fontHeadlineThreeFamily: "'Inter', sans-serif;",
    fontHeadlineThreeWeight: '500',
    fontHeadlineThreeTransform: 'none',
    fontHeadlineThreeLetterSpacing: '0',
    fontSubHeadlineFamily: "'Inter', sans-serif;",
    fontSubHeadlineWeight: '500',
    fontSubHeadlineTransform: 'none',
    fontSubHeadlineLetterSpacing: '0',
    fontTitleOverlayFamily: "'Inter', sans-serif;",
    fontTitleOverlayWeight: '400',
    fontTitleOverlayTransform: 'none',
    fontTitleOverlayLetterSpacing: '0',
    fontMainNavItemFamily: "'Inter', sans-serif;",
    fontMainNavItemWeight: '500',
    fontMainNavItemTransform: 'none',
    fontMainNavItemLetterSpacing: '0',
    fontBodyFamily: "'Inter', sans-serif;",
    fontBodyWeight: '400',
    fontBodyTransform: 'none',
    fontBodyLetterSpacing: '0',
    fontCaptionFamily: "'Inter', sans-serif;",
    fontCaptionWeight: '400',
    fontCaptionTransform: 'none',
    fontCaptionLetterSpacing: '0',
    fontButtonFamily: "'Inter', sans-serif;",
    fontButtonWeight: '400',
    fontButtonWeightSmall: '400',
    fontButtonTransform: 'none',
    fontButtonLetterSpacing: '0',
    fontLinkFamily: "'Inter', sans-serif;",
    fontLinkWeight: '400',
    fontLinkTransform: 'none',
    fontLinkLetterSpacing: '0',
    fontPriceFamily: "'Inter', sans-serif;",
    fontPriceWeight: '400',
    fontPriceTransform: 'none',
    fontPriceLetterSpacing: '0',
    fontHeroTitleLetterSpacing: '0',
    fontLineHeightExtraSmall: '0.875',
    fontLineHeightSmall: '1',
    fontLineHeightMedium: '1.15',
    fontLineHeightLarge: '1.5',
    fontPlpCategoryLabelsTextTransform: '',
    fontPlpCategoryLabelsFontWeight: '',
    fontPlpUpillSortingTextTransform: '',
    fontPlpUpillSortingFontWeight: '',
    sizeTextHeadlineSizeOneSmall: '4rem',
    sizeTextHeadlineSizeOneMedium: '8rem',
    sizeTextHeadlineSizeTwoSmall: '3.5rem',
    sizeTextHeadlineSizeTwoMedium: '6.4rem',
    sizeTextHeadlineSizeThreeSmall: '2.9rem',
    sizeTextHeadlineSizeThreeMedium: '4.8rem',
    sizeTextHeadlineSizeFourSmall: '2.3rem',
    sizeTextHeadlineSizeFourMedium: '2.3rem',
    sizeTextHeadlineSizeFiveSmall: '2rem',
    sizeTextHeadlineSizeFiveMedium: '2rem',
    sizeTextHeadlineSizeSixSmall: '1.8rem',
    sizeTextHeadlineSizeSixMedium: '1.8rem',
    sizeTextOverlayTitleExtraSmall: 'calc((1rem + 0.25vw) * 2.2)',
    sizeTextOverlayTitleSmall: 'calc((1rem + 0.25vw) * 4)',
    sizeTextOverlayTitleMedium: 'calc((1rem + 0.25vw) * 5.6)',
    sizeTextOverlayTitleLarge: 'calc((1rem + 0.25vw) * 7.2)',
    sizeTextBodyExtraSmall: '1.2rem',
    sizeTextBodySmall: '1.4rem',
    sizeTextBodyMedium: '1.6rem',
    sizeTextBodyLarge: '1.8rem',
    sizeTextBodyExtraLarge: '2.4rem',
    sizeGridSpacingBlockMedium: '4rem',
    sizeGridSpacingBlockLarge: '8rem',
    sizeGridSpacingRem2: '0.2rem',
    sizeGridSpacingRem4: '0.4rem',
    sizeGridSpacingRem6: '0.6rem',
    sizeGridSpacingRem8: '0.8rem',
    sizeGridSpacingRem10: '1rem',
    sizeGridSpacingRem12: '1.2rem',
    sizeGridSpacingRem14: '1.4rem',
    sizeGridSpacingRem16: '1.6rem',
    sizeGridSpacingRem20: '2rem',
    sizeGridSpacingRem24: '2.4rem',
    sizeGridSpacingRem28: '2.8rem',
    sizeGridSpacingRem32: '3.2rem',
    sizeGridSpacingRem36: '3.6rem',
    sizeGridSpacingRem40: '4rem',
    sizeGridSpacingRem44: '4.4rem',
    sizeGridSpacingRem48: '4.8rem',
    sizeGridSpacingRem52: '5.2rem',
    sizeGridSpacingRem56: '5.6rem',
    sizeGridSpacingRem60: '6rem',
    sizeGridSpacingRem64: '6.4rem',
    sizeGridLayoutRem24: '2.4rem',
    sizeGridLayoutRem32: '3.2rem',
    sizeGridLayoutRem40: '4rem',
    sizeGridLayoutRem48: '4.8rem',
    sizeGridLayoutRem56: '5.6rem',
    sizeGridLayoutRem64: '6.4rem',
    sizeGridLayoutRem72: '7.2rem',
    sizeGridLayoutRem80: '8rem',
    sizeGridLayoutRem88: '8.8rem',
    sizeGridLayoutRem96: '9.6rem',
    sizeGridLayoutRem104: '10.4rem',
    sizeGridLayoutRem120: '12rem',
    sizeGridLayoutMaxWidthXxs: '32rem',
    sizeGridLayoutMaxWidthXs: '37.5rem',
    sizeGridLayoutMaxWidthS: '37.5rem',
    sizeGridLayoutMaxWidthM: '61.6rem',
    sizeGridLayoutMaxWidthL: '86.4rem',
    sizeGridLayoutMaxWidthXl: '160rem',
    sizeGridLayoutMaxWidthXxl: '160rem',
    sizeOtherIconXxs: '1.2rem',
    sizeOtherIconXs: '1.6rem',
    sizeOtherIconS: '2rem',
    sizeOtherIconM: '2.4rem',
    sizeOtherIconL: '3.2rem',
    sizeOtherIconXl: '4rem',
    sizeOtherBorderRadiusS: '0rem',
    sizeOtherBorderRadiusM: '0rem',
    sizeOtherBorderRadiusL: '1.6rem',
    sizeOtherBorderRadiusFull: '99rem',
    sizeOtherBorderRadiusButton: '0rem',
    sizeOtherBorderWidthS: '0.1rem',
    sizeOtherBorderWidthXs: '0.1rem',
    sizeOtherLogoWidth: '15.8rem',
    sizeOtherLogoHeight: '1.6rem',
    svgRatingsStarPath:
        'M5.29912851,4.57520277 L0.552479625,5.25223982 L0.47915698,5.26674626 C0.00513697679,5.38775526 -0.168956388,5.98274547 0.195319258,6.33131796 L3.63001442,9.61792143 L2.81928166,14.2590186 L2.81088573,14.3286826 C2.77905682,14.8103298 3.30304684,15.1588353 3.75433964,14.9259255 L8,12.7349048 L12.2456604,14.9259255 L12.3105574,14.9552915 C12.7673486,15.133845 13.2669076,14.7523294 13.1807183,14.2590186 L12.3693411,9.61792143 L15.8046807,6.33131796 L15.8553141,6.277274 C16.166349,5.90586074 15.9509369,5.3240497 15.4475204,5.25223982 L10.700227,4.57520277 L8.57789761,0.352651397 C8.34150615,-0.117550466 7.65849385,-0.117550466 7.42210239,0.352651397 L5.29912851,4.57520277 Z',
    svgRatingsStarViewBox: '0 0 16 15',
    svgRatingsStarRatedColor: '#F0CA00',
    svgRatingsStarEmptyColor: '#E3E3E3',
    svgRatingsStarWidgetSpacing: '0.2rem',
    svgQuoteIconPath:
        'M64.3644 5.34058e-05L62.1307 13.0398C61.4868 16.7425 60.1385 20.5256 58.086 24.3893C55.9932 28.2529 53.4778 31.8952 50.5398 35.3161C47.5616 38.737 44.4626 41.5945 41.2429 43.8885L31.1009 37.1876C33.9584 33.6459 36.4335 29.9432 38.5263 26.0796C40.6191 22.216 42.0479 17.9096 42.8125 13.1606L45.0462 5.34058e-05H64.3644ZM33.8779 5.34058e-05L31.6442 13.0398C31.0003 16.7425 29.652 20.5256 27.5995 24.3893C25.5067 28.2529 22.9913 31.8952 20.0533 35.3161C17.0751 38.737 13.9761 41.5945 10.7564 43.8885L0.614384 37.1876C3.47186 33.6459 5.947 29.9432 8.03981 26.0796C10.1326 22.216 11.5613 17.9096 12.326 13.1606L14.5597 5.34058e-05H33.8779Z',
    svgQuoteIconViewBox: '0 0 65 44',
    svgQuoteIconWidth: 65,
    svgQuoteIconHeight: 44,
    svgQuoteIconFill: '#000000',
    svgMyAccountPath:
        'M5.517,6.252q.338.364.628.7l4.656.549,5.63,2.072V14.16c0,1.871-5.61,3.339-7.184,3.7L4.83,16.236c-.193.237-.364.449-.5.623L5,18.628,6.462,21.6c3.514-.962,10.711-2.552,13.606-4.572,2.76-1.926,3.481-5.095,2.962-8.277-1.037-6.358-3.978-7.509-10.157-7.305C9.666,1.546,4.617,3,1.529,4c0,.247.035.385.035.493,1.935.039,2.035.113,3.97.176M7.29,8.525a4.555,4.555,0,0,1,.663,1.95c.141,1.782-1.079,3.317-2.161,4.6L9.341,16.38c1.037-.248,5.665-1.4,5.665-2.221V10.57L10.469,8.9,7.29,8.525Zm-3.2-2.54c-.961-.031-1.915-.076-2.878-.076a5.252,5.252,0,0,1-1.07-.044C.1,5.143,0,3.908,0,3.145c0-.12.653-.361,1.095-.5C4.325,1.6,9.468.131,12.828.02c6.923-.228,10.454,1.438,11.606,8.5.61,3.743-.324,7.418-3.552,9.67C17.39,20.624,10,22.1,5.7,23.276L2.535,16.828c1.164-1.5,4.136-4.474,4-6.241C6.424,9.243,4.979,7.752,4.091,6.813V5.985Z',
    svgMyAccountViewBox: '0 0 24.609 23.275',
    svgMyAccountWidth: 24.609,
    svgMyAccountHeight: 23.275,
    svgMyAccountFill: 'currentColor',
    svgCartPath:
        'M19.896,7.79423077 L15.44,0 L13.36,1.18730769 L17.136,7.79423077 L6.864,7.79423077 L10.64,1.18730769 L8.56,0 L4.104,7.79423077 L0,7.79423077 L0,10.2173077 L1.44,10.2173077 L3.632,20.0630769 C3.75840467,20.611453 4.24233195,20.9996402 4.8,21 L19.2,21 C19.7576681,20.9996402 20.2415953,20.611453 20.368,20.0630769 L22.56,10.2011538 L24,10.2011538 L24,7.77807692 L19.896,7.79423077 Z M8.4,17.3976923 L6,17.3976923 L6,11.3965385 L8.4,11.3965385 L8.4,17.3976923 Z M13.2,17.3976923 L10.8,17.3976923 L10.8,11.3965385 L13.2,11.3965385 L13.2,17.3976923 Z M18,17.3976923 L15.6,17.3976923 L15.6,11.3965385 L18,11.3965385 L18,17.3976923 Z',
    svgCartViewBox: '0 0 24 21',
    svgCartWidth: 24,
    svgCartHeight: 21,
    svgCartFill: 'currentColor',
    svgSearchPath:
        'M6.47619048,0 C10.0528917,0 12.952381,2.89948924 12.952381,6.47619048 C12.952381,8.08184217 12.3680501,9.55101606 11.4004383,10.6826621 C11.6006024,10.6451975 11.8114518,10.6783226 11.9945136,10.782419 L12.1007085,10.8533965 L12.1840949,10.9270162 L15.7396505,14.4825718 C16.0867832,14.8297045 16.0867832,15.3925178 15.7396505,15.7396505 C15.4507072,16.0285937 15.0081634,16.079327 14.6517434,15.8690295 L14.5476409,15.7980865 L14.4825718,15.7396505 L10.9270162,12.1840949 C10.713477,11.9705557 10.6312967,11.6754005 10.6804755,11.3990417 C9.55101606,12.3680501 8.08184217,12.952381 6.47619048,12.952381 C2.89948924,12.952381 0,10.0528917 0,6.47619048 C0,2.89948924 2.89948924,0 6.47619048,0 Z M6.47619048,1.77777778 C3.88132879,1.77777778 1.77777778,3.88132879 1.77777778,6.47619048 C1.77777778,9.07105216 3.88132879,11.1746032 6.47619048,11.1746032 C9.07105216,11.1746032 11.1746032,9.07105216 11.1746032,6.47619048 C11.1746032,3.88132879 9.07105216,1.77777778 6.47619048,1.77777778 Z',
    svgSearchViewBox: '0 0 16 16',
    svgSearchWidth: 16,
    svgSearchHeight: 16,
    themeColorsPrimaryHeaderNavLink: '',
    themeColorsPrimaryHeaderNavLinkHover: '',
    themeColorsPrimaryHeaderNavLinkActive: '',
    themeColorsPrimaryHeaderNavLinkBackground: '',
    themeColorsPrimaryHeaderNavLinkHoverBackground: '',
    themeColorsPrimaryHeaderNavLinkActiveBackground: '',
    themeColorsPrimaryBackgroundColor: '',
    themeColorsPrimaryBackgroundColorSecondary: '',
    themeColorsPrimaryTextColor: '',
    themeColorsPrimaryTextColorSecondary: '',
    themeColorsPrimaryHeroQuoteTextColor: '',
    themeColorsPrimaryBorderDividerColor: '',
    themeColorsPrimaryButtonBackgroundColor: '',
    themeColorsPrimaryButtonBackgroundColorHover: '',
    themeColorsPrimaryButtonTextColor: '',
    themeColorsPrimaryButtonTextColorHover: '',
    themeColorsSecondaryHeaderNavLink: '',
    themeColorsSecondaryHeaderNavLinkHover: '',
    themeColorsSecondaryHeaderNavLinkActive: '',
    themeColorsSecondaryHeaderNavLinkBackground: '',
    themeColorsSecondaryHeaderNavLinkHoverBackground: '',
    themeColorsSecondaryHeaderNavLinkActiveBackground: '',
    themeColorsSecondaryBackgroundColor: '',
    themeColorsSecondaryBackgroundColorSecondary: '',
    themeColorsSecondaryTextColor: '',
    themeColorsSecondaryTextColorSecondary: '',
    themeColorsSecondaryHeroQuoteTextColor: '',
    themeColorsSecondaryBorderDividerColor: '',
    themeColorsSecondaryButtonBackgroundColor: '',
    themeColorsSecondaryButtonBackgroundColorHover: '',
    themeColorsSecondaryButtonTextColor: '',
    themeColorsSecondaryButtonTextColorHover: '',
    themeColorsTertiaryHeaderNavLink: '',
    themeColorsTertiaryHeaderNavLinkHover: '',
    themeColorsTertiaryHeaderNavLinkActive: '',
    themeColorsTertiaryHeaderNavLinkBackground: '',
    themeColorsTertiaryHeaderNavLinkHoverBackground: '',
    themeColorsTertiaryHeaderNavLinkActiveBackground: '',
    themeColorsTertiaryBackgroundColor: '',
    themeColorsTertiaryBackgroundColorSecondary: '',
    themeColorsTertiaryTextColor: '',
    themeColorsTertiaryTextColorSecondary: '',
    themeColorsTertiaryHeroQuoteTextColor: '',
    themeColorsTertiaryBorderDividerColor: '',
    themeColorsTertiaryButtonBackgroundColor: '',
    themeColorsTertiaryButtonBackgroundColorHover: '',
    themeColorsTertiaryButtonTextColor: '',
    themeColorsTertiaryButtonTextColorHover: '',
    themeColorsQuaternaryHeaderNavLink: '',
    themeColorsQuaternaryHeaderNavLinkHover: '',
    themeColorsQuaternaryHeaderNavLinkActive: '',
    themeColorsQuaternaryHeaderNavLinkBackground: '',
    themeColorsQuaternaryHeaderNavLinkHoverBackground: '',
    themeColorsQuaternaryHeaderNavLinkActiveBackground: '',
    themeColorsQuaternaryBackgroundColor: '',
    themeColorsQuaternaryBackgroundColorSecondary: '',
    themeColorsQuaternaryTextColor: '',
    themeColorsQuaternaryTextColorSecondary: '',
    themeColorsQuaternaryHeroQuoteTextColor: '',
    themeColorsQuaternaryBorderDividerColor: '',
    themeColorsQuaternaryButtonBackgroundColor: '',
    themeColorsQuaternaryButtonBackgroundColorHover: '',
    themeColorsQuaternaryButtonTextColor: '',
    themeColorsQuaternaryButtonTextColorHover: '',
    themeColorsQuinaryHeaderNavLink: '',
    themeColorsQuinaryHeaderNavLinkHover: '',
    themeColorsQuinaryHeaderNavLinkActive: '',
    themeColorsQuinaryHeaderNavLinkBackground: '',
    themeColorsQuinaryHeaderNavLinkHoverBackground: '',
    themeColorsQuinaryHeaderNavLinkActiveBackground: '',
    themeColorsQuinaryBackgroundColor: '',
    themeColorsQuinaryBackgroundColorSecondary: '',
    themeColorsQuinaryTextColor: '',
    themeColorsQuinaryTextColorSecondary: '',
    themeColorsQuinaryHeroQuoteTextColor: '',
    themeColorsQuinaryBorderDividerColor: '',
    themeColorsQuinaryButtonBackgroundColor: '',
    themeColorsQuinaryButtonBackgroundColorHover: '',
    themeColorsQuinaryButtonTextColor: '',
    themeColorsQuinaryButtonTextColorHover: '',
    lightTheme: {
        actionPrimaryText: '#FFFFFF',
        actionPrimaryTextHover: '#FFFFFF',
        actionPrimaryBackground: '#0000FF',
        actionPrimaryBackgroundHover: '#0000CD',
        actionPrimaryBorder: 'transparent',
        actionPrimaryBorderHover: 'transparent',
        actionSecondaryText: '#FFFFFF',
        actionSecondaryTextHover: '#FFFFFF',
        actionSecondaryBackground: '#666699',
        actionSecondaryBackgroundHover: '#52527b',
        actionSecondaryBorder: 'transparent',
        actionSecondaryBorderHover: 'transparent',
        actionTertiaryText: '#FFFFFF',
        actionTertiaryTextHover: '#FFFFFF',
        actionTertiaryBackground: '#A1A1A1',
        actionTertiaryBackgroundHover: '#6A798C',
        actionTertiaryBorder: 'transparent',
        actionTertiaryBorderHover: 'transparent',
        actionMonochromeText: '#FFFFFF',
        actionMonochromeBackground: '#000000',
        actionMonochromeBackgroundHover: '#727272',
        actionMonochromeBorder: 'transparent',
        actionMonochromeBorderHover: 'transparent',
        actionSecondaryWhiteText: '#FFFFFF',
        actionSecondaryWhiteTextHover: '#FFFFFF',
        actionSecondaryWhiteBackground: '#0052cc',
        actionSecondaryWhiteBackgroundHover: '#0052cc',
        actionSecondaryWhiteBorder: 'transparent',
        actionSecondaryWhiteBorderHover: 'transparent',
        actionSecondaryBlackText: '#FFFFFF',
        actionSecondaryBlackTextHover: '#FFFFFF',
        actionSecondaryBlackBackground: '#000000',
        actionSecondaryBlackBackgroundHover: '#181818',
        actionSecondaryBlackBorder: 'transparent',
        actionSecondaryBlackBorderHover: 'transparent',
        actionOutlineText: '#000000',
        actionOutlineTextHover: '#000000',
        actionOutlineBackground: 'transparent',
        actionOutlineBackgroundHover: 'transparent',
        actionOutlineBorder: '#000000',
        actionOutlineBorderHover: '#999999',
        actionTextLink: '#000000',
        actionTextLinkHover: '#181818',
        actionTextLinkBorder: '#E3E3E3',
        actionHeadlineLink: '#000000',
        actionHeadlineLinkHover: '#181818',
        actionNavLink: '#000000',
        actionNavLinkBackground: '#FFFFFF',
        actionNavLinkHover: '#0052cc',
        actionNavLinkActive: '#0052cc',
        actionNavLinkHoverBackground: '#FFFFFF',
        actionDisabledText: '#FFFFFF',
        actionDisabledBackground: '#6A798C',
        actionDisabledBackgroundHover: '#6A798C',
        actionDisabledBorder: '#6A798C',
        actionDisabledBorderHover: '#6A798C',
        actionRadioSquareBackground: '#FFFFFF',
        actionRadioSquareActiveBackground: '#000000',
        actionRadioSquareColor: '#000000',
        actionRadioSquareActiveColor: '#FFFFFF',
        textTitle: '#121212',
        textHeadline: '#000000',
        textSubHeadline: '#000000',
        textTitleOverlay: '#FFFFFF',
        textBody: '#000000',
        textCaption: '#000000',
        textPrice: '#000000',
        formInputLabelText: '#000000',
        formInputFocusLabelText: '#000000',
        formInputInputText: '#000000',
        formInputPlaceholderText: '#4F4F4F',
        formInputBorder: '#000000',
        formInputBorderFocus: '#0052cc',
        formInputBorderError: '#e4002b',
        formInputCheckbox: '#0073BE',
        formInputBackground: '#FFFFFF',
        formRadioEnabled: '#000000',
        formRadioDisabled: '#6A798C',
        formRadioBorderDisabled: '#E3E3E3',
        formRadioBorderEnabled: '#6A798C',
        formRadioBorderActive: '#000000',
        formListItemBackground: '#F3F3F3',
        formListItemBackgroundHover: '#F3F3F3',
        formSliderVerticalBars: '#0052cc',
        formSliderHandleBackground: '#FFFFFF',
        formSliderHandleBorder: '#000000',
        formSliderProgressBar: '#000000',
        formStatusErrorLight: '#ffe5ea',
        formStatusErrorMain: '#e4002b',
        formStatusErrorDark: '#e4002b',
        formStatusSuccessLight: '#e7fef6',
        formStatusSuccessMain: '#08875b',
        formStatusSuccessDark: '#08875b',
        formStatusWarningLight: '#fff3cc',
        formStatusWarningMain: '#ffa100',
        formStatusWarningDark: '#ffa100',
        formStatusInfoLight: '#EFF6FF',
        formStatusInfoMain: '#0073BE',
        formStatusInfoDark: '#1244CA',
        headerBackground: '#FFFFFF',
        headerStickyBorderBottom: 'rgba(227, 227, 227, 0.1)',
        headerStickyBoxShadow: 'rgba(0, 0, 0, 0.03)',
        headerStickyBoxShadowNoNotification: 'rgba(0, 0, 0, 0.25)',
        headerStickyBackground: '#FFFFFF',
        headerStickyBackgroundScrolled: '#FFFFFF',
        headerStickyBorderBottomScrolled: '#E3E3E3',
        headerSearchInputBorder: '#6A798C',
        headerMegamenu: '#F9F9F9',
        headerPlpSidebarTitle: '#000000',
        footerBackground: '#FFFFFF',
        footerTextColor: '#000000',
        footerHeadingColor: '',
        tableThead: '#F9F9F9',
        tableTh: '#F9F9F9',
        tableTd: '#FFFFFF',
        iconPrimary: '#000000',
        iconSecondary: '#727272',
        iconTertiary: '#000000',
        iconLoader: '#FFFFFF',
        iconRoundedBackground: '#F3F3F3',
        iconRoundedIcon: '#000000',
        iconRoundedBorder: '#000000',
        iconRoundedActiveBackground: '#000000',
        iconRoundedActiveIcon: '#F3F3F3',
        iconRoundedActiveBorder: '#000000',
        logo: '#000000',
        borderDivider1: '#F3F3F3',
        borderDivider2: '#0052cc',
        pillActiveTextColor: '#000000',
        pillActiveTextColorHover: '#000000',
        pillActiveBorderColor: '#000000',
        pillActiveBorderColorHover: '#000000',
        pillActiveBackground: '#333333',
        pillActiveBackgroundHover: '#4F4F4F',
        pillDefaultTextColor: '#000000',
        pillDefaultTextColorHover: '#000000',
        pillDefaultBorderColor: '#000000',
        pillDefaultBorderColorHover: '#999999',
        pillDefaultBackground: '#FFFFFF',
        pillDefaultBackgroundHover: '#FFFFFF',
        pillBadgeBackground: '#FFFFFF',
        pillBadgeBorderColorHover: '#999999',
        wrapperBody: '#FFFFFF',
        wrapperPublication: '#F9F9F9',
        wrapperCategory: '#F9F9F9',
        wrapperProduct: '#F9F9F9',
        wrapperTileColor: 'undefined',
        wrapperTileColorHover: 'undefined',
        wrapperSection: '#FFFFFF',
        wrapperBodyHover: '#F9F9F9',
        wrapperWell: '#FFFFFF',
        wrapperCard: '#F8F8F8',
        wrapperCardHover: '#F9F9F9',
        wrapperCardGradientColor: '#F8F8F8',
        wrapperCardGradientColorHover: '#F9F9F9',
        wrapperCardBorderColor: 'transparent',
        wrapperModal: '#999999',
        wrapperBanner: '#FFFFFF',
        wrapperBannerBorder: '#FFFFFF',
        wrapperUsp: '#0052cc',
        wrapperUspColor: '#FFFFFF',
        oBikeComparisonCompareButtonBackground: '#F3F3F3',
        oBikeComparisonCompareButtonBorderColor: '#6A798C',
        uListItemHighlightColor: '#333333',
        uListItemTextColorHover: '#999999',
        uCarouselBackgroundColor: '#FFFFFF',
        uPullUpToggleBackground: '#000000',
        uNewsletterTitleColor: '#FFFFFF',
        uKeyFeatureListBackgroundColor: 'unset',
        fallbackWhite: '#FFFFFF',
        fallbackGrey50: '#F9F9F9',
        fallbackGrey100: '#F3F3F3',
        fallbackGrey200: '#E3E3E3',
        fallbackGrey300: '#6A798C',
        fallbackGrey400: '#999999',
        fallbackGrey500: '#727272',
        fallbackGrey600: '#4F4F4F',
        fallbackGrey700: '#000000',
        fallbackGrey750: '#1E1E1E',
        fallbackGrey800: '#181818',
        fallbackGrey900: '#121212',
        fallbackBlack: '#000000',
    },
    darkTheme: {
        actionPrimaryText: '#FFFFFF',
        actionPrimaryTextHover: '#FFFFFF',
        actionPrimaryBackground: '#0052cc',
        actionPrimaryBackgroundHover: '#34428A',
        actionPrimaryBorder: 'transparent',
        actionPrimaryBorderHover: 'transparent',
        actionSecondaryText: '#FFFFFF',
        actionSecondaryTextHover: '#FFFFFF',
        actionSecondaryBackground: '#6A798C',
        actionSecondaryBackgroundHover: '#007B4D',
        actionSecondaryBorder: 'transparent',
        actionSecondaryBorderHover: 'transparent',
        actionTertiaryText: '#FFFFFF',
        actionTertiaryTextHover: '#FFFFFF',
        actionTertiaryBackground: '#A1A1A1',
        actionTertiaryBackgroundHover: '#D0A045',
        actionTertiaryBorder: 'transparent',
        actionTertiaryBorderHover: 'transparent',
        actionMonochromeText: '#FFFFFF',
        actionMonochromeBackground: '#000000',
        actionMonochromeBackgroundHover: '#F9F9F9',
        actionMonochromeBorder: 'transparent',
        actionMonochromeBorderHover: 'transparent',
        actionSecondaryWhiteText: '#000000',
        actionSecondaryWhiteTextHover: '#000000',
        actionSecondaryWhiteBackground: '#FFFFFF',
        actionSecondaryWhiteBackgroundHover: '#F9F9F9',
        actionSecondaryWhiteBorder: 'transparent',
        actionSecondaryWhiteBorderHover: 'transparent',
        actionSecondaryBlackText: '#000000',
        actionSecondaryBlackTextHover: '#000000',
        actionSecondaryBlackBackground: '#FFFFFF',
        actionSecondaryBlackBackgroundHover: '#F9F9F9',
        actionSecondaryBlackBorder: 'transparent',
        actionSecondaryBlackBorderHover: 'transparent',
        actionOutlineText: '#FFFFFF',
        actionOutlineTextHover: '#FFFFFF',
        actionOutlineBackground: 'transparent',
        actionOutlineBackgroundHover: 'transparent',
        actionOutlineBorder: '#6A798C',
        actionOutlineBorderHover: '#999999',
        actionTextLink: '#FFFFFF',
        actionTextLinkHover: '#F3F3F3',
        actionTextLinkBorder: '#F3F3F3',
        actionHeadlineLink: '#FFFFFF',
        actionHeadlineLinkHover: '#F3F3F3',
        actionNavLink: '#000000',
        actionNavLinkBackground: '#000000',
        actionNavLinkHover: '#0052cc',
        actionNavLinkHoverBackground: '#FFFFFF',
        actionNavLinkActive: '#0052cc',
        actionNavLinkActiveBackground: '#FFFFFF',
        actionDisabledText: '#FFFFFF',
        actionDisabledBackground: '#6A798C',
        actionDisabledBackgroundHover: '#6A798C',
        actionDisabledBorder: '#6A798C',
        actionDisabledBorderHover: '#6A798C',
        actionRadioSquareBackground: '#FFFFFF',
        actionRadioSquareActiveBackground: '#000000',
        actionRadioSquareColor: '#000000',
        actionRadioSquareActiveColor: '#FFFFFF',
        textTitle: '#FFFFFF',
        textHeadline: '#FFFFFF',
        textSubHeadline: '#F9F9F9',
        textTitleOverlay: '#FFFFFF',
        textBody: '#FFFFFF',
        textCaption: '#F9F9F9',
        textPrice: '#F9F9F9',
        formInputLabelText: '#6A798C',
        formInputFocusLabelText: '#0052cc',
        formInputInputText: '#6A798C',
        formInputPlaceholderText: '#6A798C',
        formInputBorder: '#999999',
        formInputBorderFocus: 'transparent',
        formInputBorderError: '#e4002b',
        formInputCheckbox: '#0073BE',
        formInputBackground: '#121212',
        formInputBackgroundSecondary: '#A1A1A1',
        formInputBorderSecondary: '#A1A1A1',
        formRadioEnabled: '#000000',
        formRadioDisabled: '#6A798C',
        formRadioBorderDisabled: '#E3E3E3',
        formRadioBorderEnabled: '#6A798C',
        formRadioBorderActive: '#000000',
        formListItemBackground: '#181818',
        formListItemBackgroundHover: '#181818',
        formSliderVerticalBars: '#6A798C',
        formSliderHandleBackground: '#FFFFFF',
        formSliderHandleBorder: '#6A798C',
        formSliderProgressBar: '#34428A',
        formStatusErrorLight: '#ffe5ea',
        formStatusErrorMain: '#e4002b',
        formStatusErrorDark: '#e4002b',
        formStatusSuccessLight: '#e7fef6',
        formStatusSuccessMain: '#08875b',
        formStatusSuccessDark: '#08875b',
        formStatusWarningLight: '#fff3cc',
        formStatusWarningMain: '#ffa100',
        formStatusWarningDark: '#ffa100',
        formStatusInfoLight: '#EFF6FF',
        formStatusInfoMain: '#0073BE',
        formStatusInfoDark: '#1244CA',
        headerBackground: '#FFFFFF',
        headerStickyBorderBottom: 'rgba(227, 227, 227, 0.1)',
        headerStickyBoxShadow: 'rgba(0, 0, 0, 0.03)',
        headerStickyBoxShadowNoNotification: 'rgba(0, 0, 0, 0.25)',
        headerStickyBackground: '#FFFFFF',
        headerStickyBackgroundScrolled: '#FFFFFF',
        headerStickyBorderBottomScrolled: '#E3E3E3',
        headerSearchInputBorder: '#E3E3E3',
        headerMegamenu: '#F9F9F9',
        headerPlpSidebarTitle: '#FFFFFF',
        footerBackground: '#121212',
        footerTextColor: '#FFFFFF',
        footerHeadingColor: '',
        tableThead: '#F9F9F9',
        tableTh: '#F9F9F9',
        tableTd: '#121212',
        iconPrimary: '#FFFFFF',
        iconSecondary: '#727272',
        iconTertiary: '#4F4F4F',
        iconLoader: '#FFFFFF',
        iconRoundedBackground: '#181818',
        iconRoundedIcon: '#FFFFFF',
        iconRoundedBorder: '#FFFFFF',
        iconRoundedActiveBackground: '#FFFFFF',
        iconRoundedActiveIcon: '#181818',
        iconRoundedActiveBorder: '#FFFFFF',
        logo: '#000000',
        borderDivider1: '#4F4F4F',
        borderDivider2: '#E3E3E3',
        pillActiveTextColor: '#181818',
        pillActiveTextColorHover: '#181818',
        pillActiveBorderColor: '#FFFFFF',
        pillActiveBorderColorHover: '#FFFFFF',
        pillActiveBackground: '#FFFFFF',
        pillActiveBackgroundHover: '#FFFFFF',
        pillDefaultTextColor: '#FFFFFF',
        pillDefaultTextColorHover: '#FFFFFF',
        pillDefaultBorderColor: '#000000',
        pillDefaultBorderColorHover: '#000000',
        pillDefaultBackground: '#FFFFFF',
        pillDefaultBackgroundHover: '#FFFFFF',
        pillBadgeBackground: '#FFFFFF',
        pillBadgeBorderColorHover: '#000000',
        wrapperBody: '#121212',
        wrapperPublication: '#F9F9F9',
        wrapperCategory: '#F9F9F9',
        wrapperProduct: '#F9F9F9',
        wrapperTileColor: 'undefined',
        wrapperTileColorHover: 'undefined',
        wrapperSection: '#121212',
        wrapperBodyHover: '#1E1E1E',
        wrapperWell: '#000000',
        wrapperCard: '#181818',
        wrapperCardHover: '#121212',
        wrapperCardGradientColor: '#181818',
        wrapperCardGradientColorHover: '#121212',
        wrapperCardBorderColor: 'transparent',
        wrapperModal: '#181818',
        wrapperBanner: '#FFFFFF',
        wrapperBannerBorder: '#FFFFFF',
        wrapperUsp: '#0052cc',
        wrapperUspColor: '#FFFFFF',
        oBikeComparisonCompareButtonBackground: '#F8F8F8',
        oBikeComparisonCompareButtonBorderColor: '#6A798C',
        uListItemHighlightColor: '#FFFFFF',
        uListItemTextColorHover: '#333333',
        uCarouselBackgroundColor: '#FFFFFF',
        uPullUpToggleBackground: '#000000',
        uNewsletterTitleColor: '#FFFFFF',
        uKeyFeatureListBackgroundColor: 'unset',
        fallbackWhite: '#FFFFFF',
        fallbackGrey50: '#F9F9F9',
        fallbackGrey100: '#F3F3F3',
        fallbackGrey200: '#E3E3E3',
        fallbackGrey300: '#6A798C',
        fallbackGrey400: '#999999',
        fallbackGrey500: '#727272',
        fallbackGrey600: '#4F4F4F',
        fallbackGrey700: '#000000',
        fallbackGrey750: '#1E1E1E',
        fallbackGrey800: '#181818',
        fallbackGrey900: '#121212',
        fallbackBlack: '#000000',
    },
};
