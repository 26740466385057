import React, { FC, SVGAttributes } from 'react';

const UIconCaret: FC<SVGAttributes<SVGElement>> = ({ color = 'currentColor', ...rest }) => (
    <svg width={8} height={16} viewBox="0 0 8 16" {...rest}>
        <path
            d="M7.76 14.35a1 1 0 01-1.432 1.391l-.087-.09-6-7a1 1 0 01-.074-1.205l.074-.097 6-7A1 1 0 017.835 1.55l-.076.1L2.318 8l5.441 6.35z"
            fill={color}
            fillRule="nonzero"
        />
    </svg>
);

export default UIconCaret;
