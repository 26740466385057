import { all } from 'deepmerge';
import {
    ShopwareProductRequestBodyFields,
    ShopwareProductRequestBodySelectorFilters,
    ShopwareProductVariantRequestBodyFields,
} from './fields';

export function fieldsSelector<T extends object>(sourceObject: T, propertyList: (keyof T)[]): T {
    const newObject: Partial<T> = {};
    propertyList.forEach(property => {
        if (property in sourceObject) {
            newObject[property] = sourceObject[property];
        }
    });
    return newObject as T;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function removeDuplicates(obj: Record<string, any>): Record<string, any> {
    // Helper function to remove duplicates from an array
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function removeArrayDuplicates(arr: any[]): any[] {
        return [...new Set(arr)];
    }

    const objCopy = { ...obj };
    // eslint-disable-next-line no-restricted-syntax
    for (const key in objCopy) {
        if (key in objCopy) {
            const value = objCopy[key];

            if (Array.isArray(value)) {
                // If the property value is an array, remove duplicates
                objCopy[key] = removeArrayDuplicates(value);
            } else if (typeof value === 'object' && value !== null) {
                // If the property value is an object, recursively remove duplicates
                objCopy[key] = removeDuplicates(value);
            }
        }
    }

    return objCopy;
}

export const formatFieldsToBody = (
    fields: ShopwareProductRequestBodyFields | ShopwareProductVariantRequestBodyFields,
    filter?: ShopwareProductRequestBodySelectorFilters
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
): { [key: string]: any } => {
    const body = all([...Object.values(fields)]);
    return { ...removeDuplicates(body), ...filter };
};
