import React, { FunctionComponent, SVGAttributes } from 'react';

type IProps = SVGAttributes<SVGElement>;

const UIconCheckmark: FunctionComponent<IProps> = ({ color = 'currentColor', ...rest }) => (
    <svg width="16" height="13" viewBox="0 0 16 13" {...rest}>
        <path
            d="M15.107.944a1.333 1.333 0 00-1.886 0L5.343 8.84 2.8 6.296a1.316 1.316 0 00-1.87.008 1.339 1.339 0 00.01 1.885l4.405 4.406 9.763-9.764a1.335 1.335 0 000-1.887z"
            fill={color}
        />
    </svg>
);

export default UIconCheckmark;
