import { createContext, useEffect, useContext, useMemo } from 'react';
import { GTMEvents } from '../../constants/gtm';
import isBrowser from '../../services/isBrowser';
import isDefined from '../../services/isDefined';
import { CLIENT_BIKE_COMPARE_PRODUCT_LISTING_FIELDS } from '../../services/product/fields.constants';
import { formatFieldsToBody } from '../../services/product/fields.helpers';
import { ProductToCompare } from '../../components/ui/UBikeCompareButton';
import useFetchShopwareProducts from '../product/useFetchShopwareProducts';

const COMPARE_ITEMS = 'compareItems';
export const MAX_COMPARABLE_BIKES = 3;

type State = {
    products: ProductToCompare[];
    initialised: boolean;
    open: boolean;
};

export const initialBikeCompareState: State = {
    products: [],
    initialised: false,
    open: false,
};

type ActionType = 'TOGGLE' | 'UPDATE' | 'SET';

type Action = {
    type: ActionType;
    payload?: ProductToCompare[];
};

export const getCompareItems = (): ProductToCompare[] => {
    if (!isBrowser()) {
        return [];
    }

    try {
        const parsedProducts: ProductToCompare[] =
            JSON.parse(localStorage.getItem(COMPARE_ITEMS) ?? '[]') ?? [];
        return parsedProducts;
    } catch {
        // Clean the localStorage value
        setCompareItems([]);
        return [];
    }
};

const setCompareItems = (compareItems: ProductToCompare[]) => {
    if (isBrowser()) localStorage.setItem(COMPARE_ITEMS, JSON.stringify(compareItems));
};

export const isActive = (objectID: string, state: State) =>
    Boolean(state.products.find((product: ProductToCompare) => product.objectID === objectID));

export const addToComparison = (product: ProductToCompare, GTMElement: string | undefined) => {
    if (!isBrowser()) return;

    const newCompareItems = [...getCompareItems(), product];

    setCompareItems(newCompareItems);

    window.dataLayer?.push({
        event: GTMEvents.comparison,
        eventCategory: 'Comparison',
        eventAction: 'add',
        'product-name': product.model,
        'comparison-products': newCompareItems.map((x: { model: string }) => x.model).join(', '),
        'comparison-amount': newCompareItems.length,
        element: GTMElement,
    });
};

export const removeFromComparison = (objectID: string) => {
    if (!isBrowser()) return;

    const compareItemToRemove = getCompareItems().find(
        (product: ProductToCompare) => product.objectID === objectID
    );
    const newCompareItems = getCompareItems().filter(
        (product: ProductToCompare) => product.objectID !== objectID
    );

    setCompareItems(newCompareItems);

    window.dataLayer.push({
        event: GTMEvents.comparison,
        eventCategory: 'Comparison',
        eventAction: 'remove',
        'product-name': compareItemToRemove?.model,
        'comparison-products': newCompareItems.map((x: { model: string }) => x.model).join(', '),
        'comparison-amount': newCompareItems.length, // The new amount of products
        element: 'panel',
    });
};

export const bikeCompareReducer = (state: State, action: Action) => {
    const { type, payload } = action;

    switch (type) {
        case 'TOGGLE':
            return {
                ...state,
                open: !state.open,
            };
        case 'UPDATE': {
            const products = getCompareItems();

            return {
                ...state,
                products,
                initialised: true,
            };
        }
        case 'SET':
            return {
                ...state,
                products: payload ?? [],
                initialised: true,
            };
        default:
            return state;
    }
};

export const BikeCompareContext = createContext<{
    state: State;
    dispatch: React.Dispatch<Action>;
}>({
    state: initialBikeCompareState,
    dispatch: () => null,
});

const useBikeCompare = ({ showBikeCompare }: { showBikeCompare: boolean }) => {
    const { state, dispatch } = useContext(BikeCompareContext);

    // Memoized `productIdsToCompare` & `productsToCompare` to trigger refetch only when needed
    const { productIdsToCompare, productsToCompare } = useMemo(() => {
        const productsFromLocalstorage = getCompareItems();

        if (!productsFromLocalstorage?.length) {
            return {
                productIdsToCompare: [],
                productsToCompare: [],
            };
        }

        return {
            productIdsToCompare: productsFromLocalstorage
                .map(productToCompare => productToCompare.objectID)
                .filter(isDefined)
                .sort(),
            productsToCompare: productsFromLocalstorage,
        };
    }, []);

    const { shopwareProductsRequestStatus, shopwareProducts } = useFetchShopwareProducts({
        productIds: productIdsToCompare,
        productRequestBodyOverride: formatFieldsToBody(CLIENT_BIKE_COMPARE_PRODUCT_LISTING_FIELDS),
        shouldFetch: !!productIdsToCompare.length,
    });

    useEffect(() => {
        if (!showBikeCompare) {
            return;
        }

        if (!productIdsToCompare.length) {
            dispatch({ type: 'SET', payload: [] });
            return;
        }

        if (
            productIdsToCompare.length &&
            (shopwareProductsRequestStatus === 'success' ||
                shopwareProductsRequestStatus === 'failed')
        ) {
            if (!shopwareProducts.length) {
                dispatch({ type: 'SET', payload: [] });
                return;
            }

            const enrichedProductsToCompare = shopwareProducts
                .filter(isDefined)
                .map(shopwareProduct => {
                    const matchingProductToCompare = productsToCompare.find(
                        productToCompare => productToCompare.objectID === shopwareProduct.id
                    );
                    if (!matchingProductToCompare) return undefined;

                    const shopwareProductName =
                        shopwareProduct?.translated?.name ?? shopwareProduct?.name;
                    const shopwareThumbnail =
                        shopwareProduct.cover?.media?.thumbnails?.find(
                            (thumb: { width: number; url: string }) =>
                                thumb.width === 320 || thumb.width === 400
                        )?.url ?? shopwareProduct.cover?.media?.url;

                    return {
                        ...matchingProductToCompare,
                        objectID: shopwareProduct.id,
                        model: shopwareProductName ?? matchingProductToCompare?.model,
                        thumbnail: shopwareThumbnail ?? matchingProductToCompare?.thumbnail,
                        sku: shopwareProduct.productNumber
                    };
                })
                .filter(isDefined);

            dispatch({ type: 'SET', payload: enrichedProductsToCompare });
        }
    }, [
        productsToCompare,
        shopwareProducts,
        shopwareProductsRequestStatus,
        showBikeCompare,
        dispatch,
        productIdsToCompare.length,
    ]);

    return {
        state,
    };
};

export default useBikeCompare;
