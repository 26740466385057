import { Countries, Languages, StoryblokAdditionalLanguages } from '../types/Countries';

export const countries: Countries = [
    'DE', // Default country
    'AT',
    'CH',
    'CZ',
    'ES',
    'FR',
    'BE',
    'IT',
    'PL',
    'FI',
    'SE',
    'GB',
    'IE',
    'NL',
];

export const languages: Languages = {
    DE: [
        'de', // Default country/language
        'en',
    ],
    AT: ['de', 'en'],
    CH: ['de', 'it', 'en', 'fr'],
    CZ: ['cs', 'en'],
    ES: ['es', 'en'],
    FR: ['fr', 'en'],
    BE: ['fr', 'en'],
    IT: ['it', 'en'],
    PL: ['pl', 'en'],
    FI: ['fi', 'en'],
    SE: ['sv', 'en'],
    GB: ['en'],
    IE: ['en'],
    NL: ['en'],
};

// Override the above country/language codes with specific codes for Storyblok
export const storyblokAdditionalLanguages: StoryblokAdditionalLanguages = {
    GB: {
        en: 'en-uk',
    },
    IE: {
        en: 'en-uk',
    },
};
