import { countries } from './countries';

export type Brand = keyof typeof shopwareBrandTokens;

export const brands = [
    'raleigh',
    'lapierre',
    'sparta',
    'xlc',
    'ghost',
    'winora',
    'whiteLabel',
    'haibike',
];

export const brand: Brand =
    (process.env.GATSBY_BRAND as Brand) ?? (process.env.STORYBOOK_BRAND as Brand) ?? '';

const baseUrls: { [brand in Brand]: string } = {
    raleigh: process.env.GATSBY_RALEIGH_BASE_URL ?? '',
    lapierre: process.env.GATSBY_LAPIERRE_BASE_URL ?? '',
    sparta: process.env.GATSBY_SPARTA_BASE_URL ?? '',
    xlc: process.env.GATSBY_XLC_BASE_URL ?? '',
    ghost: process.env.GATSBY_GHOST_BASE_URL ?? '',
    winora: process.env.GATSBY_WINORA_BASE_URL ?? '',
    haibike: process.env.GATSBY_HAIBIKE_BASE_URL ?? '',
};

const shopwareBrandTokens = {
    raleigh: {
        GB: process.env.GATSBY_SHOPWARE_RALEIGH_TOKEN_GB ?? '',
        IE: process.env.GATSBY_SHOPWARE_RALEIGH_TOKEN_IE ?? '',
    },
    lapierre: {
        FR: process.env.GATSBY_SHOPWARE_LAPIERRE_TOKEN_FR ?? '',
        GB: process.env.GATSBY_SHOPWARE_LAPIERRE_TOKEN_GB ?? '',
        NL: process.env.GATSBY_SHOPWARE_LAPIERRE_TOKEN_NL ?? '',
        ES: process.env.GATSBY_SHOPWARE_LAPIERRE_TOKEN_ES ?? '',
        BE: process.env.GATSBY_SHOPWARE_LAPIERRE_TOKEN_BE ?? '',
        IE: process.env.GATSBY_SHOPWARE_LAPIERRE_TOKEN_IE ?? '',
        DE: process.env.GATSBY_SHOPWARE_LAPIERRE_TOKEN_DE ?? '',
        IT: process.env.GATSBY_SHOPWARE_LAPIERRE_TOKEN_IT ?? '',
        LU: process.env.GATSBY_SHOPWARE_LAPIERRE_TOKEN_LU ?? '',
        CH: process.env.GATSBY_SHOPWARE_LAPIERRE_TOKEN_CH ?? '',
        INT: process.env.GATSBY_SHOPWARE_LAPIERRE_TOKEN_INT ?? '',
    },
    sparta: {
        NL: process.env.GATSBY_SHOPWARE_SPARTA_TOKEN_NL ?? '',
        DK: process.env.GATSBY_SHOPWARE_SPARTA_TOKEN_DK ?? '',
        BE: process.env.GATSBY_SHOPWARE_SPARTA_TOKEN_BE ?? '',
    },
    xlc: {
        GB: process.env.GATSBY_SHOPWARE_XLC_TOKEN_GB ?? '',
        NL: process.env.GATSBY_SHOPWARE_XLC_TOKEN_NL ?? '',
        DE: process.env.GATSBY_SHOPWARE_XLC_TOKEN_DE ?? '',
        ES: process.env.GATSBY_SHOPWARE_XLC_TOKEN_ES ?? '',
        FR: process.env.GATSBY_SHOPWARE_XLC_TOKEN_FR ?? '',
        INT: process.env.GATSBY_SHOPWARE_XLC_TOKEN_INT ?? '',
    },
    ghost: {
        DE: process.env.GATSBY_SHOPWARE_GHOST_TOKEN_DE ?? '',
        FI: process.env.GATSBY_SHOPWARE_GHOST_TOKEN_FI ?? '',
        IT: process.env.GATSBY_SHOPWARE_GHOST_TOKEN_IT ?? '',
        AT: process.env.GATSBY_SHOPWARE_GHOST_TOKEN_AT ?? '',
        SE: process.env.GATSBY_SHOPWARE_GHOST_TOKEN_SE ?? '',
        CH: process.env.GATSBY_SHOPWARE_GHOST_TOKEN_CH ?? '',
        ES: process.env.GATSBY_SHOPWARE_GHOST_TOKEN_ES ?? '',
        INT: process.env.GATSBY_SHOPWARE_GHOST_TOKEN_INT ?? '',
    },
    winora: {
        AT: process.env.GATSBY_SHOPWARE_WINORA_TOKEN_AT ?? '',
        CH: process.env.GATSBY_SHOPWARE_WINORA_TOKEN_CH ?? '',
        CZ: process.env.GATSBY_SHOPWARE_WINORA_TOKEN_CZ ?? '',
        DE: process.env.GATSBY_SHOPWARE_WINORA_TOKEN_DE ?? '',
        ES: process.env.GATSBY_SHOPWARE_WINORA_TOKEN_ES ?? '',
        FR: process.env.GATSBY_SHOPWARE_WINORA_TOKEN_FR ?? '',
        IT: process.env.GATSBY_SHOPWARE_WINORA_TOKEN_IT ?? '',
        NL: process.env.GATSBY_SHOPWARE_WINORA_TOKEN_NL ?? '',
        PL: process.env.GATSBY_SHOPWARE_WINORA_TOKEN_PL ?? '',
    },
    haibike: {
        DE: process.env.GATSBY_SHOPWARE_HAIBIKE_TOKEN_DE ?? '',
        AT: process.env.GATSBY_SHOPWARE_HAIBIKE_TOKEN_AT ?? '',
        CH: process.env.GATSBY_SHOPWARE_HAIBIKE_TOKEN_CH ?? '',
        CZ: process.env.GATSBY_SHOPWARE_HAIBIKE_TOKEN_CZ ?? '',
        ES: process.env.GATSBY_SHOPWARE_HAIBIKE_TOKEN_ES ?? '',
        FR: process.env.GATSBY_SHOPWARE_HAIBIKE_TOKEN_FR ?? '',
        BE: process.env.GATSBY_SHOPWARE_HAIBIKE_TOKEN_BE ?? '',
        IT: process.env.GATSBY_SHOPWARE_HAIBIKE_TOKEN_IT ?? '',
        PL: process.env.GATSBY_SHOPWARE_HAIBIKE_TOKEN_PL ?? '',
        FI: process.env.GATSBY_SHOPWARE_HAIBIKE_TOKEN_FI ?? '',
        SE: process.env.GATSBY_SHOPWARE_HAIBIKE_TOKEN_SE ?? '',
        GB: process.env.GATSBY_SHOPWARE_HAIBIKE_TOKEN_GB ?? '',
        IE: process.env.GATSBY_SHOPWARE_HAIBIKE_TOKEN_IE ?? '',
        NL: process.env.GATSBY_SHOPWARE_HAIBIKE_TOKEN_NL ?? '',
    },
};
const shopwareTokens: { [country: string]: string } = shopwareBrandTokens[brand] ?? {};

const shopwareBrandBaseUrls = {
    raleigh: {
        GB:
            process.env.GATSBY_SHOPWARE_RALEIGH_BASE_URL ??
            process.env.STORYBOOK_SHOPWARE_RALEIGH_BASE_URL ??
            '',
        IE: process.env.GATSBY_SHOPWARE_RALEIGH_BASE_URL ?? '',
    },
    lapierre: {
        FR: process.env.GATSBY_SHOPWARE_LAPIERRE_BASE_URL ?? '',
        GB:
            process.env.GATSBY_SHOPWARE_LAPIERRE_BASE_URL_GB ??
            process.env.GATSBY_SHOPWARE_LAPIERRE_BASE_URL ??
            '',
        NL: process.env.GATSBY_SHOPWARE_LAPIERRE_BASE_URL ?? '',
        ES: process.env.GATSBY_SHOPWARE_LAPIERRE_BASE_URL ?? '',
        BE: process.env.GATSBY_SHOPWARE_LAPIERRE_BASE_URL ?? '',
        IE: process.env.GATSBY_SHOPWARE_LAPIERRE_BASE_URL ?? '',
        DE: process.env.GATSBY_SHOPWARE_LAPIERRE_BASE_URL ?? '',
        IT: process.env.GATSBY_SHOPWARE_LAPIERRE_BASE_URL ?? '',
        LU: process.env.GATSBY_SHOPWARE_LAPIERRE_BASE_URL ?? '',
        CH: process.env.GATSBY_SHOPWARE_LAPIERRE_BASE_URL ?? '',
        INT: process.env.GATSBY_SHOPWARE_LAPIERRE_BASE_URL ?? '',
    },
    sparta: {
        NL: process.env.GATSBY_SHOPWARE_SPARTA_BASE_URL ?? '',
        DK: process.env.GATSBY_SHOPWARE_SPARTA_BASE_URL ?? '',
        BE: process.env.GATSBY_SHOPWARE_SPARTA_BASE_URL ?? '',
    },
    xlc: {
        GB: process.env.GATSBY_SHOPWARE_XLC_BASE_URL ?? '',
        NL: process.env.GATSBY_SHOPWARE_XLC_BASE_URL ?? '',
        DE: process.env.GATSBY_SHOPWARE_XLC_BASE_URL ?? '',
        ES: process.env.GATSBY_SHOPWARE_XLC_BASE_URL ?? '',
        FR: process.env.GATSBY_SHOPWARE_XLC_BASE_URL ?? '',
        INT: process.env.GATSBY_SHOPWARE_XLC_BASE_URL ?? '',
    },
    ghost: {
        DE: process.env.GATSBY_SHOPWARE_GHOST_BASE_URL ?? '',
        FI: process.env.GATSBY_SHOPWARE_GHOST_BASE_URL ?? '',
        IT: process.env.GATSBY_SHOPWARE_GHOST_BASE_URL ?? '',
        AT: process.env.GATSBY_SHOPWARE_GHOST_BASE_URL ?? '',
        SE: process.env.GATSBY_SHOPWARE_GHOST_BASE_URL ?? '',
        CH: process.env.GATSBY_SHOPWARE_GHOST_BASE_URL ?? '',
        ES: process.env.GATSBY_SHOPWARE_GHOST_BASE_URL ?? '',
        INT: process.env.GATSBY_SHOPWARE_GHOST_BASE_URL ?? '',
    },
    winora: {
        AT: process.env.GATSBY_SHOPWARE_WINORA_BASE_URL ?? '',
        CH: process.env.GATSBY_SHOPWARE_WINORA_BASE_URL ?? '',
        CZ: process.env.GATSBY_SHOPWARE_WINORA_BASE_URL ?? '',
        DE: process.env.GATSBY_SHOPWARE_WINORA_BASE_URL ?? '',
        ES: process.env.GATSBY_SHOPWARE_WINORA_BASE_URL ?? '',
        FR: process.env.GATSBY_SHOPWARE_WINORA_BASE_URL ?? '',
        IT: process.env.GATSBY_SHOPWARE_WINORA_BASE_URL ?? '',
        NL: process.env.GATSBY_SHOPWARE_WINORA_BASE_URL ?? '',
        PL: process.env.GATSBY_SHOPWARE_WINORA_BASE_URL ?? '',
    },
    haibike: {
        DE: process.env.GATSBY_SHOPWARE_HAIBIKE_BASE_URL ?? '',
        AT: process.env.GATSBY_SHOPWARE_HAIBIKE_BASE_URL ?? '',
        CH: process.env.GATSBY_SHOPWARE_HAIBIKE_BASE_URL ?? '',
        CZ: process.env.GATSBY_SHOPWARE_HAIBIKE_BASE_URL ?? '',
        ES: process.env.GATSBY_SHOPWARE_HAIBIKE_BASE_URL ?? '',
        FR: process.env.GATSBY_SHOPWARE_HAIBIKE_BASE_URL ?? '',
        BE: process.env.GATSBY_SHOPWARE_HAIBIKE_BASE_URL ?? '',
        IT: process.env.GATSBY_SHOPWARE_HAIBIKE_BASE_URL ?? '',
        PL: process.env.GATSBY_SHOPWARE_HAIBIKE_BASE_URL ?? '',
        FI: process.env.GATSBY_SHOPWARE_HAIBIKE_BASE_URL ?? '',
        SE: process.env.GATSBY_SHOPWARE_HAIBIKE_BASE_URL ?? '',
        GB: process.env.GATSBY_SHOPWARE_HAIBIKE_BASE_URL ?? '',
        IE: process.env.GATSBY_SHOPWARE_HAIBIKE_BASE_URL ?? '',
        NL: process.env.GATSBY_SHOPWARE_HAIBIKE_BASE_URL ?? '',
    },
    whiteLabel: {
        GB: process.env.STORYBOOK_SHOPWARE_WHITELABEL_BASE_URL ?? '',
    },
};

const shopwareBaseUrl: { [brand: string]: string } = shopwareBrandBaseUrls[brand] ?? {};

const storyblokTokens = {
    raleigh: process.env.GATSBY_STORYBLOK_RALEIGH_TOKEN ?? '',
    lapierre: process.env.GATSBY_STORYBLOK_LAPIERRE_TOKEN ?? '',
    sparta: process.env.GATSBY_STORYBLOK_SPARTA_TOKEN ?? '',
    xlc: process.env.GATSBY_STORYBLOK_XLC_TOKEN ?? '',
    ghost: process.env.GATSBY_STORYBLOK_GHOST_TOKEN ?? '',
    winora: process.env.GATSBY_STORYBLOK_WINORA_TOKEN ?? '',
    haibike: process.env.GATSBY_STORYBLOK_HAIBIKE_TOKEN ?? '',
};

const smartfitKeys = {
    raleigh: process.env.GATSBY_SMARTFIT_RALEIGH_API_KEY ?? '',
    lapierre: process.env.GATSBY_SMARTFIT_LAPIERRE_API_KEY ?? '',
    sparta: process.env.GATSBY_SMARTFIT_SPARTA_API_KEY ?? '',
    xlc: process.env.GATSBY_SMARTFIT_XLC_API_KEY ?? '',
    ghost: process.env.GATSBY_SMARTFIT_GHOST_API_KEY ?? '',
    winora: process.env.GATSBY_SMARTFIT_WINORA_API_KEY ?? '',
    haibike: process.env.GATSBY_SMARTFIT_HAIBIKE_API_KEY ?? '',
};

const recaptchaSiteKeys = {
    raleigh: process.env.GATSBY_RECAPTCHA_RALEIGH_SITE_KEY ?? '',
    lapierre: process.env.GATSBY_RECAPTCHA_LAPIERRE_SITE_KEY ?? '',
    sparta: process.env.GATSBY_RECAPTCHA_SPARTA_SITE_KEY ?? '',
    xlc: process.env.GATSBY_RECAPTCHA_XLC_SITE_KEY ?? '',
    ghost: process.env.GATSBY_RECAPTCHA_GHOST_SITE_KEY ?? '',
    winora: process.env.GATSBY_RECAPTCHA_WINORA_SITE_KEY ?? '',
    haibike: process.env.GATSBY_RECAPTCHA_HAIBIKE_SITE_KEY ?? '',
};

const gtmIds = {
    raleigh: process.env.GATSBY_GTM_RALEIGH_ID ?? '',
    lapierre: process.env.GATSBY_GTM_LAPIERRE_ID ?? '',
    sparta: process.env.GATSBY_GTM_SPARTA_ID ?? '',
    xlc: process.env.GATSBY_GTM_XLC_ID ?? '',
    ghost: process.env.GATSBY_GTM_GHOST_ID ?? '',
    winora: process.env.GATSBY_GTM_WINORA_ID ?? '',
    haibike: process.env.GATSBY_GTM_HAIBIKE_ID ?? '',
};

const cookieConsentIds = {
    raleigh: process.env.GATSBY_COOKIE_CONSENT_RALEIGH_ID ?? '',
    lapierre: process.env.GATSBY_COOKIE_CONSENT_LAPIERRE_ID ?? '',
    sparta: process.env.GATSBY_COOKIE_CONSENT_SPARTA_ID ?? '',
    xlc: process.env.GATSBY_COOKIE_CONSENT_XLC_ID ?? '',
    ghost: process.env.GATSBY_COOKIE_CONSENT_GHOST_ID ?? '',
    winora: process.env.GATSBY_COOKIE_CONSENT_WINORA_ID ?? '',
    haibike: process.env.GATSBY_COOKIE_CONSENT_HAIBIKE_ID ?? '',
};

const awinAdvertiserIds = {
    raleigh: process.env.GATSBY_AWIN_ADVERTISER_ID_RALEIGH ?? '',
    lapierre: process.env.GATSBY_AWIN_ADVERTISER_ID_LAPIERRE ?? '',
    sparta: process.env.GATSBY_AWIN_ADVERTISER_ID_SPARTA ?? '',
    xlc: process.env.GATSBY_AWIN_ADVERTISER_ID_XLC ?? '',
    ghost: process.env.GATSBY_AWIN_ADVERTISER_ID_GHOST ?? '',
    winora: process.env.GATSBY_AWIN_ADVERTISER_ID_WINORA ?? '',
    haibike: process.env.GATSBY_AWIN_ADVERTISER_ID_HAIBIKE ?? '',
};

const yotpoAccessKey = {
    raleigh: process.env.GATSBY_YOTPO_ACCESS_KEY_RALEIGH ?? '',
    lapierre: process.env.GATSBY_YOTPO_ACCESS_KEY_LAPIERRE ?? '',
    sparta: process.env.GATSBY_YOTPO_ACCESS_KEY_SPARTA ?? '',
    xlc: process.env.GATSBY_YOTPO_ACCESS_KEY_XLC ?? '',
    ghost: process.env.GATSBY_YOTPO_ACCESS_KEY_GHOST ?? '',
    winora: process.env.GATSBY_YOTPO_ACCESS_KEY_WINORA ?? '',
    haibike: process.env.GATSBY_YOTPO_ACCESS_KEY_HAIBIKE ?? '',
};

const yotpoHomeGalleryIds = {
    raleigh: process.env.GATSBY_YOTPO_HOME_GALLERY_ID_RALEIGH ?? '',
    lapierre: process.env.GATSBY_YOTPO_HOME_GALLERY_ID_LAPIERRE ?? '',
    sparta: process.env.GATSBY_YOTPO_HOME_GALLERY_ID_SPARTA ?? '',
    xlc: process.env.GATSBY_YOTPO_HOME_GALLERY_ID_XLC ?? '',
    ghost: process.env.GATSBY_YOTPO_HOME_GALLERY_ID_GHOST ?? '',
    winora: process.env.GATSBY_YOTPO_HOME_GALLERY_ID_WINORA ?? '',
    haibike: process.env.GATSBY_YOTPO_HOME_GALLERY_ID_HAIBIKE ?? '',
};

const yotpoPDPGalleryIds = {
    raleigh: process.env.GATSBY_YOTPO_PDP_GALLERY_ID_RALEIGH ?? '',
    lapierre: process.env.GATSBY_YOTPO_PDP_GALLERY_ID_LAPIERRE ?? '',
    sparta: process.env.GATSBY_YOTPO_PDP_GALLERY_ID_SPARTA ?? '',
    xlc: process.env.GATSBY_YOTPO_PDP_GALLERY_ID_XLC ?? '',
    ghost: process.env.GATSBY_YOTPO_PDP_GALLERY_ID_GHOST ?? '',
    winora: process.env.GATSBY_YOTPO_PDP_GALLERY_ID_WINORA ?? '',
    haibike: process.env.GATSBY_YOTPO_PDP_GALLERY_ID_HAIBIKE ?? '',
};

const flowboxGalleryKeys = {
    raleigh: process.env.GATSBY_FLOWBOX_KEY_RALEIGH ?? '',
    lapierre: process.env.GATSBY_FLOWBOX_KEY_LAPIERRE ?? '',
    sparta: process.env.GATSBY_FLOWBOX_KEY_SPARTA ?? '',
    xlc: process.env.GATSBY_FLOWBOX_KEY_XLC ?? '',
    ghost: process.env.GATSBY_FLOWBOX_KEY_GHOST ?? '',
    winora: process.env.GATSBY_FLOWBOX_KEY_WINORA ?? '',
    haibike: process.env.GATSBY_FLOWBOX_KEY_HAIBIKE ?? '',
};

const facebookPixelIds = {
    raleigh: process.env.GATSBY_FACEBOOK_PIXEL_RALEIGH_ID ?? undefined,
    lapierre: process.env.GATSBY_FACEBOOK_PIXEL_LAPIERRE_ID ?? undefined,
    sparta: process.env.GATSBY_FACEBOOK_PIXEL_SPARTA_ID ?? undefined,
    xlc: process.env.GATSBY_FACEBOOK_PIXEL_XLC_ID ?? undefined,
    ghost: process.env.GATSBY_FACEBOOK_PIXEL_GHOST_ID ?? undefined,
    winora: process.env.GATSBY_FACEBOOK_PIXEL_WINORA_ID ?? undefined,
    haibike: process.env.GATSBY_FACEBOOK_PIXEL_HAIBIKE_ID ?? undefined,
};

const facebookAdAccountIds = {
    raleigh: process.env.GATSBY_FACEBOOK_RALEIGH_AD_ACCOUNT_ID ?? undefined,
    lapierre: process.env.GATSBY_FACEBOOK_LAPIERRE_AD_ACCOUNT_ID ?? undefined,
    sparta: process.env.GATSBY_FACEBOOK_SPARTA_AD_ACCOUNT_ID ?? undefined,
    xlc: process.env.GATSBY_FACEBOOK_XLC_AD_ACCOUNT_ID ?? undefined,
    ghost: process.env.GATSBY_FACEBOOK_GHOST_AD_ACCOUNT_ID ?? undefined,
    winora: process.env.GATSBY_FACEBOOK_WINORA_AD_ACCOUNT_ID ?? undefined,
    haibike: process.env.GATSBY_FACEBOOK_HAIBIKE_AD_ACCOUNT_ID ?? undefined,
};

const facebookConversionApiAccessTokens = {
    raleigh: process.env.GATSBY_FACEBOOK_CONVERSION_RALEIGH_API_ACCESS_TOKEN ?? undefined,
    lapierre: process.env.GATSBY_FACEBOOK_CONVERSION_LAPIERRE_API_ACCESS_TOKEN ?? undefined,
    sparta: process.env.GATSBY_FACEBOOK_CONVERSION_SPARTA_API_ACCESS_TOKEN ?? undefined,
    xlc: process.env.GATSBY_FACEBOOK_CONVERSION_XLC_API_ACCESS_TOKEN ?? undefined,
    ghost: process.env.GATSBY_FACEBOOK_CONVERSION_GHOST_PI_ACCESS_TOKEN ?? undefined,
    winora: process.env.GATSBY_FACEBOOK_CONVERSION_WINORA_PI_ACCESS_TOKEN ?? undefined,
    haibike: process.env.GATSBY_FACEBOOK_CONVERSION_HAIBIKE_PI_ACCESS_TOKEN ?? undefined,
};

const sentryDsnTokens = {
    raleigh: process.env.GATSBY_RALEIGH_SENTRY_DSN ?? undefined,
    lapierre: process.env.GATSBY_LAPIERRE_SENTRY_DSN ?? undefined,
    sparta: process.env.GATSBY_SPARTA_SENTRY_DSN ?? undefined,
    xlc: process.env.GATSBY_XLC_SENTRY_DSN ?? undefined,
    ghost: process.env.GATSBY_GHOST_SENTRY_DSN ?? undefined,
    winora: process.env.GATSBY_WINORA_SENTRY_DSN ?? undefined,
    haibike: process.env.GATSBY_HAIBIKE_SENTRY_DSN ?? undefined,
};

const salesForceContactTokens = {
    raleigh: process.env.GATSBY_RALEIGH_SALESFORCE_CONTACT_TOKEN ?? '',
    lapierre: process.env.GATSBY_LAPIERRE_SALESFORCE_CONTACT_TOKEN ?? '',
    sparta: process.env.GATSBY_SPARTA_SALESFORCE_CONTACT_TOKEN ?? '',
    xlc: process.env.GATSBY_XLC_SALESFORCE_CONTACT_TOKEN ?? '',
    ghost: process.env.GATSBY_GHOST_SALESFORCE_CONTACT_TOKEN ?? '',
    winora: process.env.GATSBY_WINORA_SALESFORCE_CONTACT_TOKEN ?? '',
    haibike: process.env.GATSBY_HAIBIKE_SALESFORCE_CONTACT_TOKEN ?? '',
};

const batteryCategoryIds = {
    raleigh: process.env.GATSBY_RALEIGH_BATTERY_CATEGORY_ID ?? '',
    lapierre: process.env.GATSBY_LAPIERRE_BATTERY_CATEGORY_ID ?? '',
    sparta: process.env.GATSBY_SPARTA_BATTERY_CATEGORY_ID ?? '',
    xlc: process.env.GATSBY_XLC_BATTERY_CATEGORY_ID ?? '',
    ghost: process.env.GATSBY_GHOST_BATTERY_CATEGORY_ID ?? '',
    winora: process.env.GATSBY_WINORA_BATTERY_CATEGORY_ID ?? '',
    haibike: process.env.GATSBY_HAIBIKE_BATTERY_CATEGORY_ID ?? '',
};
const shopwareClientIds = {
    raleigh: process.env.GATSBY_RALEIGH_SHOPWARE_CLIENT_ID ?? '',
    lapierre: process.env.GATSBY_LAPIERRE_SHOPWARE_CLIENT_ID ?? '',
    sparta: process.env.GATSBY_SPARTA_SHOPWARE_CLIENT_ID ?? '',
    xlc: process.env.GATSBY_XLC_SHOPWARE_CLIENT_ID ?? '',
    ghost: process.env.GATSBY_GHOST_SHOPWARE_CLIENT_ID ?? '',
    winora: process.env.GATSBY_WINORA_SHOPWARE_CLIENT_ID ?? '',
    haibike: process.env.GATSBY_HAIBIKE_SHOPWARE_CLIENT_ID ?? '',
};
const shopwareClientSecrets = {
    raleigh: process.env.GATSBY_RALEIGH_SHOPWARE_CLIENT_SECRET ?? '',
    lapierre: process.env.GATSBY_LAPIERRE_SHOPWARE_CLIENT_SECRET ?? '',
    sparta: process.env.GATSBY_SPARTA_SHOPWARE_CLIENT_SECRET ?? '',
    xlc: process.env.GATSBY_XLC_SHOPWARE_CLIENT_SECRET ?? '',
    ghost: process.env.GATSBY_GHOST_SHOPWARE_CLIENT_SECRET ?? '',
    winora: process.env.GATSBY_WINORA_SHOPWARE_CLIENT_SECRET ?? '',
    haibike: process.env.GATSBY_HAIBIKE_SHOPWARE_CLIENT_SECRET ?? '',
};

const enableSSRProductPages = {
    raleigh: process.env.GATSBY_RALEIGH_ENABLE_SSR_PRODUCT_PAGES === 'true' ?? false,
    lapierre: process.env.GATSBY_LAPIERRE_ENABLE_SSR_PRODUCT_PAGES === 'true' ?? false,
    sparta: process.env.GATSBY_SPARTA_ENABLE_SSR_PRODUCT_PAGES === 'true' ?? false,
    xlc: process.env.GATSBY_XLC_ENABLE_SSR_PRODUCT_PAGES === 'true' ?? false,
    ghost: process.env.GATSBY_GHOST_ENABLE_SSR_PRODUCT_PAGES === 'true' ?? false,
    winora: process.env.GATSBY_WINORA_ENABLE_SSR_PRODUCT_PAGES === 'true' ?? false,
    haibike: process.env.GATSBY_HAIBIKE_ENABLE_SSR_PRODUCT_PAGES === 'true' ?? false,
};

const enableSSRDealerPages = {
    raleigh: process.env.GATSBY_RALEIGH_ENABLE_SSR_DEALER_PAGES === 'true' ?? false,
    lapierre: process.env.GATSBY_LAPIERRE_ENABLE_SSR_DEALER_PAGES === 'true' ?? false,
    sparta: process.env.GATSBY_SPARTA_ENABLE_SSR_DEALER_PAGES === 'true' ?? false,
    xlc: process.env.GATSBY_XLC_ENABLE_SSR_DEALER_PAGES === 'true' ?? false,
    ghost: process.env.GATSBY_GHOST_ENABLE_SSR_DEALER_PAGES === 'true' ?? false,
    winora: process.env.GATSBY_WINORA_ENABLE_SSR_DEALER_PAGES === 'true' ?? false,
    haibike: process.env.GATSBY_HAIBIKE_ENABLE_SSR_DEALER_PAGES === 'true' ?? false,
};

export const STORYBLOK_TOKEN = storyblokTokens[brand];
export const AWIN_ADVERTISER_ID = awinAdvertiserIds[brand];
export const BASE_URL = baseUrls[brand];
export const SMARTFIT_API_KEY = smartfitKeys[brand];
export const RECAPTCHA_SITE_KEY = recaptchaSiteKeys[brand];
export const GTM_ID = gtmIds[brand];
export const COOKIE_CONSENT_ID = cookieConsentIds[brand];
export const YOTPO_ACCESS_KEY = yotpoAccessKey[brand];
export const YOTPO_HOME_GALLERY_ID = yotpoHomeGalleryIds[brand];
export const YOTPO_PDP_GALLERY_ID = yotpoPDPGalleryIds[brand];
export const FLOWBOX_GALLERY_KEY = flowboxGalleryKeys[brand];
export const FACEBOOK_PIXEL_ID = facebookPixelIds[brand];
export const FACEBOOK_AD_ACCOUNT_ID = facebookAdAccountIds[brand];
export const FACEBOOK_CONVERSION_API_ACCESS_TOKEN = facebookConversionApiAccessTokens[brand];
export const SENTRY_DSN_TOKEN = sentryDsnTokens[brand];
export const SALESFORCE_CONTACT_TOKEN = salesForceContactTokens[brand];
export const BATTERY_CATEGORY_ID = batteryCategoryIds[brand];
export const SHOPWARE_CLIENT_ID = shopwareClientIds[brand];
export const SHOPWARE_CLIENT_SECRETS = shopwareClientSecrets[brand];
export const ENABLE_SSR_PRODUCT_PAGES = enableSSRProductPages[brand];
export const ENABLE_SSR_DEALER_PAGES = enableSSRDealerPages[brand];

export const getShopwareToken = (country: string) => {
    const token: string | undefined = shopwareTokens?.[country];
    if (!token) {
        throw new Error(`Unable to find shopware token for country ${country}!`);
    }
    return token;
};

export const getShopwareUrl = (country: string) => {
    const url: string | undefined = shopwareBaseUrl?.[country];
    if (!url) {
        throw new Error(`Unable to find shopware url for country ${country}!`);
    }
    return url;
};

const ensureToken = (token: string | undefined, name: string) => {
    if (!token) {
        throw new Error(`Missing token "${name}". Check your env vars.`);
    }
};

export const ensureBrand = () => {
    if (!brand) {
        throw new Error('Please specify a GATSBY_BRAND env var');
    }

    if (!brands.includes(brand)) {
        throw new Error(`Unknown brand "${brand}". Possible options:\n${brands.join('\n')}`);
    }
};

export const ensureTokens = () => {
    const brandName = brand.toUpperCase();
    ensureToken(process.env.GATSBY_STORYBLOK_GLOBAL_TOKEN, 'GATSBY_STORYBLOK_GLOBAL_TOKEN');
    ensureToken(STORYBLOK_TOKEN, `GATSBY_STORYBLOK_${brandName}_TOKEN`);
    countries.forEach(country => {
        ensureToken(shopwareTokens[country], `GATSBY_SHOPWARE_${brandName}_TOKEN_${country}`);
    });
    ensureToken(BASE_URL, `GATSBY_${brandName}_BASE_URL`);
};
