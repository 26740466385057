import React, { FC, SVGAttributes } from 'react';
import styled from 'styled-components';

const UIconLoader: FC<SVGAttributes<SVGElement>> = props => (
    <StyledSvg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 50 50"
        preserveAspectRatio="xMidYMid"
        data-test-id="loadingIcon"
        {...props}
    >
        <rect x="0" y="0" fill="none" />
        <circle
            cx="50%"
            cy="50%"
            r="40%"
            stroke="currentColor"
            strokeOpacity="0.2"
            fill="none"
            strokeWidth="4"
            strokeLinecap="round"
        >
            <animate
                id="animation1"
                attributeName="opacity"
                from="0.5"
                to="1"
                dur="1s"
                begin="0s;animation2.end"
            />
            <animate
                id="animation2"
                attributeName="opacity"
                from="1"
                to="0.5"
                dur="1s"
                begin="animation1.end"
            />
        </circle>
        <circle
            cx="50%"
            cy="50%"
            r="40%"
            stroke="currentColor"
            fill="none"
            strokeWidth="4"
            strokeLinecap="round"
        >
            <animate
                attributeName="stroke-dashoffset"
                dur="1.5s"
                repeatCount="indefinite"
                from="0"
                to="500"
            />
            <animate
                attributeName="stroke-dasharray"
                dur="1.5s"
                repeatCount="indefinite"
                values="150 100;1 250;150 100"
            />
        </circle>
    </StyledSvg>
);

// wrap inside a styled component, to have styled components filter out any non-svg props, in case
// a parent component has wrapped the current component in a styled component with props.
const StyledSvg = styled.svg``;

export default UIconLoader;
