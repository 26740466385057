import { ErrorMessage } from 'formik';
import { ErrorMessageProps } from 'formik/dist/ErrorMessage';
import React, { FC, ReactNode } from 'react';
import UMessage from './ui/UMessage';

const FieldError: FC<ErrorMessageProps> = props => (
    <ErrorMessage
        // eslint-disable-next-line react/no-unstable-nested-components
        component={({ children }: { children?: ReactNode }) => (
            <UMessage
                dataLayer={{
                    dataEvent: 'error_message_view',
                    type: 'form_field_error',
                    element: `${props.name}`,
                    description: `Form Fields: field error.`,
                }}
                variant="fail"
            >
                {children}
            </UMessage>
        )}
        {...props}
    />
);

export default FieldError;
