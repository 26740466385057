import isBrowser from './isBrowser';

export const setItemWithExpiry = (keyName: string, value: string, expiryInDays = 7) => {
    if (!isBrowser()) return;

    localStorage.setItem(keyName, value);
    localStorage.setItem(
        `${keyName}_expiry`,
        ((+new Date()).valueOf() + 1000 * 60 * 60 * 24 * expiryInDays).toString()
    );
};

export const getItemWithExpiry = (keyName: string) => {
    if (!isBrowser()) return null;

    const expiry = localStorage.getItem(`${keyName}_expiry`);

    // non-existent or expired?
    if (expiry === null || Number(expiry) < new Date().valueOf()) {
        return null;
    }

    return localStorage.getItem(keyName);
};

export const removeItemWithExpiry = (keyName: string) => {
    if (!isBrowser()) return;

    localStorage.removeItem(keyName);
    localStorage.removeItem(`${keyName}_expiry`);
};
