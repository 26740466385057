import React, { useRef, useEffect, useCallback } from 'react';
import { useActions } from 'react-api-data';
import { CheckoutPageContextProps, DefaultPageContextProps } from '../../types/internal';
import { GET_CART, INIT_CART } from '../../endpointConfig';
import useLocale from '../../hooks/useLocale';
import useShopwareDataContext, { createInitialState } from '../../hooks/useShopwareContext';

/**
 * This component is solely used to reset actions caches and context
 * when navigating from one country/language to another
 */
const Page = ({
    pageContext,
}: {
    pageContext: DefaultPageContextProps | CheckoutPageContextProps;
}) => {
    const prevLanguage = useRef<string>('');
    const prevCountry = useRef<string>('');

    const { language, country } = useLocale();

    const { dispatch } = useShopwareDataContext();

    const actions = useActions();

    const resetShopwareContextWithNewPageContext = useCallback(() => {
        // if for some reason `shopwareLanguageId` and `shopwareCurrency` are not set, don't do anything since they are required.
        if (!pageContext.shopwareLanguageId || !pageContext.shopwareCurrency) {
            return;
        }

        dispatch({
            type: 'UPDATE',
            payload: createInitialState({
                country: pageContext.country,
                shopwareLanguageId: pageContext.shopwareLanguageId,
                shopwareCurrency: pageContext.shopwareCurrency,
            }),
        });
    }, [
        dispatch,
        pageContext.country,
        pageContext.shopwareCurrency,
        pageContext.shopwareLanguageId,
    ]);

    useEffect(() => {
        if (prevLanguage.current !== language) {
            prevLanguage.current = language;

            // Make sure that the Shopware context is reset on a language change
            resetShopwareContextWithNewPageContext();
        }
    }, [language, prevLanguage, resetShopwareContextWithNewPageContext]);

    useEffect(() => {
        if (prevCountry.current !== country) {
            prevCountry.current = country;

            // Make sure that the cart context is reset on a country change
            actions.invalidateCache(INIT_CART);
            actions.invalidateCache(GET_CART);

            // Make sure that the Shopware context is reset on a country change
            resetShopwareContextWithNewPageContext();
        }
    }, [country, prevCountry, actions, resetShopwareContextWithNewPageContext]);

    return <> </>;
};

export default Page;
