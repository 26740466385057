import { createContext, useContext } from 'react';
import { FeatureType, StoryblokFeature } from '../types/feature';
import { getFeatureState } from '../services/storyblok/getFeatureState';

export interface Translations {
    // translations are structured per component
    [component: string]: {
        [key: string]: string;
    };
}

const FeaturesContext = createContext<StoryblokFeature[]>([]);

export const FeaturesProvider = FeaturesContext.Provider;

const env = process.env.GATSBY_ENV ?? process.env.NODE_ENV;

const forceFeatureDevModeFromEnvs = (featureType: FeatureType) => {
    if (env === 'prod') return undefined;

    const featuresOverriding = JSON.parse(process.env.GATSBY_OVERRIDE_FEATURE_DEV ?? '{}');
    if (featureType in featuresOverriding && !!featuresOverriding[featureType]) {
        return featuresOverriding[featureType];
    }

    return undefined;
};

const useFeatureSwitch = (featureType: FeatureType) => {
    const allFeatures = useContext<StoryblokFeature[]>(FeaturesContext);

    // DEV only
    const forceFeatureDevMode = forceFeatureDevModeFromEnvs(featureType);
    if (env !== 'prod' && forceFeatureDevMode) {
        return forceFeatureDevMode;
    }
    // END - DEV only

    return getFeatureState(allFeatures, featureType);
};

export default useFeatureSwitch;
