import React, { FC, SVGProps } from 'react';
import styled from 'styled-components';

type Props = SVGProps<SVGSVGElement>;

const UIconError: FC<Props> = ({ color, ...rest }) => (
    <svg width={24} height={24} viewBox="0 0 24 24" {...rest}>
        <g fill="none" fillRule="evenodd">
            <Circle cx={12} cy={12} r={12} />
            <g fill="#fff" transform="translate(2),rotate(45 10 10)">
                <path d="M12 7.333a1 1 0 011 1V11l2.667.001a1 1 0 010 2h-2.668L13 15.666a1 1 0 01-2 0v-2.668L8.332 13a1 1 0 010-2H11L11 8.332a1 1 0 011-1z" />
            </g>
        </g>
    </svg>
);

const Circle = styled.circle`
    fill: ${({ theme }) => theme.colors.formStatusErrorMain};
`;

export default UIconError;
