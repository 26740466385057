import React, { ReactNode, useEffect, useReducer } from 'react';
import { GatsbyBrowser } from 'gatsby';
import type { PageProps } from 'gatsby';
import { ThemeProvider } from 'styled-components';
import smoothscroll from 'smoothscroll-polyfill';
import { uiSiteConfig } from '../constants/uiConfig';
import { themeLight } from '../constants/theming/theme';
import { DefaultPageContextProps, CheckoutPageContextProps } from '../types/internal';
import sendFacebookConversionEvent, { events } from '../services/facebookConversionEvents';
import { TranslationsProvider } from '../hooks/useTranslations';
import { FeaturesProvider } from '../hooks/useFeatureSwitch';
import { LocaleProvider } from '../hooks/useLocale';
import {
    ShopwareContextProvider,
    shopwareContextReducer,
    createInitialState,
} from '../hooks/useShopwareContext';
import useQueryString from '../hooks/useQueryString';
import { WrapperColorProvider } from '../hooks/useWrapperColor';
import ErrorBoundary from './ErrorBoundary';
import GlobalStyle from './GlobalStyle';
import Page from './page/Page';
import isBrowser from '../services/isBrowser';

if (isBrowser()) {
    smoothscroll.polyfill();
}

export const AWC_KEY = 'awc';

type Props = Omit<
    PageProps<undefined, DefaultPageContextProps | CheckoutPageContextProps>,
    'children'
> & { children?: ReactNode };

export const WrapperContents: React.FC<Props> = ({ children, ...props }) => {
    const [shopwareContextState, shopwareContextDispatch] = useReducer(
        shopwareContextReducer,
        {
            country: props.pageContext.country,
            shopwareLanguageId: props.pageContext.shopwareLanguageId,
            shopwareCurrency: props.pageContext.shopwareCurrency,
        },
        createInitialState
    );

    return (
        <TranslationsProvider
            value={{
                translations: props.pageContext.translations ?? {},
                translationsUsed: props.pageContext.translationsUsed ?? new Set(),
            }}
        >
            <FeaturesProvider value={props.pageContext.allFeatures}>
                <ShopwareContextProvider
                    value={{
                        state: shopwareContextState,
                        dispatch: shopwareContextDispatch,
                    }}
                >
                    <WrapperColorProvider pageType={props.pageContext.pageType}>
                        <>
                            <Page pageContext={props.pageContext} />

                            {children}
                        </>
                    </WrapperColorProvider>
                </ShopwareContextProvider>
            </FeaturesProvider>
        </TranslationsProvider>
    );
};

export const PageComponent: React.FC<Props> = props => {
    const { country, language, pageType } = props.pageContext;
    const [{ awc }] = useQueryString();

    useEffect(() => {
        // SIDE EFFECT: Only once on change language

        const setHtmlGlobalLanguageAttributes = () => {
            if (isBrowser()) {
                const html = window.document.querySelector('html');
                if (language) {
                    html?.setAttribute('lang', language);
                } else {
                    html?.removeAttribute('lang');
                }
            }
        };
        if (isBrowser()) {
            if (window.document.readyState === 'complete') {
                setHtmlGlobalLanguageAttributes();
            } else {
                window.addEventListener('load', setHtmlGlobalLanguageAttributes);
            }
        }
        return () => {
            window.removeEventListener('load', setHtmlGlobalLanguageAttributes);
        };
    }, [language]);

    useEffect(() => {
        // SIDE EFFECT: Only once on page load

        if (isBrowser() && country && language) {
            sendFacebookConversionEvent(events.PAGE_VIEW, { country });

            try {
                fetch(window.location.href, { method: 'HEAD' }).then(response => {
                    window.dataLayer?.push({
                        pageType,
                        language,
                        country: country?.toLowerCase(),
                        environment: process.env.GATSBY_ENV ?? process.env.NODE_ENV,
                        git_hash: props.pageContext.gitHash,
                        page_status_code: response.status,
                    });
                });
            } catch {
                // no-op
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // SIDE EFFECT: Only once on init of awc query string

        if (isBrowser() && typeof awc === 'string' && uiSiteConfig.awinEnabled) {
            window.sessionStorage.setItem(AWC_KEY, awc);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [awc]);

    return (
        <ErrorBoundary unhandledError={props.pageContext.unhandledError}>
            {country && language ? (
                <LocaleProvider
                    value={{
                        country,
                        language,
                    }}
                >
                    <WrapperContents {...props} />
                </LocaleProvider>
            ) : (
                <WrapperContents {...props} />
            )}
        </ErrorBoundary>
    );
};

export default (({
    props,
    element,
}: {
    props: PageProps<undefined, DefaultPageContextProps | CheckoutPageContextProps>;
    element: ReactNode;
}) => {
    const isPreviewMode = isBrowser() && window.location.search.includes('_storyblok');

    return (
        <ThemeProvider theme={themeLight}>
            <>
                <GlobalStyle theme={themeLight} $isPreviewMode={isPreviewMode} />

                <PageComponent {...props}>{element}</PageComponent>
            </>
        </ThemeProvider>
    );
}) as unknown as GatsbyBrowser['wrapPageElement'];
