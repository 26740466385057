import { createContext } from 'react';
import { ElasticSearchDealer } from '../types/elasticSearchDealers';
import isBrowser from '../services/isBrowser';

type State = {
    activeDealer: ElasticSearchDealer | undefined;
    isOpen: boolean;
};

export const initialDealerOffCanvasPanelState: State = {
    activeDealer: undefined,
    isOpen: false,
};

type ActionType = 'SET' | 'TOGGLE' | 'OPEN';

type Action = {
    type: ActionType;
    payload?: ElasticSearchDealer;
};

export const dealerOffCanvasPanelReducer = (state: State, action: Action) => {
    const { type, payload } = action;

    switch (type) {
        case 'TOGGLE':
            // eslint-disable-next-line no-case-declarations
            const isOpen = !state.isOpen;

            if (isBrowser() && !isOpen) {
                const { pathname, search } = window.location;
                const newURL = pathname + search;
                window.history.replaceState(null, '', newURL);
            }
            return {
                ...state,
                isOpen,
            };
        case 'OPEN':
            return {
                ...state,
                isOpen: true,
            };
        case 'SET':
            if (isBrowser() && payload?.name) {
                window.location.hash = payload.name;
            }
            return {
                ...state,
                activeDealer: payload,
            };
        default:
            return state;
    }
};

export const DealerOffCanvasPanelContext = createContext<{
    state: State;
    dispatch: React.Dispatch<Action>;
}>({
    state: initialDealerOffCanvasPanelState,
    dispatch: () => null,
});
