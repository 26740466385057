import { EndpointConfig as ReactApiDataEndpointConfig } from 'react-api-data';
import getLocale from './getLocale';
import { getShopwareToken, getShopwareUrl } from './constants/tokens';
import { getItemWithExpiry } from './services/expiringLocalStorage';
import isBrowser from './services/isBrowser';

export const ADD_NEWSLETTER_SUBSCRIPTION = 'addNewsletterSubscription';
export const GET_STOCK = 'getStock';
export const INIT_CART = 'initCart';
export const GET_CART = 'getCart';
export const ADD_CART_ITEM = 'addCartItem';
export const ADD_PROMOTION = 'addPromotion';
export const UPDATE_CART_ITEM = 'updateCartItem';
export const REMOVE_CART_ITEM = 'removeCartItem';
export const GET_CART_STOCK = 'getCartStock';
export const GET_FREE_PRODUCTS_PROMOTION = 'getFreeProductsPromotion';
export const UPDATE_CART_SESSION_CONTEXT = 'updateCartSessionContext';
export const REGISTER_CART_SESSION_CUSTOMER = 'registerCartSessionCustomer';
export const GET_CUSTOMER = 'getCustomer';
export const CREATE_CART_SESSION_ORDER = 'createCartSessionOrder';
export const INITIATE_KLARNA_SESSION = 'initiateKlarnaSession';
export const INITIATE_ORDER_PAYMENT = 'initiateOrderPayment';
export const GET_ORDERS = 'getOrders';
export const GET_ORDERS_REFETCH = 'getOrdersRefetch';

interface EndpointConfig extends ReactApiDataEndpointConfig {
    shouldAutoTrigger?: boolean; // autoTrigger needs to be false for UseShopwareData Hook, shouldAutoTrigger will perform as autoTrigger in the hook
}

export const getCountry = () => {
    if (isBrowser()) {
        return getLocale(window.location.pathname).country;
    }
    return '';
};

export const getLanguage = () => {
    if (isBrowser()) {
        return getLocale(window.location.pathname).language;
    }
    return '';
};

export const shopwareUrl = (options?: { country?: string; isSalesChannelApi?: boolean }) => {
    const {
        isSalesChannelApi = false, // Defaults to store-api, only if we do not want it, we can fallback to sales-channel-api to allow gradual migration
        country = getCountry(),
    } = options ?? {};
    const shopwareApiPath = isSalesChannelApi ? '/sales-channel-api/v3' : '/store-api';
    if (country) {
        return `${getShopwareUrl(country)}${shopwareApiPath}`;
    }
    return '';
};

const setShopwareHeaders = (defaultHeaders: object) => ({
    ...defaultHeaders,
    'sw-access-key': getShopwareToken(getCountry()),
});

const setShopwareSessionHeaders = (defaultHeaders: object) => {
    // We assume at this point that the cart has been loaded, because all other cart calls will
    // wait for this call to complete through the useShopwareData hook.
    const token = getItemWithExpiry(`shopwareToken_${getCountry()}`);

    return {
        ...setShopwareHeaders(defaultHeaders),
        'sw-context-token': token,
    };
};

const customerSessionConfig = {
    setHeaders: setShopwareSessionHeaders,
};

const endpointConfig: { [endpointKey: string]: EndpointConfig } = {
    // Shopware General
    [ADD_NEWSLETTER_SUBSCRIPTION]: {
        /**
         * `newsletter/signup`: this is not the default shopware country context endpoint,
         *  but a custom endpoint that gets the countries of the sales channel since the store-api doesn't have this
         */
        url: `${shopwareUrl()}/newsletter/signup`, // [custom endpoint store-api]
        method: 'POST',
        setHeaders: setShopwareHeaders,
        autoTrigger: false,
        shouldAutoTrigger: false,
    },
    [GET_STOCK]: {
        /**
         * `stock/product`: this is not the default shopware endpoint,
         *  but a custom endpoint, since the store-api doesn't return this info
         */
        url: `${shopwareUrl()}/stock/product`, // [custom endpoint store-api]
        method: 'GET',
        setHeaders: setShopwareHeaders,
        autoTrigger: false,
        shouldAutoTrigger: false,
    },

    // Shopware Checkout
    /**
     * All checkout calls should wait for INIT_CART to complete. Therefore all calls should be run through
     * useShopwareData instead of using useApiData directly and all calls need to have autoTrigger
     * set to false. A custom prop 'shouldAutoTrigger' will allow you to specify autoTrigger behavior for Shopware calls.
     *
     * Note: never trigger two Shopware calls with the same context key simultaneously as this may cause a 500 error.
     */
    [INIT_CART]: {
        cacheDuration: 500, // in ms, use with caution. Preferably set to a low value to prevent multiple simultaneous calls.
        url: `${shopwareUrl()}/checkout/cart`,
        method: 'GET',
        autoTrigger: false,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        setHeaders: setShopwareHeaders,
    },
    [GET_CART]: {
        cacheDuration: 500, // in ms, use with caution. Preferably set to a low value to prevent multiple simultaneous calls.
        url: `${shopwareUrl()}/checkout/cart`,
        method: 'GET',
        autoTrigger: false,
        shouldAutoTrigger: true,
        ...customerSessionConfig,
    },
    [ADD_CART_ITEM]: {
        cacheDuration: 500, // in ms, use with caution. Preferably set to a low value to prevent multiple simultaneous calls.
        url: `${shopwareUrl()}/checkout/cart/line-item`,
        method: 'POST',
        autoTrigger: false,
        shouldAutoTrigger: false,
        ...customerSessionConfig,
        afterSuccess: ({ actions }) => {
            actions.invalidateCache(GET_CART);
            actions.invalidateCache(GET_CART_STOCK);
        },
    },
    [ADD_PROMOTION]: {
        cacheDuration: 500, // in ms, use with caution. Preferably set to a low value to prevent multiple simultaneous calls.
        url: `${shopwareUrl()}/checkout/cart/line-item`,
        method: 'POST',
        autoTrigger: false,
        shouldAutoTrigger: false,
        ...customerSessionConfig,
        afterSuccess: ({ actions }) => {
            actions.invalidateCache(GET_CART);
            actions.invalidateCache(GET_CART_STOCK);
        },
    },
    [UPDATE_CART_ITEM]: {
        cacheDuration: 500, // in ms, use with caution. Preferably set to a low value to prevent multiple simultaneous calls.
        url: `${shopwareUrl()}/checkout/cart/line-item`,
        method: 'PATCH',
        autoTrigger: false,
        shouldAutoTrigger: false,
        ...customerSessionConfig,
        afterSuccess: ({ actions }) => {
            actions.invalidateCache(GET_CART);
            actions.invalidateCache(GET_CART_STOCK);
        },
    },
    [REMOVE_CART_ITEM]: {
        cacheDuration: 500, // in ms, use with caution. Preferably set to a low value to prevent multiple simultaneous calls.
        url: `${shopwareUrl()}/checkout/cart/line-item/delete`,
        method: 'POST',
        autoTrigger: false,
        shouldAutoTrigger: false,
        ...customerSessionConfig,
        afterSuccess: ({ actions }) => {
            actions.invalidateCache(GET_CART);
            actions.invalidateCache(GET_CART_STOCK);
        },
    },
    [GET_CART_STOCK]: {
        cacheDuration: 500, // in ms, use with caution. Preferably set to a low value to prevent multiple simultaneous calls.
        /**
         * `stock/cart`: this is not the default shopware endpoint,
         *  but a custom endpoint, since the store-api doesn't return this info
         */
        url: `${shopwareUrl()}/stock/cart`, // [custom endpoint store-api]
        method: 'GET',
        autoTrigger: false,
        shouldAutoTrigger: true, // set this to true so it's triggered after product is added or removed from the cart
        ...customerSessionConfig,
    },
    [GET_FREE_PRODUCTS_PROMOTION]: {
        cacheDuration: 500, // in ms, use with caution. Preferably set to a low value to prevent multiple simultaneous calls.
        /**
         * `checkout/cart/check`: this is not the default shopware endpoint,
         *  but a custom endpoint, since the store-api doesn't return this info
         */
        url: `${shopwareUrl()}/checkout/cart/check`, // [custom endpoint store-api]
        method: 'GET',
        autoTrigger: false,
        shouldAutoTrigger: false,
        ...customerSessionConfig,
    },
    [UPDATE_CART_SESSION_CONTEXT]: {
        url: `${shopwareUrl()}/context`,
        method: 'PATCH',
        autoTrigger: false,
        shouldAutoTrigger: false,
        ...customerSessionConfig,
    },
    [REGISTER_CART_SESSION_CUSTOMER]: {
        url: `${shopwareUrl()}/account/register`,
        method: 'POST',
        autoTrigger: false,
        shouldAutoTrigger: false,
        ...customerSessionConfig,
    },
    [GET_CUSTOMER]: {
        url: `${shopwareUrl()}/account/customer`,
        method: 'POST',
        autoTrigger: false,
        shouldAutoTrigger: false,
        ...customerSessionConfig,
    },
    [CREATE_CART_SESSION_ORDER]: {
        url: `${shopwareUrl()}/checkout/order`,
        method: 'POST',
        autoTrigger: false,
        shouldAutoTrigger: false,
        ...customerSessionConfig,
    },
    [INITIATE_KLARNA_SESSION]: {
        url: `${shopwareUrl()}/checkout/klarna/session`,
        method: 'POST',
        autoTrigger: false,
        shouldAutoTrigger: false,
        ...customerSessionConfig,
    },
    [INITIATE_ORDER_PAYMENT]: {
        url: `${shopwareUrl()}/handle-payment`,
        method: 'POST',
        autoTrigger: false,
        shouldAutoTrigger: false,
        ...customerSessionConfig,
    },
    [GET_ORDERS]: {
        url: `${shopwareUrl()}/order`,
        method: 'POST',
        autoTrigger: false,
        shouldAutoTrigger: false,
        ...customerSessionConfig,
    },
    [GET_ORDERS_REFETCH]: {
        url: `${shopwareUrl()}/order`,
        method: 'POST',
        autoTrigger: false,
        shouldAutoTrigger: false,
        ...customerSessionConfig,
    },
};

export default endpointConfig;
