import { useLocation } from '@gatsbyjs/reach-router';
import { navigate } from 'gatsby';
import { useEffect, useMemo, useState, useCallback } from 'react';
import { parse, stringify } from 'query-string';
import isEqual from 'lodash/isEqual';

export type QueryParams = { [key: string]: string | string[] | null };

const cleanseParams = (queryParams: QueryParams) => {
    const cleansedParams = queryParams;
    Object.keys(queryParams).forEach(queryParam => {
        cleansedParams[queryParam] = cleanseParam(queryParams[queryParam]);
    });
    return cleansedParams;
};

const cleanseParam = (param: string | string[] | null) => {
    if (!param?.includes('utm_') || typeof param !== 'string') {
        return param;
    }
    return param.split('?utm_')[0];
};

interface Options {
    isDynamicSSRPageComponent?: boolean;
}

const useQueryString = (
    { isDynamicSSRPageComponent = false }: Options | undefined = {
        isDynamicSSRPageComponent: false,
    }
) => {
    const { search, pathname, hash } = useLocation();
    const queryParams = useMemo<QueryParams>(() => parse(search), [search]);
    const [params, setParams] = useState<QueryParams>(cleanseParams(queryParams));

    const setQueryParams = useCallback(
        (newParams: QueryParams, customPathname?: string): void => {
            const convertedParams = stringify(newParams);
            const withParams = Object.keys(newParams).length
                ? `${!customPathname ? pathname : customPathname}?${convertedParams}`
                : pathname;
            const to = hash.length ? withParams + hash : withParams;

            // We only force window navigate replace for dynamic SSR pages
            if (isDynamicSSRPageComponent) {
                window.location.replace(to);
            } else {
                navigate(to, { replace: false, state: { restoreScroll: false } });
            }

            setParams(newParams);
        },
        [hash, pathname, isDynamicSSRPageComponent]
    );

    useEffect(() => {
        const cleansedParams = cleanseParams(queryParams);
        if (!isEqual(params, cleansedParams)) {
            setParams(cleansedParams);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryParams]);

    return [params, setQueryParams] as const;
};

export default useQueryString;
