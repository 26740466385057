import { createContext, useContext } from 'react';
import { ShopwareCurrency } from '../types/shopwareCurrency';
import { ShopwareMedia } from '../types/shopwareProduct';
import isBrowser from '../services/isBrowser';
import {
    getItemWithExpiry,
    removeItemWithExpiry,
    setItemWithExpiry,
} from '../services/expiringLocalStorage';
import getLocale from '../getLocale';

type ActionType = 'UPDATE' | 'UPDATE_CONTEXT_TOKEN' | 'UPDATE_AUTH' | 'PUSH_MEDIA';

type State = {
    shopwareContextToken: string;
    shopwareLanguageId: string;
    shopwareCurrency: ShopwareCurrency;
    shopwareMedia?: ShopwareMedia[];
    shopwareAuth?: {
        token: string;
        tokenExpiry: string;
    };
};

type Action = {
    type: ActionType;
    payload: Partial<State>;
};

export const getCountry = () => {
    if (isBrowser()) {
        return getLocale(window.location.pathname).country;
    }
    return '';
};

const handleOnChangeShopwareTokenLocalStorage = (payload: Partial<State>) => {
    if (isBrowser() && getCountry() && 'shopwareContextToken' in payload) {
        const newToken = payload.shopwareContextToken ?? '';

        if (!newToken) {
            removeItemWithExpiry(`shopwareToken_${getCountry()}`);
        } else {
            setItemWithExpiry(`shopwareToken_${getCountry()}`, newToken);
        }
    }
};

const handleOnChangeShopwareAuthTokenLocalStorage = (payload: Partial<State>) => {
    if (isBrowser() && getCountry() && 'shopwareAuth' in payload) {
        const newToken = payload.shopwareAuth?.token ?? '';
        const newTokenExpiry = payload.shopwareAuth?.tokenExpiry ?? '';

        if (!newToken) {
            removeItemWithExpiry('shopwareToken_auth');
            removeItemWithExpiry('shopwareToken_auth_expiry');
        } else {
            setItemWithExpiry('shopwareToken_auth', newToken);
            setItemWithExpiry('shopwareToken_auth_expiry', newTokenExpiry);
        }
    }
};

export const shopwareContextReducer = (state: State, action: Action) => {
    const { type, payload } = action;

    switch (type) {
        case 'UPDATE': {
            handleOnChangeShopwareTokenLocalStorage(payload);
            handleOnChangeShopwareAuthTokenLocalStorage(payload);

            return {
                ...state,
                ...payload,
            };
        }
        case 'UPDATE_CONTEXT_TOKEN': {
            handleOnChangeShopwareTokenLocalStorage(payload);

            return {
                ...state,
                ...('shopwareContextToken' in payload && {
                    shopwareContextToken: payload.shopwareContextToken,
                }),
            };
        }
        case 'UPDATE_AUTH': {
            handleOnChangeShopwareAuthTokenLocalStorage(payload);

            return {
                ...state,
                ...('shopwareAuth' in payload && {
                    shopwareAuth: payload.shopwareAuth,
                }),
            };
        }
        case 'PUSH_MEDIA': {
            const payloadMediaIds =
                'shopwareMedia' in payload
                    ? (payload.shopwareMedia ?? []).map(payloadItem => payloadItem.id)
                    : [];
            return {
                ...state,
                ...('shopwareMedia' in payload && {
                    shopwareMedia: [
                        ...(state.shopwareMedia ?? []).filter(
                            item => !payloadMediaIds.includes(item.id)
                        ),
                        ...(payload.shopwareMedia ?? []),
                    ],
                }),
            };
        }
        default:
            return state;
    }
};

export const createInitialState = ({
    country,
    shopwareLanguageId,
    shopwareCurrency,
}: {
    country?: string;
    shopwareLanguageId?: string;
    shopwareCurrency?: ShopwareCurrency;
} = {}) => ({
    shopwareContextToken: country ? getItemWithExpiry(`shopwareToken_${country}`) ?? '' : '',
    shopwareLanguageId: shopwareLanguageId ?? '',
    shopwareCurrency:
        shopwareCurrency ??
        ({
            id: '',
            name: '',
            iso: '',
            symbol: '',
        } as ShopwareCurrency),
});

const ShopwareContextContext = createContext<{
    state: State;
    dispatch: React.Dispatch<Action>;
}>({
    state: createInitialState(),
    dispatch: () => null,
});

export const ShopwareContextProvider = ShopwareContextContext.Provider;

export const useShopwareContextToken = (): string =>
    useContext(ShopwareContextContext).state.shopwareContextToken;
export const useShopwareLanguageId = (): string =>
    useContext(ShopwareContextContext).state.shopwareLanguageId;
export const useShopwareCurrency = (): ShopwareCurrency =>
    useContext(ShopwareContextContext).state.shopwareCurrency;

const useShopwareDataContext = () => useContext(ShopwareContextContext);

export default useShopwareDataContext;
