import { useContext, useState, useEffect, createContext } from 'react';
import { COOKIE_CONSENT_ID } from '../constants/tokens';
import isBrowser from '../services/isBrowser';

type ActionType = 'UPDATE';

type Action = {
    type: ActionType;
    payload: string[];
};

export const cookieConsentReducer = (state: string[], action: Action) => {
    const { type, payload } = action;

    switch (type) {
        case 'UPDATE':
            return payload;
        default:
            return state;
    }
};

export const CookieConsentContext = createContext<{
    state: string[];
    dispatch: React.Dispatch<Action>;
}>({
    state: [],
    dispatch: () => null,
});

const useCookieConsentStatus = (oneTrustKeysToValidate: string[] = ['C0001']): boolean => {
    const cookieConsentContext = useContext(CookieConsentContext);
    const [hasConsent, setHasConsent] = useState<boolean>(false);

    useEffect(() => {
        if (!COOKIE_CONSENT_ID) {
            setHasConsent(true);
            return;
        }

        if (cookieConsentContext.state.length) {
            const hasEveryRequiredConsentKey = oneTrustKeysToValidate.every(key =>
                cookieConsentContext.state.includes(key)
            );

            if (oneTrustKeysToValidate.length === 1 && oneTrustKeysToValidate[0] === 'C0001') {
                // For the default cookies we check if the alert box has been closed or not to verify
                // if the user clicked the popup buttons, or not yet, if not we also set consent to false.
                if (
                    hasEveryRequiredConsentKey &&
                    isBrowser() &&
                    window.OneTrust.IsAlertBoxClosed()
                ) {
                    // Only nessesary cookies accepted, all other cookies rejected
                    setHasConsent(true);
                } else {
                    // No consent
                    setHasConsent(false);
                }
            } else if (hasEveryRequiredConsentKey) {
                // Cookies accepted
                setHasConsent(true);
            } else {
                // No consent
                setHasConsent(false);
            }
        } else {
            // No consent
            setHasConsent(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [oneTrustKeysToValidate, cookieConsentContext.state]);

    return hasConsent;
};

export default useCookieConsentStatus;
