import { createContext } from 'react';

type State = {
    plpHistory?: string;
    breadCrumbClicked?: boolean;
};

export const initialBreadcrumbHistoryState: State = {
    plpHistory: undefined,
    breadCrumbClicked: false,
};

type ActionType = 'SET_PLP' | 'SET_BREADCRUMB_CLICKED';

export type Action = {
    type: ActionType;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    payload?: any;
};

export const breadcrumbHistoryReducer = (state: State, action: Action) => {
    const { type, payload } = action;

    switch (type) {
        case 'SET_PLP':
            return {
                ...state,
                plpHistory: payload,
            };
        case 'SET_BREADCRUMB_CLICKED':
            return {
                ...state,
                breadCrumbClicked: payload,
            };
        default:
            return state;
    }
};

export const BreadcrumbHistoryContext = createContext<{
    state: State;
    dispatch: React.Dispatch<Action>;
}>({
    state: initialBreadcrumbHistoryState,
    dispatch: () => null,
});
