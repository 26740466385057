import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { configureApiData, reducer } from 'react-api-data';
import endpointConfig from '../endpointConfig';
import isBrowser from './isBrowser';

const createReduxStore = () => {
    const composeEnhancers =
        // eslint-disable-next-line
        (isBrowser() && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

    const store = createStore(
        combineReducers({ apiData: reducer }),
        composeEnhancers(applyMiddleware(thunk))
    );

    store.dispatch(configureApiData({}, endpointConfig));

    return store;
};

export default createReduxStore;
