import { parseUrl, stringifyUrl } from 'query-string';
import slugify from 'slugify';
import { enc } from 'crypto-js';
import IProduct, { IListProduct } from './types/product';
import { ElasticSearchListProduct } from './types/elasticSearchListProduct';
import { AttributeOption, ProductVariant } from './types/productVariant';
import { ElasticSearchDealer } from './types/elasticSearchDealers';
import { AlternativeSlugs } from './types/PageProps';
import { uiSiteConfig } from './constants/uiConfig';
import {
    PRODUCT_LISTING_PAGE_URL_SEGMENTATION_SEPARATOR_DEFAULT,
    PRODUCT_DETAIL_PAGE_URL_SEGMENTATION_SEPARATOR_DEFAULT,
    PRODUCT_LINE_DETAIL_PAGE_URL_SEGMENTATION_SEPARATOR_DEFAULT,
    PRODUCT_DETAIL_PAGE_PATH_PARENT_DEFAULT,
    PRODUCT_DETAIL_PAGE_PATH_CHILD_DEFAULT,
    IS_NODE_RUNTIME,
} from './constants';
import getBrandAttributeCodeFromOption from './constants/attributes';
import { ENABLE_SSR_PRODUCT_PAGES } from './constants/tokens';

const { urlStructure } = uiSiteConfig;

const getPLPUrlSegmentationSeparator = () =>
    urlStructure.productListing.urlSegmentation.overrideSeparator ??
    PRODUCT_LISTING_PAGE_URL_SEGMENTATION_SEPARATOR_DEFAULT;

const PDPParentPathIdentifierConfigSSR =
    // Can't be undefined AND can't be array without any items in it (`[]`)
    IS_NODE_RUNTIME &&
    ENABLE_SSR_PRODUCT_PAGES &&
    urlStructure.productDetail.path.parentProductSSR?.length
        ? urlStructure.productDetail.path.parentProductSSR
        : undefined;

const PDPParentPathIdentifierConfig =
    // Can't be undefined AND can't be array without any items in it (`[]`)
    urlStructure.productDetail.path.parentProduct?.length
        ? urlStructure.productDetail.path.parentProduct
        : undefined;

export const getPDPParentPathIdentifier = () =>
    PDPParentPathIdentifierConfigSSR ??
    PDPParentPathIdentifierConfig ??
    PRODUCT_DETAIL_PAGE_PATH_PARENT_DEFAULT;

export const getPDPChildPathIdentifier = () =>
    urlStructure.productDetail.path.childProduct ?? PRODUCT_DETAIL_PAGE_PATH_CHILD_DEFAULT;

export const getPDPUrlSegmentationSeparator = () =>
    urlStructure.productDetail.urlSegmentation.overrideSeparator ??
    PRODUCT_DETAIL_PAGE_URL_SEGMENTATION_SEPARATOR_DEFAULT;

const getProductLineUrlSeparator = () =>
    urlStructure.productLineDetail.urlSegmentation.overrideSeparator ??
    PRODUCT_LINE_DETAIL_PAGE_URL_SEGMENTATION_SEPARATOR_DEFAULT;

export const slugifyStringForUrl = (url: string): string =>
    slugify(url, { lower: true, strict: true });

// Encode a url element (lowercased) into a short enough simple url code that can be decoded
export const encodeShortSlugifyUrlElement = (urlElement: string): string =>
    slugifyStringForUrl(enc.Hex.stringify(enc.Utf8.parse(urlElement.toLowerCase())));

// Decode the url element as inverted of the function above
export const decodeShortSlugifyUrlElement = (encodedUrlElement: string): string =>
    enc.Hex.parse(encodedUrlElement).toString(enc.Utf8);

export const routeBase = ({
    country,
    language,

    // Only use when the GATSBY_BRAND env isn't available and the uiConfig fallsback to Raleigh as default.
    forceLocaleSeperator,
}: {
    country: string;
    language: string;

    // Only use when the GATSBY_BRAND env isn't available and the uiConfig fallsback to Raleigh as default.
    forceLocaleSeperator?: '-' | '/';
}): string =>
    `/${country.toLowerCase()}${forceLocaleSeperator ?? urlStructure.localeSeparator}${language}`;

export const ensureTrailingSlash = (url: string): string =>
    !url.endsWith('/') && !url.includes('?') ? `${url}/` : url;

export const homeRoute = (country: string, language: string): string =>
    `${routeBase({ country, language })}/`;

export const getBasicRoute = (country: string, language: string, path: string): string =>
    ensureTrailingSlash(
        routeBase({ country, language }) + (path.startsWith('/') ? '' : '/') + path
    );

export const startsWithLanguageCountryStoryblokFullSlug = (fullSlug: string) =>
    // e.g. en/GB/faqs/do-you-ship-abroad or en/INT/faqs/do-you-ship-abroad
    /^([a-z]{2})\/([A-Z]{2,3}\/).*/.test(fullSlug);

export const startsWithOnlyCountryStoryblokFullSlug = (fullSlug: string) =>
    // e.g. GB/faqs/do-you-ship-abroad or INT/faqs/do-you-ship-abroad
    /^([A-Z]{2,3}\/).*/.test(fullSlug);

const startsWithOnlyLanguageStoryblokFullSlug = (fullSlug: string) =>
    // e.g. en/faqs/do-you-ship-abroad or fr/faqs/do-you-ship-abroad
    /^([a-z]{2}\/).*/.test(fullSlug);

export const getStoryblokFullSlugWithoutCountryLanguage = (fullSlug: string) => {
    let relative = fullSlug;

    // this is for ContentRenderer, if there is a internal url linked (story)
    // then we need to remove the first two parts e.g. GB/faqs/do-you-ship-abroad, en/GB/faqs/do-you-ship-abroad or en/INT/faqs/do-you-ship-abroad
    if (startsWithLanguageCountryStoryblokFullSlug(fullSlug)) {
        relative = fullSlug.split('/').slice(2).join('/');
    } else if (startsWithOnlyCountryStoryblokFullSlug(fullSlug)) {
        relative = fullSlug.split('/').slice(1).join('/');
    } else if (startsWithOnlyLanguageStoryblokFullSlug(fullSlug)) {
        relative = fullSlug.split('/').slice(1).join('/');
    }

    // ensure it starts with a slash, because we want to add country/language in front again.
    if (relative.charAt(0) !== '/') {
        relative = `/${relative}`;
    }

    return relative;
};

export const getStoryblokFullSlugCountryLanguage = (fullSlug: string) => {
    let country;
    let language;

    // this is for ContentRenderer, if there is a internal url linked (story)
    // then we need to remove the first two parts e.g. GB/faqs/do-you-ship-abroad, en/GB/faqs/do-you-ship-abroad or en/INT/faqs/do-you-ship-abroad
    if (startsWithLanguageCountryStoryblokFullSlug(fullSlug)) {
        language = fullSlug.split('/')?.[0] ?? '';
        country = fullSlug.split('/')?.[1] ?? '';
    } else if (startsWithOnlyCountryStoryblokFullSlug(fullSlug)) {
        country = fullSlug.split('/')?.[0] ?? '';
    } else if (startsWithOnlyLanguageStoryblokFullSlug(fullSlug)) {
        language = fullSlug.split('/')?.[0] ?? '';
    }

    return { country, language };
};

export const getRelativeSlugWithoutCountryLanguage = (path: string) => {
    let relative = path;

    // regex for /gb/en/ or /gb-en/ or /int-en/ by default, extended to use the separator as well
    const regex = new RegExp(
        `^(?:/[a-zA-Z]{2,3}[-/${urlStructure.localeSeparator}][a-zA-Z]{2}/)?(.*)`
    );
    const matches = regex.exec(path);
    relative = matches?.length === 2 ? matches[1] : relative;

    // ensure it starts with a slash, because we want to add country/language in front again.
    if (relative.charAt(0) !== '/') {
        relative = `/${relative}`;
    }

    return relative;
};

// for relative urls from storyblok makes sure that the correct country and language is used
// e.g. given routeBase = /gb/en/ it converts:
// /gb-en/, /fr-en/, /fr/en/ -> /gb/en/
export const ensureCorrectCountryLanguage = (
    country: string,
    language: string,
    path: string
): string => {
    if (path.startsWith(routeBase({ country, language }))) return path;
    const relative = getRelativeSlugWithoutCountryLanguage(path);
    return `${routeBase({ country, language })}${relative}`;
};

const getProductBasePath = ({
    parentProduct,

    // Only use when the GATSBY_BRAND env isn't available and the uiConfig fallsback to Raleigh as default.
    forcePDPParentPathIdentifier,
}: {
    parentProduct: Partial<IProduct> | Partial<IListProduct> | Partial<ElasticSearchListProduct>;

    // Only use when the GATSBY_BRAND env isn't available and the uiConfig fallsback to Raleigh as default.
    forcePDPParentPathIdentifier?: { productKey: keyof IProduct; shouldHash?: boolean }[];
}): string | undefined => {
    const parentPathIdentifier = forcePDPParentPathIdentifier ?? getPDPParentPathIdentifier();

    const parentProductToCheck = {
        ...parentProduct,
        // We need to make sure we always use the parentModel for the productRoute cause that is used in the base path (`product.name` could also be variant name sometimes) (when model is present it's 99% sure ElasticSearchListProduct and sometimes its a variant)
        ...('model' in parentProduct && !!parentProduct.model ? { name: parentProduct.model } : {}),
        // We need to make sure we always use the parentSku for the productRoute cause that is used in the base path (`product.sku` could also be variant sku sometimes) (when parentSku is present it's 99% sure ElasticSearchListProduct and sometimes its a variant)
        ...('parentSku' in parentProduct && !!parentProduct.parentSku
            ? { sku: parentProduct.parentSku }
            : {}),
    } as Partial<IProduct>;

    let basePath = '';
    parentPathIdentifier.forEach(({ productKey, shouldHash }) => {
        const productFieldValue =
            productKey in parentProductToCheck ? parentProductToCheck[productKey] ?? '' : '';
        const productFieldUrlAddition = shouldHash
            ? encodeShortSlugifyUrlElement(`${productFieldValue}`)
            : productFieldValue;

        if (productFieldValue) {
            basePath += basePath.length ? `-${productFieldUrlAddition}` : productFieldUrlAddition;
        }
    });

    if (!basePath) {
        return undefined;
    }

    return slugifyStringForUrl(basePath);
};

const getProductChildPath = ({
    childProductVariant,
    language,

    // Only use when the GATSBY_BRAND env isn't available and the uiConfig fallsback to Raleigh as default.
    forcePDPChildPathIdentifier,
}: {
    childProductVariant?: Partial<ProductVariant>;
    language: string;

    // Only use when the GATSBY_BRAND env isn't available and the uiConfig fallsback to Raleigh as default.
    forcePDPChildPathIdentifier?: { variantAttributeOption: AttributeOption };
}): string | undefined => {
    if (!childProductVariant) {
        return undefined;
    }

    const childPathIdentifier = forcePDPChildPathIdentifier ?? getPDPChildPathIdentifier();

    let childPath = '';
    if (childPathIdentifier?.variantAttributeOption) {
        const optionName =
            childProductVariant?.options?.find(
                option =>
                    option.code ===
                    getBrandAttributeCodeFromOption({
                        language,
                        attributeOption: childPathIdentifier?.variantAttributeOption,
                    })
            )?.value ?? '';

        if (optionName) {
            childPath += childPath.length ? `-${optionName}` : optionName;
        }
    }

    if (!childPath) {
        return undefined;
    }

    return slugifyStringForUrl(childPath);
};

export const getProductRouteBase = ({
    country,
    language,

    // Only use when the GATSBY_BRAND env isn't available and the uiConfig fallsback to Raleigh as default.
    forceLocaleSeperator,
    forceUrlSegmentation,
}: {
    country: string;
    language: string;

    // Only use when the GATSBY_BRAND env isn't available and the uiConfig fallsback to Raleigh as default.
    forceLocaleSeperator?: '-' | '/';
    forceUrlSegmentation?: boolean;
}): string =>
    `${routeBase({ country, language, forceLocaleSeperator })}/${
        !!forceUrlSegmentation || urlStructure.productDetail.urlSegmentation.enabled
            ? `${getPDPUrlSegmentationSeparator()}/`
            : ''
    }`;

export const getProductRoute = ({
    country,
    language,

    parentProduct,
    childProductVariant,

    // Only use when the GATSBY_BRAND env isn't available and the uiConfig fallsback to Raleigh as default.
    forceLocaleSeperator,
    forceUrlSegmentation,
    forcePDPParentPathIdentifier,
    forcePDPChildPathIdentifier,
}: {
    country: string;
    language: string;

    parentProduct: Partial<IProduct> | Partial<IListProduct> | Partial<ElasticSearchListProduct>;
    childProductVariant?: Partial<ProductVariant>;

    // Only use when the GATSBY_BRAND env isn't available and the uiConfig fallsback to Raleigh as default.
    forceLocaleSeperator?: '-' | '/';
    forceUrlSegmentation?: boolean;
    forcePDPParentPathIdentifier?: { productKey: keyof IProduct }[];
    forcePDPChildPathIdentifier?: { variantAttributeOption: AttributeOption };
}): string => {
    const basePath = getProductBasePath({ parentProduct, forcePDPParentPathIdentifier });
    const childPath = getProductChildPath({
        childProductVariant,
        language,
        forcePDPChildPathIdentifier,
    });

    if (!basePath) {
        return '';
    }

    return `${getProductRouteBase({
        country,
        language,
        forceLocaleSeperator,
        forceUrlSegmentation,
    })}${basePath}${childPath ? `/${childPath}` : ''}/`;
};

export const getProductRouteParamsFromUrl = ({
    country,
    language,

    url,

    // Only use when the GATSBY_BRAND env isn't available and the uiConfig fallsback to Raleigh as default.
    forceLocaleSeperator,
    forceUrlSegmentation,
}: {
    country: string;
    language: string;

    url: string;

    // Only use when the GATSBY_BRAND env isn't available and the uiConfig fallsback to Raleigh as default.
    forceLocaleSeperator?: '-' | '/';
    forceUrlSegmentation?: boolean;
}) => {
    const fullProductUrl = url.at(0) === '/' ? url : `/${url}`;
    const fullProductPath = fullProductUrl
        // Remove the query string from the path
        .split('?')[0]
        // Remove `page-data.json` string from the path
        .split('page-data.json')[0]
        // Remove the base path from the URL > result is possibly undefined
        .split(
            getProductRouteBase({
                country,
                language,
                forceLocaleSeperator,
                forceUrlSegmentation,
            })
        )[1];

    if (!fullProductPath) {
        return {
            parentIdentifier: undefined,
            childIdentifier: undefined,
        };
    }

    const firstUrlElement = fullProductPath.split('/')[0];
    const secondUrlElement = fullProductPath.split('/')[1];

    let parentIdentifier;
    if (firstUrlElement) {
        parentIdentifier = firstUrlElement;
    }
    let childIdentifier;
    if (secondUrlElement) {
        childIdentifier = secondUrlElement;
    }

    return {
        parentIdentifier,
        childIdentifier,
    };
};

// Check in PdpUrlStore and match parentIdentifier with the path to get the product's sku
export const getProductRouteSkuFromUrl = ({
    country,
    language,
    uniqueFlattenedPdpUrlStore,

    parentIdentifier,

    // Only use when the GATSBY_BRAND env isn't available and the uiConfig fallsback to Raleigh as default.
    forceLocaleSeperator,
    forceUrlSegmentation,
}: {
    country: string;
    language: string;
    uniqueFlattenedPdpUrlStore: AlternativeSlugs[];

    parentIdentifier: string;

    // Only use when the GATSBY_BRAND env isn't available and the uiConfig fallsback to Raleigh as default.
    forceLocaleSeperator?: '-' | '/';
    forceUrlSegmentation?: boolean;
}) => {
    const productRoute = `${getProductRouteBase({
        country,
        language,
        forceLocaleSeperator,
        forceUrlSegmentation,
    })}${parentIdentifier}/`;
    const matchingProduct = uniqueFlattenedPdpUrlStore.find(
        alternativeSlug => alternativeSlug.path === productRoute
    );

    return matchingProduct?.id ?? undefined;
};

export const getDealerRouteBase = ({
    country,
    language,

    identifier,

    // Only use when the GATSBY_BRAND env isn't available and the uiConfig fallsback to Raleigh as default.
    forceLocaleSeperator,
}: {
    country: string;
    language: string;

    identifier: string | undefined;

    // Only use when the GATSBY_BRAND env isn't available and the uiConfig fallsback to Raleigh as default.
    forceLocaleSeperator?: '-' | '/';
}): string =>
    `${routeBase({ country, language, forceLocaleSeperator })}/${
        identifier ? `${identifier}/` : ''
    }`;

export const getDealerRoute = ({
    country,
    language,

    identifier,
    dealer,

    forceLocaleSeperator,
}: {
    country: string;
    language: string;

    identifier: string | undefined;
    dealer: ElasticSearchDealer;

    // Only use when the GATSBY_BRAND env isn't available and the uiConfig fallsback to Raleigh as default.
    forceLocaleSeperator?: '-' | '/';
}) => {
    let params = dealer.name;
    if (dealer.city) {
        params += `-${dealer.city}`;
    }
    if (dealer.addressLine1) {
        params += `-${dealer.addressLine1}`;
    }
    const path = slugifyStringForUrl(params);

    return `${getDealerRouteBase({ country, language, identifier, forceLocaleSeperator })}${path}/`;
};

export const getDealerRouteParamsFromUrl = ({
    country,
    language,

    identifier,
    url,

    // Only use when the GATSBY_BRAND env isn't available and the uiConfig fallsback to Raleigh as default.
    forceLocaleSeperator,
}: {
    country: string;
    language: string;

    identifier: string | undefined;
    url: string;

    // Only use when the GATSBY_BRAND env isn't available and the uiConfig fallsback to Raleigh as default.
    forceLocaleSeperator?: '-' | '/';
}) => {
    const fullDealerUrl = url.at(0) === '/' ? url : `/${url}`;
    const fullDealerPath = fullDealerUrl
        // Remove the query string from the path
        .split('?')[0]
        // Remove `page-data.json` string from the path
        .split('page-data.json')[0]
        // Remove the base path from the URL > result is possibly undefined
        .split(
            getDealerRouteBase({
                country,
                language,
                identifier,
                forceLocaleSeperator,
            })
        )[1];

    if (!fullDealerPath) {
        return {
            pathIdentifier: undefined,
        };
    }

    const firstUrlElement = fullDealerPath.split('/')[0];

    let pathIdentifier;
    if (firstUrlElement) {
        pathIdentifier = firstUrlElement;
    }

    return {
        pathIdentifier,
    };
};

export const relativeRouteUrlSegmentation = ({
    relative,

    // Only use when the GATSBY_BRAND env isn't available and the uiConfig fallsback to Raleigh as default.
    forceUrlSegmentation,
}: {
    relative: string;

    // Only use when the GATSBY_BRAND env isn't available and the uiConfig fallsback to Raleigh as default.
    forceUrlSegmentation?: boolean;
}) => {
    if (!(urlStructure.productListing.urlSegmentation.enabled || forceUrlSegmentation)) {
        return relative;
    }

    /**
     * if urlSegmentation is set to true it means that plps in storyblok will
     * be under a `/${getPLPUrlSegmentationSeparator()}/` aka '/l/' folder
     * and that plp urls need to be flattered.
     *
     * So below we take the first and last part of the relative path
     * e.g. /l/bikes/mountain-bikes -> /l/mountain-bikes
     */
    const relativeWithLeadingSlash = relative.charAt(0) !== '/' ? `/${relative}` : relative;
    if (relativeWithLeadingSlash.includes(`/${getPLPUrlSegmentationSeparator()}/`)) {
        const parts = relativeWithLeadingSlash.split('/').filter(part => !!part);
        const category = parts.slice(-1);
        let flattenedRelative = [parts[0], category].join('/');
        // ensure it starts with a slash
        if (flattenedRelative.charAt(0) !== '/') {
            flattenedRelative = `/${flattenedRelative}`;
        }
        return flattenedRelative;
    }

    return relative;
};

export const siteStructureRoute = ({
    country,
    language,
    fullSlug,

    // Only use when the GATSBY_BRAND env isn't available and the uiConfig fallsback to Raleigh as default.
    forceLocaleSeperator,
    forceUrlSegmentation,
}: {
    country: string;
    language: string;
    fullSlug: string;

    // Only use when the GATSBY_BRAND env isn't available and the uiConfig fallsback to Raleigh as default.
    forceLocaleSeperator?: '-' | '/';
    forceUrlSegmentation?: boolean;
}) => {
    let relative;
    if (fullSlug.includes('sitecentralcontent/')) {
        // eslint-disable-next-line prefer-destructuring
        relative = fullSlug.split('sitecentralcontent/')[1];
    } else if (fullSlug.includes('sitestructure/')) {
        // eslint-disable-next-line prefer-destructuring
        relative = fullSlug.split('sitestructure/')[1];

        // sitestructure routes could have segmentation
        relative = relativeRouteUrlSegmentation({ relative, forceUrlSegmentation });
    } else if (fullSlug.includes(`sitepages/structure-override-${country}/`)) {
        // eslint-disable-next-line prefer-destructuring
        relative = fullSlug.split(`sitepages/structure-override-${country}/`)[1];

        // sitepages routes could have segmentation
        relative = relativeRouteUrlSegmentation({ relative, forceUrlSegmentation });
    } else if (fullSlug.includes('sitepages/structure/')) {
        // eslint-disable-next-line prefer-destructuring
        relative = fullSlug.split('sitepages/structure/')[1];

        // sitepages routes could have segmentation
        relative = relativeRouteUrlSegmentation({ relative, forceUrlSegmentation });
    } else {
        relative = getStoryblokFullSlugWithoutCountryLanguage(fullSlug);
    }

    // ensure it starts with a slash, because we want to add country/language in front again.
    if (relative.charAt(0) !== '/') {
        relative = `/${relative}`;
    }
    const { query, ...rest } = parseUrl(relative);
    const { url } = rest;
    return ensureTrailingSlash(
        `${routeBase({ country, language, forceLocaleSeperator })}${stringifyUrl({
            url,
            query,
        })}`
    );
};

export const alternateRoute = ({
    country,
    language,
    fullSlug,
}: {
    country: string;
    language: string;
    fullSlug: string;
}) => {
    let slug = fullSlug;

    if (urlStructure.productLineDetail.urlSegmentation.enabled) {
        slug = slug.replace('/productlines/', `/${getProductLineUrlSeparator()}/`);
    } else {
        slug = slug.replace('/productlines/', '/');
    }

    return siteStructureRoute({ country, language, fullSlug: slug });
};

// aliases for convenience
export const categoryRoute = siteStructureRoute;
export const publicationRoute = siteStructureRoute;
export const publicationListingRoute = siteStructureRoute;
export const campaignRoute = siteStructureRoute;
export const campaignListingRoute = siteStructureRoute;
export const productLineListingRoute = siteStructureRoute;
export const storeLocatorRoute = siteStructureRoute;
export const bikeComparisonRoute = siteStructureRoute;
export const iFrameRoute = siteStructureRoute;

export const basketRoute = (country: string, language: string): string =>
    `${routeBase({ country, language })}/basket/`;

export const checkoutRoute = (country: string, language: string): string =>
    `${routeBase({ country, language })}/checkout/`;

export const orderConfirmRoute = (country: string, language: string): string =>
    `${routeBase({ country, language })}/order-confirm/`;

export const searchRoute = (country: string, language: string): string =>
    `${routeBase({ country, language })}/search/`;

export const productLineRoute = (country: string, language: string, fullSlug: string) => {
    let slug = siteStructureRoute({ country, language, fullSlug });

    if (urlStructure.productLineDetail.urlSegmentation.enabled) {
        if (slug.includes('productlines')) {
            slug = slug.replace('/productlines/', `/${getProductLineUrlSeparator()}/`);
        } else {
            slug = `${routeBase({
                country,
                language,
            })}/${getProductLineUrlSeparator()}${getRelativeSlugWithoutCountryLanguage(slug)}`;
        }
    }

    return slug;
};

export const routeWithIdentifier = (
    country: string,
    language: string,
    identifier?: string,
    detailPagePath?: string
) => {
    let identifierSlug = '';
    if (identifier) {
        identifierSlug = `${identifier}/`;
    }
    let path = '';
    if (detailPagePath) {
        path = slugifyStringForUrl(detailPagePath);
    }
    return `${routeBase({ country, language })}/${identifierSlug}${path}`;
};
