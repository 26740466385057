import { useCallback, useEffect, useMemo, useState } from 'react';
import { ShopwareProduct } from '../../types/shopwareProduct';
import getShopwareProducts from '../../services/product/shopware/getShopwareProducts';
import { logger } from '../../services/logger/logger';
import useLocale from '../useLocale';
import { useShopwareLanguageId } from '../useShopwareContext';

export type RequestStatus = 'ready' | 'loading' | 'success' | 'failed';

type ReturnType = {
    shopwareProducts: ShopwareProduct[];
    shopwareProductsRequestStatus: RequestStatus;
};

const useFetchShopwareProducts = ({
    productIds,
    productRequestBodyOverride,
    shouldFetch = true,
}: {
    productIds?: string[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    productRequestBodyOverride?: { [key: string]: any };
    shouldFetch?: boolean;
}): ReturnType => {
    const { country } = useLocale();
    const shopwareLanguageId = useShopwareLanguageId();

    const [requestStatus, setRequestStatus] = useState<RequestStatus>('ready');
    const [shopwareProducts, setShopwareProducts] = useState<ShopwareProduct[]>([]);

    // 1 - Memoized param based on `productIds` to trigger refetch only when needed
    const fetchProductIds = useMemo(
        () => (productIds ? productIds.sort() : undefined),
        [productIds]
    );
    // 2 - Memoized param based on `productRequestBodyOverride` to trigger refetch only when needed
    const fetchBodyOverrideString = useMemo(
        () => JSON.stringify(productRequestBodyOverride),
        [productRequestBodyOverride]
    );

    const fetchData = useCallback(async () => {
        if (!shouldFetch) {
            return;
        }

        setRequestStatus('loading');

        try {
            const products = await getShopwareProducts({
                country,
                shopwareLanguageId,
                productIds: fetchProductIds,
                productRequestBodyOverride,
            });

            setShopwareProducts(products);
            setRequestStatus('success');
        } catch (error) {
            logger.allNotProd().error().client('useFetchShopwareProducts - Error', error);

            setShopwareProducts([]);
            setRequestStatus('failed');
        }
    }, [shouldFetch, country, shopwareLanguageId, productRequestBodyOverride, fetchProductIds]);

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldFetch, fetchProductIds, fetchBodyOverrideString]);

    return {
        shopwareProducts,
        shopwareProductsRequestStatus: requestStatus,
    };
};

export default useFetchShopwareProducts;
