import { Translations } from '../hooks/useTranslations';
import { CaseFormArgs, CaseFormConfig, CaseFormField } from './caseForm';
import { SALESFORCE_CONTACT_TOKEN } from './tokens';
import worldCountries, { getCountryInfo } from './worldCountries';

const countryOptions = worldCountries.map(ct => ({
    value: ct.name,
    label: ct.name,
}));

const getTypeOptions = (translations: Translations['salesForceForm']) => {
    const options = [
        {
            value: '',
            label: translations.typePlaceHolder,
        },
        {
            value: 'Information Request',
            label: translations.typeInformationRequest,
        },
        {
            value: 'Order-,delivery- or invoice',
            label: translations.typeOrderDeliveryOrInvoice,
        },
        {
            value: 'Shipment and Transport',
            label: translations.typeShipmentAndTransport,
        },
        {
            value: 'Dealer-Service',
            label: translations.typeDealerService,
        },
        {
            value: 'Suggestion Box',
            label: translations.typeSuggestionBox,
        },
        {
            value: 'Requests',
            label: translations.typeRequests,
        },
    ];

    return options.filter(Boolean);
};

const getRaleighCaseFormConfig = ({
    country,
    translations,
    language,
}: CaseFormArgs): CaseFormConfig => ({
    brand: 'Raleigh',
    webSource: 'Raleigh Website',
    captchaKey: SALESFORCE_CONTACT_TOKEN,
    fields: [
        {
            id: 'type',
            salesforceId: 'type',
            type: 'dropdown',
            options: getTypeOptions(translations),
            validation: {
                required: true,
                requiredMessage: translations.requiredType,
            },
        },
        {
            id: 'subject',
            salesforceId: 'subject',
            type: 'text',
            hideValidation: true,
            validation: {
                required: true,
                requiredMessage: translations.requiredSubject,
            },
        },
        {
            id: 'description',
            salesforceId: 'description',
            type: 'textarea',
            hideValidation: true,
            validation: {
                required: true,
                requiredMessage: translations.requiredDescription,
            },
        },
        {
            id: 'firstName',
            salesforceId: '00N0X00000AkIVn',
            type: 'text',
            hideValidation: true,
            validation: {
                required: true,
                requiredMessage: translations.requiredFirstName,
            },
        },
        {
            id: 'lastName',
            salesforceId: '00N0X00000AkIVo',
            type: 'text',
            hideValidation: true,
            validation: {
                required: true,
                requiredMessage: translations.requiredLastName,
            },
        },
        {
            id: 'email',
            salesforceId: 'email',
            type: 'text',
            inputMode: 'email',
            hideValidation: true,
            validation: {
                required: true,
                requiredMessage: translations.requiredEmail,
                email: true,
                emailMessage: translations.validEmail,
            },
        },
        {
            id: 'phone',
            salesforceId: 'phone',
            type: 'text',
            inputMode: 'tel',
            autoComplete: 'tel',
            hideValidation: true,
        },
        ...(language === 'en' && country !== 'GB'
            ? ([
                  {
                      id: 'houseNumber',
                      salesforceId: '00N0X00000ARcIE',
                      type: 'text',
                      hideValidation: true,
                  },
                  {
                      id: 'street',
                      salesforceId: '00N0X00000ARcI9',
                      type: 'text',
                      hideValidation: true,
                  },
                  {
                      id: 'city',
                      salesforceId: '00N0X00000ARcIO',
                      type: 'text',
                      hideValidation: true,
                  },
                  {
                      id: 'postalCode',
                      salesforceId: '00N0X00000ARcIJ',
                      type: 'text',
                      hideValidation: true,
                  },
              ] as CaseFormField[])
            : country !== 'GB'
              ? ([
                    {
                        id: 'street',
                        salesforceId: '00N0X00000ARcI9',
                        type: 'text',
                        hideValidation: true,
                    },
                    {
                        id: 'houseNumber',
                        salesforceId: '00N0X00000ARcIE',
                        type: 'text',
                        hideValidation: true,
                    },
                    {
                        id: 'postalCode',
                        salesforceId: '00N0X00000ARcIJ',
                        type: 'text',
                        hideValidation: true,
                    },
                    {
                        id: 'city',
                        salesforceId: '00N0X00000ARcIO',
                        type: 'text',
                        hideValidation: true,
                    },
                ] as CaseFormField[])
              : []),
        {
            id: 'country',
            salesforceId: '00N0X00000AjXxu',
            type: 'dropdown',
            options: countryOptions,
            defaultValue: getCountryInfo(country)?.name,
            validation: {
                required: true,
                requiredMessage: translations.validCountry,
            },
        },
    ],
});

export default getRaleighCaseFormConfig;
