import { ShopwareCurrency } from '../types/shopwareCurrency';
import { LineItem } from '../types/shopwareCart';
import { ShopwareOrder } from '../types/shopwareOrder';
import { SHA256, enc } from 'crypto-js';
import { FACEBOOK_CONVERSION_API_ACCESS_TOKEN, FACEBOOK_PIXEL_ID } from '../constants/tokens';
import { LocatorFormValues } from '../components/page/StoreLocator/LocatorForm';
import IProduct from '../types/product';
import { FormValues } from '../types/Checkout';
import isBrowser from './isBrowser';

interface EventProps {
    checkoutFormValues?: FormValues;
    country?: string;
    currency?: ShopwareCurrency;
    email?: string;
    firstName?: string;
    lastName?: string;
    lineItems?: LineItem[];
    order?: ShopwareOrder;
    phoneNumber?: string;
    productCategory?: string;
    product?: IProduct;
    storeLocator?: LocatorFormValues;
}

export const events = {
    ADD_PAYMENT_INFO: 'AddPaymentInfo',
    ADD_TO_CART: 'Addtocart',
    CONTACT: 'Contact',
    FIND_LOCATION: 'FindLocation',
    INITIATE_CHECKOUT: 'Initiatecheckout',
    PAGE_VIEW: 'PageView',
    PURCHASE: 'Purchase',
    SEARCH: 'Search',
    SUBSCRIBE: 'Subscribe',
    VIEW_CONTENT: 'ViewContent',
};

const sendFacebookConversionEvent = async (eventName: string, props?: EventProps) => {
    if (!FACEBOOK_PIXEL_ID || !FACEBOOK_CONVERSION_API_ACCESS_TOKEN) return;

    const {
        checkoutFormValues,
        country,
        currency,
        email,
        firstName,
        lastName,
        lineItems,
        order,
        phoneNumber,
        productCategory,
        product,
        storeLocator,
    } = props ?? {};

    // TODO still needs to be decided if user IP can be fetched without consent
    const getIp = async () => {
        try {
            const response = await fetch('https://geolocation-db.com/json/');
            const data = await response.json();
            return data.IPv4;
        } catch (error) {
            return undefined;
        }
    };

    const lineItemsPrice = (allLineItems: LineItem[]) => {
        let price = 0;
        allLineItems.forEach(x => {
            price += x.price.totalPrice;
        });

        return price;
    };

    const lineItemsQuantity = (allLineItems: LineItem[]) => {
        let q = 0;
        allLineItems.forEach(x => {
            q += x.quantity;
        });

        return q;
    };

    // Email - Trim any leading and trailing spaces. Convert all characters to lowercase.
    let em: string | string[] | undefined =
        email ?? order?.orderCustomer.email ?? checkoutFormValues?.email;
    em = em && [SHA256(em).toString(enc.Hex)];
    // Phone number - Remove symbols, letters, and any leading zeros. Phone numbers must include a country code to be used for matching (e.g., the number 1 must precede a phone number in the United States). Always include the country code as part of your customers' phone numbers, even if all of your data is from the same country.
    let ph: string | string[] | undefined =
        order?.customFields?.accell_order_phone_number ?? phoneNumber;
    ph = ph && [SHA256(ph).toString(enc.Hex)];
    // First name - Using Roman alphabet a-z characters is recommended. Lowercase only with no punctuation. If using special characters, the text must be encoded in UTF-8 format.
    const fn = order?.orderCustomer.firstName ?? checkoutFormValues?.firstName ?? firstName;
    fn?.toLocaleLowerCase();
    // Last name - Using Roman alphabet a-z characters is recommended. Lowercase only with no punctuation. If using special characters, the text must be encoded in UTF-8 format.
    const ln = order?.orderCustomer.lastName ?? checkoutFormValues?.lastName ?? lastName;
    ln?.toLocaleLowerCase();
    // City - Using Roman alphabet a-z characters is recommended. Lowercase only with no punctuation, no special characters, and no spaces. If using special characters, the text must be encoded in UTF-8 format.
    const ct = order?.addresses[0].city ?? checkoutFormValues?.billingAddress?.city;
    ct?.toLocaleLowerCase().trim();
    // Use the lowercase, 2-letter country codes in ISO 3166-1 alpha-2. E.g. NL, BE, LU, DE, FR
    const c = order?.addresses[0].country.iso ?? country;
    c?.toLocaleLowerCase();
    // ZIP code - Use lowercase with no spaces and no dash. Use only the first 5 digits for U.S. zip codes. Use the area, district, and sector format for the UK.
    const zp = order?.addresses[0].zipcode ?? checkoutFormValues?.billingAddress?.zipcode;
    zp?.toLocaleLowerCase().trim();

    const request = {
        data: [
            {
                event_name: eventName,
                event_time: order
                    ? Math.round(new Date(order.createdAt).getTime() / 1000)
                    : Math.round(Date.now() / 1000),
                event_id: `eventName.${Math.round(Date.now() / 1000)}`,
                event_source_url: isBrowser() ? window.location.href : '',
                action_source: 'website',
                user_data: {
                    client_ip_address: await getIp(),
                    client_user_agent: navigator.userAgent,
                    em,
                    ph,
                    fn,
                    ln,
                    ct,
                    country: c,
                    zp,
                },
                custom_data: {
                    value: order
                        ? order.price.totalPrice
                        : product
                          ? product.price?.amount
                          : lineItems
                            ? lineItemsPrice(lineItems)
                            : undefined,
                    currency: currency?.iso,
                    content_ids: lineItems
                        ? lineItems.map(x => x.id)
                        : product
                          ? product.objectID
                          : undefined,
                    content_name: product?.name,
                    content_type: (!!order || !!product) && 'product',
                    search_string: storeLocator?.locationQuery,
                    content_category: productCategory,
                    num_items: product ? 1 : lineItems ? lineItemsQuantity(lineItems) : undefined,
                },
                opt_out: false,
            },
        ],
    };

    fetch(
        `https://graph.facebook.com/v12.0/${FACEBOOK_PIXEL_ID}/events?access_token=${FACEBOOK_CONVERSION_API_ACCESS_TOKEN}`,
        {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(request),
        }
    ).catch(() => {
        // no-op
    });
};

export default sendFacebookConversionEvent;
