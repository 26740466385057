export type ValueOf<T> = T[keyof T];

// Page Types
export type PageType = {
    home: string;
    cart: string;
    plp: string;
    pdp: string;
    contact: string;
    blogArticle: string;
    content: string;
    checkout: string;
    other: string;
    splashPage: string;
    dealer: string;
    bikeComparison: string;
    contactMethods: string;
    shippingMethods: string;
    paymentMethods: string;
};

export enum GTMEvents {
    addPaymentInfo = 'add_payment_info',
    addShippingInfo = 'add_shipping_info',
    addToCart = 'add_to_cart',
    beginCheckout = 'begin_checkout',
    checkoutError = 'CheckoutError',
    checkoutOption = 'checkoutOption',
    comparison = 'comparison',
    continueShopping = 'continueShopping',
    continueToBasket = 'continueToBasket',
    dealerClick = 'dealerClick',
    dealerEmail = 'dealerEmail',
    dealerFilter = 'dealerFilter',
    dealerInfoClick = 'dealerInfoClick',
    dealerNavigate = 'dealerNavigate',
    dealerPhone = 'dealerPhone',
    dealerSearch = 'dealerSearch',
    dealerVisitSite = 'dealerVisitSite',
    detailView = 'view_item',
    ecomPurchase = 'purchase',
    filter = 'filter',
    hitsView = 'hitsView',
    newsletterSubscription = 'newsletterSubscription',
    newsletterSubscriptionView = 'newsletter_subscription_view',
    notifyMe = 'notifyMe',
    notifyMeCompleted = 'notifyMeCompleted',
    pdpDealerSearch = 'pdpDealerSearch',
    productClick = 'select_item',
    quantityControl = 'quantityControl',
    removeFromCart = 'remove_from_cart',
    send = 'send',
    variantSelection = 'variantSelection',
    viewCart = 'view_cart',
}

export const PAGE_TYPE = {
    home: 'home',
    cart: 'cart',
    plp: 'product_listing_page',
    pdp: 'detail',
    contact: 'contact',
    blogArticle: 'blog',
    content: 'content',
    checkout: 'checkout',
    other: 'other',
    splashPage: 'splash',
    dealer: 'dealer',
    bikeComparison: 'bikeComparison',
    contactMethods: 'contactMethods',
    shippingMethods: 'shippingMethods',
    paymentMethods: 'paymentMethods',
    iFrame: 'iframe',
};
