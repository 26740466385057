import { Countries, Languages, StoryblokAdditionalLanguages } from '../types/Countries';

export const countries: Countries = [
    'FR', // Default country
    'IT',
    'IE',
    'GB',
    'ES',
    'DE',
    'NL',
    'BE',
    'LU',
    'CH',
    'INT',
];

export const languages: Languages = {
    FR: [
        'en', // Default country/language
        'fr',
    ],
    IT: ['en', 'it'],
    IE: ['en'],
    GB: ['en'],
    ES: ['es', 'en'],
    DE: ['en', 'de'],
    NL: ['en'],
    BE: ['en', 'fr'],
    LU: ['en', 'fr'],
    CH: ['fr', 'de'],
    INT: ['en', 'es'],
};

// Override the above country/language codes with specific codes for Storyblok
export const storyblokAdditionalLanguages: StoryblokAdditionalLanguages = {};
