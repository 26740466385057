import { FeatureType, StoryblokFeature, StoryblokFeatureState } from '../../types/feature';
import { currentEnvToFeatureStateEnv } from './currentEnvToFeatureStateEnv';

export const getFeatureState = (
    allFeatures: StoryblokFeature[],
    featureType: FeatureType
): StoryblokFeatureState => {
    const env = process.env.GATSBY_ENV ?? process.env.NODE_ENV;

    const currentFeatures = allFeatures.filter(item => item.feature === featureType);

    if (!currentFeatures.length) {
        return {
            enabled: false,
            content: {},
        };
    }

    // Find the first matching feature that includes the current env. in the state
    const matchedFeatureForEnv = currentFeatures.find((currentFeature: StoryblokFeature) => {
        const currentState = currentFeature?.state ?? undefined;
        if (!currentState) {
            return false;
        }
        return !!currentState.includes(currentEnvToFeatureStateEnv(env));
    });

    if (!matchedFeatureForEnv) {
        return {
            enabled: false,
            content: {},
        };
    }

    return {
        enabled: true,
        content: matchedFeatureForEnv?.configuration?.[0] ?? {},
    };
};

export default getFeatureState;
