import IProduct from '../types/product';
import { AttributeOption } from '../types/productVariant';
import { Brand } from './tokens';

export const IS_NODE_RUNTIME =
    process.env.GATSBY_NODE_RUNTIME === 'true' || process.env.GATSBY_DEVSERVER_FORCE_SSR === 'true';
export const IS_NODE_RUNTIME_DOCKER =
    process.env.GATSBY_NODE_RUNTIME === 'true' && process.env.GATSBY_NODE_RUNTIME_DOCKER === 'true';

export const PRODUCT_LISTING_PAGE_URL_SEGMENTATION_SEPARATOR_DEFAULT = 'l';
export const PRODUCT_DETAIL_PAGE_PATH_PARENT_DEFAULT: {
    productKey: keyof IProduct;
    shouldHash?: boolean;
}[] = [{ productKey: 'name' }];
export const PRODUCT_DETAIL_PAGE_PATH_CHILD_DEFAULT:
    | { variantAttributeOption: AttributeOption }
    | undefined = undefined;
export const PRODUCT_DETAIL_PAGE_URL_SEGMENTATION_SEPARATOR_DEFAULT = 'p';
export const PRODUCT_LINE_DETAIL_PAGE_URL_SEGMENTATION_SEPARATOR_DEFAULT = 'r';
export const DEFAULT_CURRENCY_CODE = 'EUR';
export const KLARNA_PAYMENT_METHOD_SHORT_NAME = 'klarna_payments_payment_handler';
export const KLARNA_START_PRICE = 60;
export const ONEY_PAYMENT_METHOD_SHORT_NAME = 'adyen_miep_oney_handler';
export const JOBRAD_PAYMENT_METHOD_SHORT_NAME = 'jobrad_handler';
export const APPLE_PAY_PAYMENT_METHOD_SHORT_NAME = 'adyen_miep_apple_pay_handler';
export const JOBRAD_PAYMENT_METHOD_MAX_CART_ITEMS = 1;
export const SHOPWARE_OUT_OF_STOCK = 'outOfStock';
export const JPEG_MAX_WIDTH_DEFAULT = 864;
export const JPEG_QUALITY = 80;
export const SHIPPING_COSTS = 1.99;
export const MINIMUM_PRICE = 20;
export const HOME_DELIVERY = 'homeDelivery';
export const CLICK_COLLECT = 'clickAndCollect';
export const LAST_VISITED_PLP_URL = 'lastVisitedPlp';
export const CHECKOUT_LOCALSTORAGE_KEY = 'orderDetails';
export const BIKE_COMPARE_MAX_BIKES = 3;
export const HEADER_MAX_USPS = 3;
export const DEFAULT_SIZE_SORTING = ['XS', 'S', 'M', 'L', 'XL', 'XXL'];
export const WINORA_FRAME_SIZE_ATTRIBUTE = 'HEIGHT';
export const HAIBIKE_FRAME_SIZE_ATTRIBUTE = 'HEIGHTS';
export const CYCLESCHEME_WIDGET_ENABLED_COUNTRIES = ['GB'];
export const CYCLESCHEME_WIDGET_URL = 'https://www.cyclescheme.co.uk/calculator-widget';
export const YOTPO_API_BASE_URL = 'https://api.yotpo.com';
export const YOTPO_WIDGET_BASE_URL = 'https://staticw2.yotpo.com';
export const BIDEX_BASE_API_URL =
    'https://api.bidex.bike/v1/Product_VariantsNoAuth/GetProductsNoAuth';
export const BIDEX_IFRAME_URL = 'https://2.local.bidex.bike/Shops';
export const BIDEX_AUTH_KEY = '1100 f7e6f685cf1613fa2b387e2b74ffa8ff21a5717c';
export const BIDEX_CLIENT_IDS: Record<
    Brand,
    {
        brandid?: string;
        bchID?: string;
    }
> = {
    winora: {
        brandid: '139',
        bchID: '11',
    },
    haibike: {
        brandid: '150',
        bchID: '10',
    },
    ghost: {},
    lapierre: {},
    xlc: {},
    sparta: {},
    raleigh: {},
};
