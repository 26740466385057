import omit from 'lodash/omit';
import designTokens, { DesignTokens } from './designTokens';
import { UITheme } from '../uiConfig';

export const { darkTheme } = designTokens;

const { lightTheme } = designTokens;

export type FullTheme = DesignTokens & {
    id: UITheme;
    colors: typeof lightTheme | typeof darkTheme;
};

export type Theme = Omit<DesignTokens, 'lightTheme' | 'darkTheme'> & {
    id: UITheme;
    colors: typeof lightTheme | typeof darkTheme;
};

export const defaultTheme: Theme = {
    ...omit(designTokens, ['lightTheme', 'darkTheme']),
    id: UITheme.Light,
    colors: {
        ...lightTheme,
    },
};

export const themeLight: Theme = {
    ...omit(designTokens, ['lightTheme', 'darkTheme']),
    id: UITheme.Light,
    colors: {
        ...lightTheme,
    },
};

export const themeDark: Theme = {
    ...omit(designTokens, ['lightTheme', 'darkTheme']),
    id: UITheme.Dark,
    colors: {
        ...darkTheme,
    },
};

// Just a utility to get the correct theme based on a UITheme value. You can also import a theme directly.
export const getTheme = (theme?: UITheme): Theme =>
    theme === UITheme.Dark ? themeDark : themeLight;
