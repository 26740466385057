import { Countries, Languages, StoryblokAdditionalLanguages } from '../types/Countries';

export const countries: Countries = [
    'GB', // Default country
    'IE',
];

export const languages: Languages = {
    GB: [
        'en', // Default country/language
    ],
    IE: ['en'],
};

// Override the above country/language codes with specific codes for Storyblok
export const storyblokAdditionalLanguages: StoryblokAdditionalLanguages = {};
