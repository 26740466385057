import React, { useRef, useMemo, useEffect, useState } from 'react';
import styled from 'styled-components';
import { OptionType, UDropdownField } from './ui/UDropdown';
import UButton from './ui/UButton';
import { TextInputField } from './ui/UInputTxt';
import { Formik } from 'formik';
import useTranslations from '../hooks/useTranslations';
import { object, string, bool, StringSchema, BooleanSchema } from 'yup';
import { homeRoute } from '../getRoute';
import useLocale from '../hooks/useLocale';
import ReCAPTCHA from 'react-google-recaptcha';
import FieldError from './FieldError';
import { BASE_URL, RECAPTCHA_SITE_KEY, brand } from '../constants/tokens';
import { getLangNameFromCode } from 'language-name-map';
import getLapierreCaseFormConfig from '../constants/caseForm.lapierre';
import getSpartaCaseFormConfig from '../constants/caseForm.sparta';
import getXlcCaseFormConfig from '../constants/caseForm.xlc';
import { CaseFormField } from '../constants/caseForm';
import UMessage from './ui/UMessage';
import getRaleighCaseFormConfig from '../constants/caseForm.raleigh';
import { sendSalesforceSubmitEvent } from '../services/analyticsEvents';
import getGhostCaseFormConfig from '../constants/caseForm.ghost';
import getWinoraCaseFormConfig from '../constants/caseForm.winora';
import { CountryKeys, uiSiteConfig } from '../constants/uiConfig';
import useCookieConsentStatus from '../hooks/useCookieConsentStatus';
import sendFacebookConversionEvent, { events } from '../services/facebookConversionEvents';
import isBrowser from '../services/isBrowser';
import getHaibikeCaseFormConfig from '../constants/caseForm.haibike';

interface FieldComponentProps {
    placeholder?: string;
    type?: string;
    hideValidation?: boolean;
    name: string;
    id: string;
    options?: OptionType[];
    defaultValue?: string;
}

const fieldIdMap: Record<string, string> = {
    brand: '00N0X00000AjXxr',
    webSource: '00N0X00000AjXy9',
    language: '00N0X00000AjXy0',
};

const caseFormGetters: Record<string, Function> = {
    lapierre: getLapierreCaseFormConfig,
    raleigh: getRaleighCaseFormConfig,
    sparta: getSpartaCaseFormConfig,
    xlc: getXlcCaseFormConfig,
    ghost: getGhostCaseFormConfig,
    winora: getWinoraCaseFormConfig,
    haibike: getHaibikeCaseFormConfig,
};
const getCaseFormConfig = caseFormGetters[brand];

const SalesforceForm = () => {
    const { country, language } = useLocale();
    const salesFormTranslations = useTranslations('salesforceForm');
    const messagesTranslations = useTranslations('messages');
    const caseFormConfig = getCaseFormConfig({
        country,
        translations: salesFormTranslations,
        language,
    });
    const formRef = useRef<HTMLFormElement>(null);
    const [timingError, setTimingError] = useState(false);

    const [captchaSettings, setCaptchaSettings] = useState(
        `{"keyname":"${caseFormConfig.captchaKey}","fallback":"true","orgId":"00D0X000000Oixf","ts":""}`
    );

    const schemaObj = caseFormConfig.fields.reduce(
        (prevSchemaObj: { [key: string]: StringSchema | BooleanSchema }, field: CaseFormField) => {
            if (field.validation) {
                let fieldSchema = string();

                if (field.validation.required) {
                    fieldSchema = fieldSchema.required(field.validation.requiredMessage);
                }

                if (field.validation.email) {
                    fieldSchema = fieldSchema.email(field.validation.emailMessage);
                }

                return { ...prevSchemaObj, [field.salesforceId]: fieldSchema };
            }

            return prevSchemaObj;
        },
        {
            recaptcha: bool()
                .required(salesFormTranslations.validRecaptcha)
                .oneOf([true], salesFormTranslations.validRecaptcha),
        }
    );
    const validationSchema = useMemo(
        () => object(schemaObj),
        // translations won't change during runtime
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );
    const getCaptchaSettings = () =>
        `{"keyname":"${
            caseFormConfig.captchaKey
        }","fallback":"true","orgId":"00D0X000000Oixf","ts":"${new Date().getTime()}"}`;

    const [formStatus, setFormStatus] = useState<string | null>(null);

    useEffect(() => {
        const status = localStorage.getItem('formStatus');
        setFormStatus(status);
        const offset = localStorage.getItem('formPageOffset');

        if (!isBrowser()) return;

        if (offset) {
            window.scrollTo({ top: parseInt(offset, 10) });
        }

        // Clear storage
        localStorage.removeItem('formStatus');
        localStorage.removeItem('formPageOffset');
    }, []);

    // We only load the form (because of recaptcha) when cookie consent given
    const oneTrustKeysToValidate =
        uiSiteConfig.CookieSettings?.recaptcha?.oneTrustKeysToValidate[
            country.toLowerCase() as CountryKeys
        ] ?? uiSiteConfig.CookieSettings?.recaptcha?.oneTrustKeysToValidate.default;
    const hasDefaultCookieConsent = useCookieConsentStatus(oneTrustKeysToValidate);
    if (!hasDefaultCookieConsent) {
        return (
            <UMessage
                variant="fail"
                dataLayer={{
                    dataEvent: 'error_message_view',
                    type: 'salesforce_form_error',
                    description: `Salesforce Form Fields: missing cookie consent.`,
                }}
            >
                {messagesTranslations.missingCookieConsent}
            </UMessage>
        );
    }

    if (!RECAPTCHA_SITE_KEY) {
        return null;
    }

    const initialValues = caseFormConfig.fields.reduce(
        (prevValues: { [key: string]: string }, field: CaseFormField) => ({
            ...prevValues,
            [field.salesforceId]: field.defaultValue ?? '',
        }),
        {
            recaptcha: false,
            captcha_settings: getCaptchaSettings(),
        }
    );

    const fieldComponents: { [key: string]: React.FC } = {
        text: StyledTextInputField,
        textarea: StyledTextInputField,
        dropdown: UDropdownField,
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleSubmit = async (values: any) => {
        if (!isBrowser()) return;

        const lastSubmitTime = localStorage.getItem('formSubmitTime');
        const timeDiff = new Date().getTime() - (lastSubmitTime ? parseInt(lastSubmitTime, 10) : 0);
        const timeDiffLimit = 5 * 60 * 1000; // 5 minutes
        if (lastSubmitTime && timeDiff < timeDiffLimit) {
            setTimingError(true);
            return;
        }

        localStorage.setItem('formStatus', 'succes');
        localStorage.setItem('formPageOffset', window.pageYOffset.toString());
        localStorage.setItem('formSubmitTime', new Date().getTime().toString());
        await sendFacebookConversionEvent(events.CONTACT, {
            email: values.email,
            phoneNumber: values.phone,
            firstName: values['00N0X00000AkIVn'],
            lastName: values['00N0X00000AkIVo'],
        });
        formRef?.current?.submit();
    };

    return (
        <StyledFormContainer>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {props => {
                    const onRecaptchaChange = (token: string) => {
                        if (token) {
                            const settings = getCaptchaSettings();
                            setCaptchaSettings(settings);
                            props.setFieldValue('recaptcha', true);
                            props.setFieldValue('captcha_settings', settings);
                        }
                    };
                    const onRecaptchaError = () => props.setFieldValue('recaptcha', false);

                    // ! Due to Salesforce limitations the post is being handled like this. Source: https://www.mhamzas.com/blog/2019/11/05/salesforce-web-to-lead-and-web-to-case-using-javascript-cors-proof/
                    return (
                        <form
                            action="https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8"
                            method="POST"
                            ref={formRef}
                            onSubmit={props.handleSubmit}
                        >
                            <input type="hidden" name="captcha_settings" value={captchaSettings} />
                            <input type="hidden" name="orgid" value="00D0X000000Oixf" />
                            <input
                                type="hidden"
                                name={fieldIdMap.brand}
                                id={fieldIdMap.brand}
                                value={caseFormConfig.brand}
                            />
                            <input
                                type="hidden"
                                name={fieldIdMap.language}
                                id={fieldIdMap.language}
                                value={getLangNameFromCode(language).name}
                            />
                            <input
                                type="hidden"
                                name={fieldIdMap.webSource}
                                id={fieldIdMap.webSource}
                                value={caseFormConfig.webSource}
                            />
                            <input
                                type="hidden"
                                name="retURL"
                                value={
                                    isBrowser()
                                        ? BASE_URL + window.location.pathname
                                        : BASE_URL + homeRoute(country, language)
                                }
                            />
                            {caseFormConfig.fields.map((field: CaseFormField, index: number) => {
                                if (field.type === 'hidden') {
                                    return (
                                        <input
                                            key={index}
                                            type="hidden"
                                            id={field.salesforceId}
                                            name={field.salesforceId}
                                            value={field.defaultValue ?? undefined}
                                        />
                                    );
                                }
                                const FieldComponent: React.FC<FieldComponentProps> =
                                    fieldComponents[field.type];

                                return (
                                    <FieldComponent
                                        key={field.id}
                                        placeholder={salesFormTranslations[field.id]}
                                        type={field.type !== 'dropdown' ? field.type : undefined}
                                        hideValidation={field.hideValidation ?? false}
                                        name={field.salesforceId}
                                        id={field.salesforceId}
                                        options={field.options ?? undefined}
                                        defaultValue={field.defaultValue ?? undefined}
                                    />
                                );
                            })}
                            <ReCAPTCHA
                                sitekey={RECAPTCHA_SITE_KEY}
                                onChange={onRecaptchaChange}
                                onErrored={onRecaptchaError}
                                onExpired={onRecaptchaError}
                            />
                            <FieldError name="recaptcha" />
                            <StyledUButton onClick={() => sendSalesforceSubmitEvent}>
                                {salesFormTranslations.submit}
                            </StyledUButton>
                            {formStatus && (
                                <UMessage variant="success">
                                    {salesFormTranslations.submitFeedbackSuccess}
                                </UMessage>
                            )}
                            {timingError && (
                                <UMessage variant="warning">
                                    {salesFormTranslations.submitFeedbackTimeout}
                                </UMessage>
                            )}
                        </form>
                    );
                }}
            </Formik>
        </StyledFormContainer>
    );
};

const StyledUButton = styled(UButton)`
    margin-top: ${({ theme }) => theme.sizeGridSpacingRem8};
`;

const StyledFormContainer = styled.div`
    max-width: 60rem;
`;

const StyledTextInputField = styled(TextInputField)`
    margin-bottom: ${({ theme }) => theme.sizeGridSpacingRem8};
`;

export default SalesforceForm;
