/* eslint-disable import/no-dynamic-require */
import { brand } from '../tokens';
import globalTokens from './designTokens.global';
import brandTokensRaleigh from './designTokens.raleigh'; // direct imported needed for TypeScript type inference :(

// Needed for Storybook
const brandKey = brand ?? 'raleigh';

const brandTokens = brandKey ? require(`./designTokens.${brandKey}.ts`).default : {}; // storybook does not include the brand theme here, but in the storybook config

export type GlobalTheme = typeof globalTokens;
export type BrandTheme = typeof brandTokensRaleigh;
export type DesignTokens = GlobalTheme & BrandTheme;

const designTokens: DesignTokens = { ...globalTokens, ...brandTokens };

export default designTokens;
