import { storyblokInit, apiPlugin } from 'gatsby-source-storyblok';

import * as tokens from '../../constants/tokens';
import { STORYBLOK_API_OPTIONS } from '../../constants/storyblok';

const env = process.env.GATSBY_ENV ?? process.env.NODE_ENV;

const initStoryblokClient = () => {
    storyblokInit({
        accessToken: tokens.STORYBLOK_TOKEN,
        bridge: env !== 'prod',
        apiOptions: STORYBLOK_API_OPTIONS,
        use: [apiPlugin],
        components: {
            // Dynamic components!
        },
    });
};

export default initStoryblokClient;
