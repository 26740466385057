import React, { FC, HTMLAttributes } from 'react';
import styled from 'styled-components';

interface ContainerProps extends HTMLAttributes<HTMLElement> {
    // eslint-disable-next-line
    forwardAs?: keyof JSX.IntrinsicElements | React.ComponentType<any>;
    fluid?: boolean;
    // Don't contain the left or right side--let it run to the edge of the screen. These will be
    // applied from breakpoint L and wider.
    breakLeft?: boolean;
    breakRight?: boolean;
}

const UContainer: FC<ContainerProps> = ({
    forwardAs,
    fluid,
    children,
    breakLeft,
    breakRight,
    ...rest
}) => (
    <StyledContainer
        as={forwardAs}
        fluid={fluid}
        $breakLeft={breakLeft}
        $breakRight={breakRight}
        {...rest}
    >
        {children}
    </StyledContainer>
);

export default UContainer;

const StyledContainer = styled.div<
    Omit<ContainerProps, 'breakLeft' | 'breakRight'> & {
        $breakLeft?: boolean;
        $breakRight?: boolean;
    }
>`
    width: calc(100% / (var(--gutter-width) * var(--font-size)));
    padding-left: var(--gutter-width);
    padding-right: var(--gutter-width);

    @media screen and (min-width: ${({ theme }) => `${theme.breakpointMedium}px`}) {
        ${props => props.$breakLeft && `padding-left: 0;`};
        ${props => props.$breakRight && `padding-right: 0;`};
    }

    @media screen and (min-width: ${({ theme }) => `${theme.breakpointExtraLarge}px`}) {
        ${props =>
            !props.$breakLeft &&
            !props.$breakRight &&
            `margin: 0 auto;
            max-width: ${props.fluid ? '100%' : props.theme.sizeGridLayoutMaxWidthXxl};`}
        ${props =>
            props.$breakLeft &&
            `margin-right: calc(0.5 * (100vw - ${props.theme.sizeGridLayoutMaxWidthXxl}))`};
        ${props =>
            props.$breakRight &&
            `margin-left: calc(0.5 * (100vw - ${props.theme.sizeGridLayoutMaxWidthXxl}))`};
    }
`;
