/* eslint-disable @typescript-eslint/no-explicit-any, no-console */
import React from 'react';
import styled from 'styled-components';
import UIconError from '../../components/ui/icons/UIconError';
import UIconInfoBlue from '../../components/ui/icons/UIconInfoBlue';
import UIconSuccess from '../../components/ui/icons/UIconSuccess';
import UIconWarning from '../../components/ui/icons/UIconWarning';
import isBrowser from '../isBrowser';

type Environment = 'development' | 'test' | 'accept' | 'prod' | 'production';

export class Logger {
    private environment: Environment = 'prod';

    private logLevel: 'info' | 'success' | 'warn' | 'error' = 'info';

    private statementEnvironments: Array<string> = ['development'];

    private toastInstance: any = null;

    constructor(environment: Environment) {
        this.environment = environment;

        // Make sure to alwaye log when running locally (NODE_ENV === 'development')
        if (process.env.NODE_ENV === 'development') {
            this.statementEnvironments = ['development', 'test'];
        }

        // eslint-disable-next-line @typescript-eslint/no-shadow
        import('sonner').then(({ toast }) => {
            this.toastInstance = toast;
        });
    }

    public client(...args: any[]): void {
        if (this.shouldLog()) this.log(...args);
        if (this.shouldToast()) this.toast(...args);
        this.reset();
    }

    public server(...args: any[]): void {
        if (this.shouldLog()) this.log(...args);
        this.reset();
    }

    private shouldLog(): boolean {
        return this.statementEnvironments.includes(this.environment);
    }

    private shouldToast(): boolean {
        return (
            isBrowser() &&
            this.environment !== 'prod' &&
            this.environment !== 'production' &&
            this.statementEnvironments.includes(this.environment)
        );
    }

    private reset(): void {
        // Make sure to alwaye log when running locally (NODE_ENV === 'development')
        if (process.env.NODE_ENV === 'development') {
            this.statementEnvironments = ['development', 'test'];
        } else {
            this.statementEnvironments = ['development'];
        }

        this.logLevel = 'info';
    }

    // eslint-disable-next-line class-methods-use-this
    private async toast(...args: any[]): Promise<void> {
        const fullMessageClipboard = Array.isArray(args)
            ? args
                  .map(arg =>
                      typeof arg === 'string'
                          ? arg
                          : JSON.stringify(arg, Object.getOwnPropertyNames(arg), 2)
                  )
                  .join(' , ')
            : args;
        const copyToClipboard = () => {
            navigator?.clipboard?.writeText(fullMessageClipboard);
        };

        const initialMessageString =
            Array.isArray(args) && !!args[0] && typeof args[0] === 'string' ? args[0] : undefined;
        const restArgs: any[] = Array.isArray(args)
            ? initialMessageString
                ? args.slice(1)
                : args
            : [];

        let title = initialMessageString;
        if (this.logLevel === 'success') title = initialMessageString ?? 'Success';
        if (this.logLevel === 'info') title = initialMessageString ?? 'Info';
        if (this.logLevel === 'warn') title = initialMessageString ?? 'Warning';
        if (this.logLevel === 'error') title = initialMessageString ?? 'Error';

        const restArgsStrings: string[] = restArgs.filter((arg: any) => typeof arg === 'string');
        const description =
            // We only set the default description if the args contain only strings, so same length as main args
            args.length === restArgsStrings.length ? restArgsStrings.join(' , ') : undefined;
        const data = {
            description,
            action: {
                label: 'Copy to Clipboard',
                onClick: copyToClipboard,
            },
        };

        // We render custom template, if:
        // a - No string description because there is code content in args
        // b - String description is fairly large
        let customContent;
        if (!description || description.length > 250) {
            let Icon = UIconInfoBlue;
            if (this.logLevel === 'success') Icon = UIconSuccess;
            if (this.logLevel === 'info') Icon = UIconInfoBlue;
            if (this.logLevel === 'warn') Icon = UIconWarning;
            if (this.logLevel === 'error') Icon = UIconError;

            customContent = (
                <StyledWrapper $variant={this.logLevel}>
                    <StyledIcon>
                        <Icon width={24} height={24} />
                    </StyledIcon>
                    <StyledMessage>
                        <div>
                            <b>{initialMessageString}</b>
                        </div>
                        {restArgs.map((arg: any, index: number) =>
                            typeof arg === 'string' ? (
                                <div key={index}>{arg}</div>
                            ) : (
                                <pre key={index}>
                                    {JSON.stringify(arg, Object.getOwnPropertyNames(arg), 2)}
                                </pre>
                            )
                        )}
                    </StyledMessage>
                    <StyledButton onClick={copyToClipboard} type="button">
                        Copy to Clipboard
                    </StyledButton>
                </StyledWrapper>
            );
        }

        if (this.logLevel === 'success') this.toastInstance.success(customContent ?? title, data);
        if (this.logLevel === 'info') this.toastInstance.info(customContent ?? title, data);
        if (this.logLevel === 'warn') this.toastInstance.warning(customContent ?? title, data);
        if (this.logLevel === 'error') this.toastInstance.error(customContent ?? title, data);
    }

    private log(...args: any[]): void {
        if (['info', 'success'].includes(this.logLevel)) console.log(...args);
        if (this.logLevel === 'warn') console.warn(...args);
        if (this.logLevel === 'error') console.error(...args);
    }

    public test(): Logger {
        if (this.statementEnvironments.indexOf('test') === -1) {
            this.statementEnvironments.push('test');
        }
        return this;
    }

    public accept(): Logger {
        if (this.statementEnvironments.indexOf('accept') === -1) {
            this.statementEnvironments.push('accept');
        }
        return this;
    }

    public prod(): Logger {
        if (this.statementEnvironments.indexOf('prod') === -1) {
            this.statementEnvironments.push('prod');
        }
        if (this.statementEnvironments.indexOf('production') === -1) {
            this.statementEnvironments.push('production');
        }
        return this;
    }

    public production(): Logger {
        if (this.statementEnvironments.indexOf('prod') === -1) {
            this.statementEnvironments.push('prod');
        }
        if (this.statementEnvironments.indexOf('production') === -1) {
            this.statementEnvironments.push('production');
        }
        return this;
    }

    public all(): Logger {
        this.test().accept().production();
        return this;
    }

    public allNotProd(): Logger {
        this.test().accept();
        return this;
    }

    public error(): Logger {
        this.logLevel = 'error';
        return this;
    }

    public warn(): Logger {
        this.logLevel = 'warn';
        return this;
    }

    public info(): Logger {
        this.logLevel = 'info';
        return this;
    }

    public success(): Logger {
        this.logLevel = 'success';
        return this;
    }
}

const StyledWrapper = styled.div<{ $variant?: 'info' | 'success' | 'warn' | 'error' }>`
    position: relative;
    max-width: calc(50vw - 34px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 6px;
    font-size: 13px;
`;

const StyledIcon = styled.div`
    height: 24px;
    width: 24px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

const StyledMessage = styled.div`
    flex-grow: 1;
    position: relative;
    max-height: 100px;
    overflow: auto;

    pre {
        position: relative;
        width: 100%;
        overflow: auto;
        padding: 6px;
        border-radius: 4px;
        box-shadow: inset 0 4px 12px #0000001a;
    }
`;

const StyledButton = styled.button`
    flex-shrink: 0;
    border-radius: 4px;
    padding-left: 8px;
    padding-right: 8px;
    height: 24px;
    font-size: 12px;
    color: #fff;
    background: hsl(0, 0%, 9%);
    border: none;
    cursor: pointer;
    outline: none;
    display: flex;
    align-items: center;
    margin-left: auto;
`;

/*
 * Usage examples:
 * Note: for local development no .development() is needed.
 * logger.client('This is a message with INFO context');
 * displays: This is a message with INFO context console.log on development
 *
 * logger.all().client()
 * displays: console.info on development, test, production and accept environments
 *
 * logger.error().test().production().accept().client('Log another thing');
 * displays: Log another thing in the console.error on test, production and accept environments
 *
 * logger.test().client('Log something', 'and something else');
 * displays: Log something and something else in the console.log on test environment
 *
 * logger.accept().test().warn().client('Log yet another thing');
 * displays: Log yet another thing in the console.warn on accept and test environment
 *
 *
 *
 */
export const logger = new Logger((process.env.GATSBY_ENV ?? process.env.NODE_ENV) as Environment);
