import { Field, FieldConfig, FieldProps } from 'formik';
import React, { InputHTMLAttributes } from 'react';
import styled from 'styled-components';
import UIconCaret from './icons/UIconCaret';
import FieldError from '../FieldError';

type InputProps = InputHTMLAttributes<HTMLSelectElement>;
export type OptionType = {
    label: string;
    value: string;
};
export interface DropdownFieldProps extends InputProps {
    placeholder: string;
    options: OptionType[];
    onSelectValue?: (value: string) => void;
}

const UDropdown = React.forwardRef<HTMLSelectElement, DropdownFieldProps>(
    ({ placeholder, value, options, onSelectValue, ...props }, ref) => (
        <Container $disabled={options.length === 1}>
            <Label>{placeholder}</Label>
            <Select
                ref={ref}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                    !!onSelectValue && onSelectValue(e.target.value)
                }
                {...props}
                disabled={options.length === 1}
                $disabled={options.length === 1}
                value={value}
            >
                {options.map((option: OptionType, i: number) => (
                    <option key={i} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </Select>
            {options.length > 1 && (
                <IconWrapper>
                    <DropdownIcon />
                </IconWrapper>
            )}
        </Container>
    )
);

export const UDropdownField = React.forwardRef<HTMLSelectElement, DropdownFieldProps & FieldConfig>(
    ({ name, ...props }, ref) => (
        <>
            <Field name={name} {...props}>
                {({ field }: FieldProps) => <UDropdown ref={ref} {...field} {...props} />}
            </Field>
            <FieldError name={name} />
        </>
    )
);

export default UDropdown;

const Label = styled.label`
    position: absolute;
    left: ${({ theme }) => `${theme.sizeGridSpacingRem16}`};
    top: ${({ theme }) => `${theme.sizeGridSpacingRem12}`};
    font-size: ${({ theme }) => `${theme.sizeTextBodyExtraSmall}`};
    color: ${({ theme }) => theme.colors.textCaption};
    line-height: 1;
`;
export const Select = styled.select<{ $disabled: boolean }>`
    display: flex;
    width: 100%;
    height: 100%;
    outline: none;
    border: none;
    font-size: ${({ theme }) => `${theme.sizeTextBodyMedium}`};
    line-height: 2.4rem;
    background: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-overflow: '';
    margin: 0;
    padding: ${({ theme }) =>
        `${theme.sizeGridSpacingRem24} ${theme.sizeGridSpacingRem16} ${theme.sizeGridSpacingRem8}`};
    ${({ theme }) => `
        font-family: ${theme.fontBodyFamily};
        font-weight: ${theme.fontBodyWeight};
        text-transform: ${theme.fontBodyTransform};
        border-radius: ${theme.sizeOtherBorderRadiusM}};
    `};
    color: ${({ theme }) => theme.colors.textBody};
    cursor: pointer;

    ${({ $disabled, theme }) =>
        $disabled &&
        `
        cursor: not-allowed;
        pointer-events: none;
        color: ${theme.colors.formInputInputTextDisabled};
        background: ${theme.colors.formInputBackgroundDisabled};
    `}
`;

export const Container = styled.div<{ $disabled: boolean }>`
    position: relative;
    flex-direction: row;
    flex: 1;
    display: flex;
    height: ${({ theme }) => `${theme.sizeGridSpacingRem56}`};
    border-radius: ${({ theme }) => `${theme.sizeOtherBorderRadiusM}`};
    margin-bottom: ${({ theme }) => `${theme.sizeGridSpacingRem8}`};
    border: ${({ theme }) => `${theme.sizeOtherBorderWidthS}`} solid
        ${({ theme }) => `${theme.colors.formInputBorder}`};
    background: ${({ theme }) => theme.colors.wrapperWell};
    transition: all 0.3s ${({ theme }) => theme.additionalMotionEaseInOut};
    -webkit-appearance: none;
    &:active,
    &:focus {
        border: ${({ theme }) => `${theme.sizeOtherBorderWidthS}`} solid
            ${({ theme }) => `${theme.colors.fallbackGrey400}`};
        transition: all 0.3s ${({ theme }) => theme.additionalMotionEaseInOut};
    }

    ${({ $disabled }) =>
        $disabled &&
        `
        cursor: not-allowed;
    `}
`;

const IconWrapper = styled.div`
    position: absolute;
    right: ${({ theme }) => `${theme.sizeGridSpacingRem24}`};
    bottom: ${({ theme }) => `${theme.sizeGridSpacingRem20}`};
    top: ${({ theme }) => `${theme.sizeGridSpacingRem16}`};
    height: ${({ theme }) => `${theme.sizeGridSpacingRem24}`};
    display: flex;
    align-items: center;
    pointer-events: none;
`;

export const DropdownIcon = styled(UIconCaret)`
    transform: rotate(-90deg);
    color: ${({ theme }) => theme.colors.iconSecondary};
`;
