import { StoryblokFeatureEnvState } from '../../types/feature';

export const currentEnvToFeatureStateEnv = (currentEnv?: string): StoryblokFeatureEnvState => {
    switch (currentEnv) {
        case 'test':
            return 'preview';
        case 'accept':
            return 'accept';
        case 'prod':
        default:
            return 'live';
    }
};

export default currentEnvToFeatureStateEnv;
