import { Field, FieldConfig, FieldProps, getIn, useFormikContext } from 'formik';
import React, { FocusEvent, InputHTMLAttributes, Ref, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import FieldError from '../FieldError';
import UIconCheckmark from './icons/UIconCheckmark';
import { Theme } from '../../constants/theming/theme';
import isBrowser from '../../services/isBrowser';

type InputProps = InputHTMLAttributes<HTMLInputElement>;

export interface TextInputFieldProps extends InputProps {
    error?: string;
    touched?: boolean;
    overwriteValidated?: boolean;
    hideValidation?: boolean;
    hideFloatLabel?: boolean;
    trimTrailingSpace?: boolean;
    isUsingFormik?: boolean;
    disabled?: boolean;
    // hideError?: boolean;
}

const UInputTxt = React.forwardRef<HTMLInputElement | HTMLTextAreaElement, TextInputFieldProps>(
    (
        {
            children,
            placeholder,
            error,
            className,
            onBlur,
            onChange,
            touched,
            value,
            overwriteValidated,
            hideValidation,
            hideFloatLabel,
            trimTrailingSpace,
            isUsingFormik = true,
            disabled = false,
            // hideError,
            ...props
        },
        ref
    ) => {
        const [isFocused, setisFocused] = useState(false);
        const isValid = typeof value === 'string' && value.length > 0;

        const { setFieldValue, setFieldTouched, setFieldError } = useFormikContext<string>() ?? {};

        const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
            if (onBlur) {
                onBlur(e);
            }
            const { name } = props;
            if (isUsingFormik && name && value && typeof value === 'string' && trimTrailingSpace) {
                setFieldValue(name, value.trim());
            }
            setisFocused(false);
        };

        useEffect(() => {
            if (isBrowser() && ((!!touched && !error && !hideValidation) || !!overwriteValidated)) {
                window.dataLayer.push({
                    event: 'success_message_view',
                    type: 'form_success',
                    description: `Form Fields: field success.`,
                    element: props.name,
                });
            }
        }, [touched, error, hideValidation, overwriteValidated, props.name]);

        useEffect(() => {
            if (!isFocused && props.name && value && isUsingFormik) {
                setFieldTouched(props.name, true);
                // If there was no validation error on autocomplete, clear the initial error for the field
                if (!error) {
                    setFieldError(props.name, '');
                }
            }
            // eslint-disable-next-line
        }, [props.name, touched, value, setFieldTouched]);

        return (
            <Container
                $focus={isFocused}
                error={error}
                className={className}
                disabled={disabled}
                touched={touched}
            >
                <Inner>
                    {!hideFloatLabel && (
                        <Label className={isFocused || isValid ? 'focus' : ''} disabled={disabled}>
                            {placeholder}
                        </Label>
                    )}
                    <Input
                        // $hideError={hideError}
                        className={isFocused ? 'focus' : ''}
                        onFocus={() => setisFocused(true)}
                        onBlur={handleBlur}
                        onChange={onChange}
                        ref={ref as Ref<HTMLInputElement>}
                        value={value}
                        placeholder={hideFloatLabel ? placeholder : ''}
                        $hideFloatLabel={hideFloatLabel}
                        $trimTrailingSpace={trimTrailingSpace}
                        as={props.type === 'textarea' ? 'textarea' : 'input'}
                        disabled={disabled}
                        {...props}
                    />
                    {(!!(touched && !error && !hideValidation) || !!overwriteValidated) && (
                        <StyledUIconCheckmark />
                    )}
                </Inner>
            </Container>
        );
    }
);

export const TextInputField = React.forwardRef<
    HTMLInputElement,
    TextInputFieldProps &
        FieldConfig & {
            hideError?: boolean;
            hideValidation?: boolean;
        }
>(({ name, hideError, ...props }, ref) => (
    <Field name={name} {...props}>
        {({ field, form: { errors, touched } }: FieldProps) => (
            <>
                <UInputTxt
                    ref={ref}
                    touched={getIn(touched, field.name)}
                    error={
                        getIn(touched, field.name) && getIn(errors, field.name)
                            ? getIn(errors, field.name)
                            : undefined
                    }
                    {...field}
                    {...props}
                />
                {!hideError && <FieldError name={field.name} />}
            </>
        )}
    </Field>
));

const Label = styled.label<{ disabled?: boolean }>`
    position: absolute;
    display: flex;
    font-size: ${({ theme }) => theme.sizeTextBodyMedium};
    line-height: 1;
    color: ${({ theme }) => theme.colors.formInputLabelText};
    align-items: center;
    left: ${({ theme }) => theme.sizeGridSpacingRem16};
    top: 2.1rem;
    pointer-events: none;
    margin-top: 0;
    transition: 200ms all;

    ${({ theme, disabled }) =>
        disabled &&
        `
        color: ${theme.colors.formInputInputTextDisabled};
    `}

    &.focus {
        transform: translateY(-1.2rem);
        font-size: ${({ theme }) => theme.sizeTextBodyExtraSmall};
        color: ${({ theme }) => theme.colors.formInputFocusLabelText};
    }
`;
export const Input = styled.input<{
    $trimTrailingSpace?: boolean;
    $hideFloatLabel?: boolean;
    // $hideError?: boolean;
}>`
    display: flex;
    width: 100%;
    outline: none;
    border: none;
    padding: ${({ $hideFloatLabel }) =>
        $hideFloatLabel ? '1.7rem 1.6rem 1.5rem' : '2.4rem 1.6rem 0.8rem'};
    margin: 0;
    line-height: 1.5;
    background: transparent;

    ${({ theme, disabled }) => css`
        font-size: ${theme.sizeTextBodyMedium};
        font-family: ${theme.fontBodyFamily};
        font-weight: ${theme.fontBodyWeight};
        text-transform: ${theme.fontBodyTransform};
        color: ${theme.colors.formInputInputText};

        ${disabled &&
        `
            pointer-events: none;
            color: ${theme.colors.formInputInputTextDisabled};
            background: ${theme.colors.formInputBackgroundDisabled};
        `}
    `};

    & > input {
        :-webkit-autofill,
        :-webkit-autofill-strong-password,
        :-webkit-autofill-strong-password-viewable {
            background: rgb(255, 255, 255);
            background-image: none;
            color: ${({ theme }) => theme.colors.formInputInputText};
        }
    }
`;
const containerBorderColor = ({
    error,
    $focus,
    touched,
    theme,
}: TextInputFieldProps & {
    $focus: boolean;
    theme: Theme;
}) => {
    if (error) {
        return theme.colors.formInputBorderError;
    }

    if (touched && !error) {
        return theme.colors.formStatusSuccessMain;
    }

    if ($focus) {
        return theme.colors.formInputBorderFocus;
    }

    return theme.colors.formInputBorderSecondary;
};
export const Container = styled.div<TextInputFieldProps & { $focus: boolean }>`
    position: relative;
    flex-direction: row;
    flex: 1;
    display: flex;
    border-radius: ${({ theme }) => theme.sizeOtherBorderRadiusM};
    border: ${({ theme }) => theme.sizeOtherBorderWidthS} solid ${containerBorderColor};
    background: ${({ theme }) => theme.colors.formInputBackgroundSecondary};
    -webkit-appearance: none;
    overflow: hidden;

    ${({ disabled }) =>
        disabled &&
        `
        cursor: not-allowed;
    `}
`;

const Inner = styled.div`
    position: relative;
    flex-direction: row;
    flex: 1;
    display: flex;

    textarea {
        resize: vertical;
    }
`;

const StyledUIconCheckmark = styled(UIconCheckmark)`
    color: ${({ theme }) => theme.colors.formInputCheckbox};
    margin: auto;
    width: ${({ theme }) => theme.sizeOtherIconXs};
    min-width: ${({ theme }) => theme.sizeOtherIconXs};
    height: ${({ theme }) => theme.sizeOtherIconXs};
    padding-right: ${({ theme }) => theme.sizeGridSpacingRem16};
`;

export default UInputTxt;
