import { getShopwareToken } from '../../../constants/tokens';
import { shopwareUrl } from '../../../endpointConfig';
import { ShopwareProduct } from '../../../types/shopwareProduct';
import { MINIMAL_PRODUCT_FIELDS } from '../fields.constants';
import { formatFieldsToBody } from '../fields.helpers';

const getShopwareProducts = async ({
    country,
    shopwareLanguageId,
    productIds,
    productRequestBodyOverride,
}: {
    country: string; // country is required to make sure its present in createPages, since no client country present
    shopwareLanguageId?: string; // shopwareLanguageId is required in order to fetch translated names
    productIds?: string[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    productRequestBodyOverride?: { [key: string]: any };
}): Promise<ShopwareProduct[]> => {
    const response = await fetch(`${shopwareUrl({ country })}/product`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'sw-access-key': getShopwareToken(country),
            ...(shopwareLanguageId ? { 'sw-language-id': shopwareLanguageId } : {}),
        },
        body: JSON.stringify({
            ...(productIds ? { ids: productIds } : {}),
            ...formatFieldsToBody(MINIMAL_PRODUCT_FIELDS),
            ...productRequestBodyOverride,
        }),
    });

    const result = await response.json();

    if (response.ok) {
        const shopwareProducts: ShopwareProduct[] = result?.elements ?? [];
        return shopwareProducts;
    }

    throw new Error(result.errors[0].detail);
};

export default getShopwareProducts;
