import React, { FC, SVGProps } from 'react';
import styled from 'styled-components';

type Props = SVGProps<SVGSVGElement>;

const UIconSuccess: FC<Props> = ({ ...rest }) => (
    <svg width={24} height={24} viewBox="0 0 24 24" {...rest}>
        <g fill="none" fillRule="evenodd">
            <Circle cx={12} cy={12} r={12} />
            <path
                fill="#fff"
                transform="translate(4 4)"
                d="M12.738 4.63a.889.889 0 00-1.257 0L6.229 9.893 4.533 8.197a.877.877 0 00-1.246.006.893.893 0 00.005 1.256l2.937 2.937 6.51-6.508a.89.89 0 000-1.259z"
            />
        </g>
    </svg>
);

const Circle = styled.circle`
    fill: ${({ theme }) => theme.colors.formStatusSuccessMain};
`;

export default UIconSuccess;
