import React, { ButtonHTMLAttributes } from 'react';
import styled, { css, useTheme } from 'styled-components';
import { Theme } from '../../constants/theming/theme';
import GatsbyLink from '../GatsbyLink';
import UIconLoader from './icons/UIconLoader';
import { ButtonTheme } from '../../services/setThemeColors';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
    disabled?: boolean;
    secondary?: boolean;
    secondaryBlack?: boolean;
    secondaryWhite?: boolean;
    tertiary?: boolean;
    loading?: boolean;
    outline?: boolean;
    href?: string;
    rel?: string;
    target?: string;
    to?: string;
    isSmall?: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    forwardAs?: keyof JSX.IntrinsicElements | React.ComponentType<any>; // renamed as to forwardAs because of passing a prop and a as will not work with styled components (probably a bug in Styled Components)
    buttonThemeColors?: ButtonTheme;
}

interface Colors {
    background?: string;
    backgroundHover?: string;
    text: string;
    textHover: string;
    border?: string;
    borderHover?: string;
}

const UButton: React.FunctionComponent<Props> = ({
    disabled,
    secondary,
    secondaryBlack,
    secondaryWhite,
    tertiary,
    outline,
    loading,
    isSmall,
    forwardAs,
    to,
    buttonThemeColors,
    ...props
}) => {
    const theme = useTheme();
    const colors = getColors(
        {
            disabled,
            secondary,
            tertiary,
            outline,
            loading,
            secondaryBlack,
            secondaryWhite,
            buttonThemeColors,
        },
        theme
    );
    const component = (
        <BtnPrimaryBgDefault
            /* eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing */
            $disabled={disabled ?? loading}
            $backgroundColor={colors.background}
            $backgroundHoverColor={colors.backgroundHover}
            $borderColor={colors.border}
            $borderHoverColor={colors.borderHover}
            $textHoverColor={colors.textHover}
            $outline={outline}
            $isSmall={isSmall}
            $themeColors={buttonThemeColors}
            as={to ? 'span' : forwardAs ?? 'button'}
            {...props}
        >
            {loading && <StyledUIconLoader $outline={outline} $isSmall={isSmall} />}
            <Text color={colors.text} $isLoading={loading} $isSmall={isSmall}>
                {props.children}
            </Text>
        </BtnPrimaryBgDefault>
    );
    return to && !disabled ? <StyledLink to={to}>{component}</StyledLink> : component;
};

const getColors = (props: Props, theme: Theme): Colors => {
    const result = (
        background: string | undefined,
        backgroundHover: string | undefined,
        text: string,
        textHover: string,
        border?: string,
        borderHover?: string
    ) => ({
        backgroundHover:
            props.disabled && !props.outline
                ? theme.colors.actionDisabledBackgroundHover
                : backgroundHover,
        background: props.disabled ? theme.colors.actionDisabledBackground : background,
        text: props.disabled
            ? props.outline
                ? theme.colors.actionDisabledText
                : theme.colors.actionDisabledText
            : text,
        textHover: props.disabled
            ? props.outline
                ? theme.colors.actionDisabledText
                : theme.colors.actionDisabledText
            : textHover,
        border: props.disabled
            ? props.loading
                ? borderHover
                : theme.colors.actionDisabledBorder
            : border,
        borderHover:
            props.disabled && !props.outline ? theme.colors.actionDisabledBorderHover : borderHover,
    });
    if (props.buttonThemeColors) {
        return result(
            props.buttonThemeColors.backgroundColor,
            props.buttonThemeColors.backgroundColorHover,
            props.buttonThemeColors.textColor,
            props.buttonThemeColors.textColorHover,
            '',
            ''
        );
    }
    if (props.outline) {
        return result(
            theme.colors.actionOutlineBackground,
            theme.colors.actionOutlineBackgroundHover,
            theme.colors.actionOutlineText,
            theme.colors.actionOutlineTextHover,
            theme.colors.actionOutlineBorder,
            theme.colors.actionOutlineBorderHover
        );
    }
    if (props.tertiary) {
        return result(
            theme.colors.actionTertiaryBackground,
            theme.colors.actionTertiaryBackgroundHover,
            theme.colors.actionTertiaryText,
            theme.colors.actionTertiaryTextHover,
            theme.colors.actionTertiaryBorder,
            theme.colors.actionTertiaryBorderHover
        );
    }

    if (props.secondary) {
        return result(
            theme.colors.actionSecondaryBackground,
            theme.colors.actionSecondaryBackgroundHover,
            theme.colors.actionSecondaryText,
            theme.colors.actionSecondaryTextHover,
            theme.colors.actionSecondaryBorder,
            theme.colors.actionSecondaryBorderHover
        );
    }

    if (props.secondaryBlack) {
        return result(
            theme.colors.actionSecondaryBlackBackground,
            theme.colors.actionSecondaryBlackBackgroundHover,
            theme.colors.actionSecondaryBlackText,
            theme.colors.actionSecondaryBlackTextHover,
            theme.colors.actionSecondaryBlackBorder,
            theme.colors.actionSecondaryBlackBorderHover
        );
    }

    if (props.secondaryWhite) {
        return result(
            theme.colors.actionSecondaryWhiteBackground,
            theme.colors.actionSecondaryWhiteBackgroundHover,
            theme.colors.actionSecondaryWhiteText,
            theme.colors.actionSecondaryWhiteTextHover,
            theme.colors.actionSecondaryWhiteBorder,
            theme.colors.actionSecondaryWhiteBorderHover
        );
    }

    return result(
        theme.colors.actionPrimaryBackground,
        theme.colors.actionPrimaryBackgroundHover,
        theme.colors.actionPrimaryText,
        theme.colors.actionPrimaryTextHover,
        theme.colors.actionPrimaryBorder,
        theme.colors.actionPrimaryBorderHover
    );
};

const Text = styled.span<{ $isLoading?: boolean; $isSmall?: boolean }>`
    ${({ $isSmall, $isLoading }) =>
        $isSmall &&
        $isLoading &&
        css`
            visibility: hidden;
        `}
    ${({ theme }) => css`
        font-family: ${theme.fontButtonFamily};
        text-transform: ${theme.fontButtonTransform};
    `};
    font-size: ${({ $isSmall, theme }) =>
        $isSmall ? theme.sizeTextBodySmall : theme.sizeTextBodyMedium};
    color: ${({ color }) => color};
    font-weight: ${({ $isSmall, theme }) =>
        $isSmall ? theme.fontButtonWeightSmall : theme.fontButtonWeight};
    text-align: center;
    line-height: 1;
    margin-left: ${({ $isLoading, theme, $isSmall }) =>
        $isLoading && !$isSmall
            ? theme.sizeGridSpacingRem24
            : $isSmall
              ? theme.sizeGridSpacingRem16
              : theme.sizeGridSpacingRem24};
    margin-right: ${({ $isSmall, theme }) =>
        $isSmall ? theme.sizeGridSpacingRem16 : theme.sizeGridSpacingRem24};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-decoration: none;
`;

const BtnPrimaryBgDefault = styled.button<{
    $backgroundColor?: string;
    $backgroundHoverColor?: string;
    $borderColor?: string;
    $borderHoverColor?: string;
    $textHoverColor: string;
    $outline?: boolean;
    $isSmall?: boolean;
    $themeColors?: ButtonTheme;
}>`
    ${({ $backgroundColor }) =>
        typeof $backgroundColor === 'string'
            ? css`
                  background: ${$backgroundColor};
              `
            : css`
                  background: transparent;
              `}

    ${({ $borderColor, theme }) =>
        $borderColor
            ? css`
                  border: ${theme.sizeOtherBorderWidthS} solid ${$borderColor};
              `
            : css`
                  border: none;
              `}
    ${({ $outline }) =>
        $outline &&
        css`
            background: transparent;
        `};
    border-radius: ${({ theme }) => theme.sizeOtherBorderRadiusButton};
    height: ${({ $isSmall, theme }) =>
        $isSmall ? theme.sizeGridSpacingRem32 : theme.sizeGridSpacingRem48};

    @media (min-width: ${({ theme }) => `${theme.breakpointExtraLarge}px`}) {
        height: ${({ $isSmall, theme }) =>
            $isSmall ? theme.sizeGridSpacingRem32 : theme.sizeGridSpacingRem56};
    }

    font-size: ${({ $isSmall, theme }) =>
        $isSmall ? theme.sizeTextBodySmall : theme.sizeTextBodyMedium};
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${({ $isSmall }) => ($isSmall ? `auto` : `100%`)};
    cursor: pointer;
    -webkit-appearance: none;
    text-decoration: none;
    padding: 0;
    outline: none;
    position: relative;
    letter-spacing: ${({ theme }) => theme.fontButtonLetterSpacing};
    z-index: 1;
    padding-bottom: ${({ theme }) => (theme.fontBodyFamily === 'Input Mono' ? '2px' : '0')};
    &:hover,
    &:focus {
        background: ${({ $backgroundHoverColor }) => $backgroundHoverColor};
        ${({ $borderHoverColor }) =>
            $borderHoverColor &&
            css`
                border-color: ${$borderHoverColor};
            `}

        ${Text} {
            color: ${({ $textHoverColor }) => $textHoverColor};
        }
    }

    &:disabled {
        cursor: not-allowed;
    }
`;

const StyledUIconLoader = styled(UIconLoader)<{ $outline?: boolean; $isSmall?: boolean }>`
    width: ${({ theme }) => theme.sizeOtherIconS};
    color: ${({ $outline, color, theme }) => ($outline ? color : theme.colors.iconLoader)};
    ${({ $isSmall }) =>
        $isSmall
            ? css`
                  position: absolute;
                  left: 50%;
                  transform: translateX(-50%);
              `
            : css`
                  margin-left: -theme.sizeGridSpacingRem20;
              `}
`;

const StyledLink = styled(GatsbyLink)`
    text-decoration: none;
`;

export default UButton;
