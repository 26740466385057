import { brandTitleUppercase } from '../constants/brandTitle';
import { GTMEvents } from '../constants/gtm';
import { Battery, IListProduct } from '../types/product';
import { LineItem } from '../types/shopwareCart';
import isBrowser from './isBrowser';

export function sendAddCartEvent(
    eventName: string,
    id: string,
    name: string,
    price?: number,
    category?: string[],
    variant?: string,
    quantity?: number
) {
    if (!isBrowser()) return;
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
        event: eventName,
        ecommerce: {
            items: [
                {
                    item_id: id,
                    item_name: name,
                    price,
                    item_brand: brandTitleUppercase,
                    ...structureGA4ItemCategories(category),
                    item_variant: variant,
                    quantity,
                },
            ],
        },
    });
}

export function sendRemoveCartEvent(
    eventName: string,
    id: string,
    name: string,
    price?: number,
    category?: string[],
    variant?: string,
    quantity?: number
) {
    if (!isBrowser()) return;
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
        event: eventName,
        ecommerce: {
            items: [
                {
                    item_id: id,
                    item_name: name,
                    price,
                    item_brand: brandTitleUppercase,
                    ...structureGA4ItemCategories(category), // TODO: Check if this is correct
                    item_variant: variant,
                    quantity,
                },
            ],
        },
    });
}
export const structureGA4ItemCategories = (categories?: string[]) => {
    if (!categories) return null;
    const itemCategories = categories.reduce(
        (acc, curr, index) => {
            const key = index === 0 ? 'item_category' : `item_category${index + 1}`;
            acc[key] = curr;
            return acc;
        },
        {} as Record<string, string>
    );
    return itemCategories;
};

export const getAnalyticProducts = (
    products?: IListProduct[],
    parentProducts?: IListProduct[],
    lineItems?: LineItem[],
    batteries?: Battery[]
) =>
    lineItems?.map((lineItem: LineItem) => {
        const linkedProduct = products?.find(
            (product: IListProduct) =>
                lineItem.id === product?.objectID || lineItem.id === product?.id
        );

        const parentProduct = parentProducts?.find(
            product =>
                product.id === linkedProduct?.parentId || product.id === lineItem.payload.parentId
        );

        if (linkedProduct) {
            return {
                item_name: parentProduct?.name ?? linkedProduct.name,
                item_id: parentProduct?.sku ?? linkedProduct?.sku,
                price: lineItem?.price.unitPrice,
                item_brand: linkedProduct?.brand
                    ? linkedProduct.brand.toUpperCase()
                    : brandTitleUppercase,
                ...structureGA4ItemCategories(linkedProduct?.categories), // TODO: Check if this is correct
                item_variant: lineItem?.payload?.productNumber,
                quantity: lineItem?.quantity,
            };
        }

        const linkedBattery = batteries?.find(
            battery => battery.objectID === lineItem.referencedId
        );

        if (!linkedBattery) {
            return {
                price: lineItem?.price.unitPrice,
                item_variant: lineItem?.payload?.productNumber,
                quantity: lineItem?.quantity,
            };
        }

        return {
            item_name: linkedBattery?.name,
            item_id: linkedBattery?.productNumber,
            price: lineItem?.price.unitPrice,
            item_category: 'battery',
            quantity: lineItem?.quantity,
        };
    });

export function sendCheckoutEvent(
    eventName: string,
    products?: IListProduct[],
    parentProducts?: IListProduct[],
    lineItems?: LineItem[],
    batteries?: Battery[]
) {
    if (!isBrowser()) return;

    const analyticProducts = getAnalyticProducts(products, parentProducts, lineItems, batteries);
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
        event: eventName,
        ecommerce: {
            items: analyticProducts,
        },
    });
}

export function sendCheckoutErrorEvent(message: string) {
    if (!isBrowser()) return;

    window.dataLayer.push({
        event: GTMEvents.checkoutError,
        eventCategory: 'CheckoutError',
        eventAction: 'message',
        eventLabel: message,
    });
}

export function sendCheckoutOptionEvent(option: string, event: string) {
    if (!isBrowser()) return;

    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
        event,
        ecommerce: {
            option,
        },
    });
}

export function sendSalesforceSubmitEvent() {
    if (!isBrowser()) return;

    window.dataLayer.push({
        event: GTMEvents.send,
        eventCategory: 'Contact Form',
        eventAction: 'submit',
    });
}
