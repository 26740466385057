import { string, StringSchema, addMethod } from 'yup';
import validZipcode from 'i18n-zipcodes';

type ValidZipcodeFn = (iso: string, message?: string) => StringSchema;

declare module 'yup' {
    interface StringSchema {
        validZipcode: ValidZipcodeFn;
    }
}

const method: ValidZipcodeFn = (iso, message) =>
    string().test({
        message,
        test(value?: string) {
            const { createError, path } = this;

            if (!iso || !value) {
                return false;
            }

            let valid = false;
            try {
                valid = validZipcode(iso, value.toUpperCase());
            } catch (e) {
                // eslint-disable-next-line no-console
                console.warn('validZipCode validation failed:', e);
                valid = true;
            }

            if (!valid) {
                createError({
                    path,
                    message,
                });
                return false;
            }
            return true;
        },
    });

addMethod(string, 'validZipcode', method);
