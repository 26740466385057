import { GatsbyImageData } from '../types/image';
import { JPEG_QUALITY } from '../constants';
import { themeLight } from '../constants/theming/theme';
import getStoryblokImageGatsbyImageData, {
    GetGatsbyImageOptions,
} from './getStoryblokImageGatsbyImageData';

export enum ImageLayout {
    Fixed = 'fixed',
    Constrained = 'constrained',
    FullWidth = 'fullWidth',
}

const BREAKPOINTS = [
    themeLight.mediaQueryS,
    themeLight.mediaQueryM,
    themeLight.mediaQueryL,
    themeLight.mediaQueryXl,
    themeLight.mediaQueryXxl,
];

const DEFAULT_GET_IMAGE_OPTIONS: Omit<GetGatsbyImageOptions, 'layout'> = {
    /** Global additional optional options: */
    breakpoints: BREAKPOINTS,

    /** Storyblok image service additional options: */
    fitIn: false, // false = act like "cover", true = act like "contain". Refer to https://www.storyblok.com/docs/image-service#fit-in
    fitInColor: 'transparent', // Color to fill when fitIn true. Okay to add `#` prefix.
    smartCrop: true,
    quality: JPEG_QUALITY,
};

function getResponsiveGatsbyImageData(
    storyblokImage: string | undefined | null,
    /**
     * Options can be overwriting defaults and should include at least `layout`, example options are:
     *
     * - `layout` can be 'fixed' | 'constrained' | 'fullWidth',
     * see more: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-plugin-image/#layout.
     *
     * - use `width` or `height` when layout is 'fixed' (fixed width and heigh) or 'constrained' (max width or height).
     * No need to use `width` or `height` when layout is 'fullWidth'.
     *
     * - use `fitIn` to make the image like fixed object to "contain",
     * see more: https://www.storyblok.com/docs/image-service#fit-in.
     *
     * - use `quality` to define lower output quality.
     *
     * - use `backgroundImage` just to pass to gatsby-plugin-image image prop
     */
    options: GetGatsbyImageOptions
): GatsbyImageData | undefined {
    if (!storyblokImage) {
        return undefined;
    }

    const gatsbyImageData = getStoryblokImageGatsbyImageData(storyblokImage, {
        ...DEFAULT_GET_IMAGE_OPTIONS,
        ...options,
    });

    return gatsbyImageData ?? undefined;
}

export default getResponsiveGatsbyImageData;
