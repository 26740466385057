import { Countries, Languages, StoryblokAdditionalLanguages } from '../types/Countries';

export const countries: Countries = [
    'INT', // Default country
    'FR',
    'NL',
    'DE',
    'ES',
];

export const languages: Languages = {
    INT: [
        'en', // Default country/language
    ],
    FR: ['fr'],
    NL: ['nl'],
    DE: ['de'],
    ES: ['es'],
};

// Override the above country/language codes with specific codes for Storyblok
export const storyblokAdditionalLanguages: StoryblokAdditionalLanguages = {};
