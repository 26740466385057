import { Countries, Languages, StoryblokAdditionalLanguages } from '../types/Countries';

interface CountryConstants {
    countries: Countries;
    languages: Languages;
    storyblokAdditionalLanguages?: StoryblokAdditionalLanguages;
}

// Needed for Storybook
const brandKey = process.env.GATSBY_BRAND ?? process.env.STORYBOOK_BRAND ?? 'raleigh';

// eslint-disable-next-line import/no-dynamic-require,@typescript-eslint/no-var-requires
const constants: CountryConstants = require(`./countries.${brandKey}`);
export const { countries, languages, storyblokAdditionalLanguages = {} } = constants;

export const defaultCountry = countries[0];
export const defaultLanguage = languages[defaultCountry][0];
