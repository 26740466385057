import React, { FC, HTMLAttributes, useEffect } from 'react';
import styled, { css, keyframes } from 'styled-components';
import isBrowser from '../../services/isBrowser';
import ErrorIcon from './icons/UIconError';
import SuccessIcon from './icons/UIconSuccess';
import WarningIcon from './icons/UIconWarning';
import InfoIcon from './icons/UIconInfoBlue';

export type MessageVariant = 'success' | 'fail' | 'warning' | 'info';

interface Props extends HTMLAttributes<{}> {
    variant?: MessageVariant;
    dataLayer?: {
        dataEvent: string;
        type?: string;
        element?: string;
        description: string;
    };
}

const getIcon = (variant: MessageVariant) => {
    switch (variant) {
        case 'fail':
            return <ErrorIcon />;
        case 'warning':
            return <WarningIcon />;
        case 'info':
            return <InfoIcon />;
        case 'success':
        default:
            return <SuccessIcon />;
    }
};

const getCss = (variant: MessageVariant) => {
    switch (variant) {
        case 'fail':
            return FailCss;
        case 'warning':
            return WarningCss;
        case 'info':
            return InfoCss;

        case 'success':
        default:
            return SuccessCss;
    }
};

const UMessage: FC<Props> = ({ variant = 'success', dataLayer = undefined, children, ...rest }) => {
    useEffect(() => {
        if (isBrowser() && variant === 'fail' && !!dataLayer) {
            if (!window.dataLayer) {
                window.dataLayer = [];
            }
            window.dataLayer.push({
                type: dataLayer?.type ?? 'error',
                event: dataLayer?.dataEvent,
                element: dataLayer?.element ?? '',
                description: `Error - ${dataLayer?.description}`,
            });
        }
    }, [dataLayer, variant]);

    return (
        <Container $variant={variant} {...rest}>
            <IconContainer>{getIcon(variant)}</IconContainer>
            <div>{children}</div>
        </Container>
    );
};

export default UMessage;

const SuccessCss = css`
    background: ${({ theme }) => theme.colors.formStatusSuccessLight};
    color: ${({ theme }) => theme.colors.formStatusSuccessDark};
`;

const FailCss = css`
    background: ${({ theme }) => theme.colors.formStatusErrorLight};
    color: ${({ theme }) => theme.colors.formStatusErrorDark};
`;

const WarningCss = css`
    background: ${({ theme }) => theme.colors.formStatusWarningLight};
    color: ${({ theme }) => theme.colors.formStatusWarningDark};
`;

const InfoCss = css`
    background: ${({ theme }) => theme.colors.formStatusInfoLight};
    color: ${({ theme }) => theme.colors.formStatusInfoDark};
`;

const slideIn = keyframes`
    0% { opacity: 0; transform: translateY(5rem) }
    100% { opacity: 1; transform: translateY(0rem) }
`;

const Container = styled.div<{ $variant: MessageVariant; $transparent?: boolean }>`
    border-radius: ${({ theme }) => `${theme.sizeOtherBorderRadiusM}`};
    display: flex;
    flex-direction: row;
    padding: ${({ theme }) => `${theme.sizeGridSpacingRem16}`};
    margin-top: ${({ theme }) => `${theme.sizeGridSpacingRem8}`};
    margin-bottom: ${({ theme }) => `${theme.sizeGridSpacingRem16}`};
    align-items: center;
    opacity: 1;
    font-size: ${({ theme }) => `${theme.sizeTextBodySmall}`};
    transform: translateY(0rem);
    ${({ $variant = 'success' }) => getCss($variant)};
    animation: ${({ theme }) => css`
        ${slideIn} 0.3s ${theme.additionalMotionEaseIn};
    `};
`;

export const IconContainer = styled.div`
    margin-right: ${({ theme }) => `${theme.sizeGridSpacingRem8}`};
    height: ${({ theme }) => `${theme.sizeOtherIconS}`};
    width: ${({ theme }) => `${theme.sizeOtherIconS}`};
    & > svg {
        width: 100%;
        min-width: ${({ theme }) => `${theme.sizeOtherIconS}`};
        height: auto;
    }
`;
