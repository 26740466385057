import { ISbConfig, ISbResponse } from 'gatsby-source-storyblok';

export const STORYBLOK_API_OPTIONS: ISbConfig = {
    // storyblok-js-client config object (see: https://github.com/storyblok/storyblok-js-client#class-storyblok)
    cache: {
        clear: 'auto',
        type: 'memory',
    },
    maxRetries: 10, // defaults to 5
    responseInterceptor: (response: ISbResponse) => {
        // You can pass a function and return the result, like axios' interceptors. For security reasons, Storyblok client will deal only with the response interceptor.

        if (response.status >= 500 || response.status === 429) {
            // eslint-disable-next-line no-console
            console.log(
                `-----\n⚠️ Storyblok query failed with status code: ${response.status}`,
                `\n${response}.`,
                '\nIf too many 429 retry errors appear the build might fail and you need to wait before trying again. \n-----'
            );
        }
        // ALWAYS return the response
        return response;
    },
};

export const STORYBLOK_RESOLVED_RELATIONS = [
    // Make sure we resolve relations that we access via parents

    // Low level content (media)
    'bannerBlock.connectedImage',
    'bannerBlock.connectedVideo',
    'heroBlock.connectedImage',
    'heroBlock.connectedImage2',
    'heroQuoteBlock.image',
    'heroWithVideoBlock.connectedVideo',
    'fullScreenImageHero.connectedImage',
    'listXLBlock.connectedImage',
    'listXLBlock.connectedVideo',
    'imageAndTextBlock.connectedImages',
    'twoImagesAndTextBlock.connectedImages',
    'videoAndTextBlock.connectedVideos',
    'keyFeatureListItem.connectedImage',
    'signUpFormNewsletter.connectedImage',
    'newsletterPopUp.image',
    'keyFeature.image',
    'OProductLine.imagePrimary',
    'OProductLine.imageSecondary',
    'OProductLine.videoPrimary',
    'OVideo.thumbnailImage',
    'highlightedBike.connectedImage',
    'connectedImages.connectedImage',
    'riderProfile.connectedImage',
    'sliderSlide.connectedImage',
    'connectedCampaigns.subCampaigns',
    'textAndMediaBlock.connectedMedia',

    // High level content
    'pathInfoHomePage.bottomCampaign',
    'pathInfoHomePage.mainCampaign',
    'pathInfoHomePage.onPageNavigationItems',
    'pathInfoHomePage.subCampaigns',
    'pathInfoListingPageContent.objectsConnectedManual',
    'paymentMethodsAndCouriers.couriers',
    'paymentMethodsAndCouriers.paymentMethods',
    'contactMethods.connectedContactMethods',
    'connectedProductLines.connectedProductLines',
    'connectedFAQsBlock.connectedFAQs',
    'socialMediaChannels.connectedSocialMediaChannels',
    'connectedCategories.connectedCategories',
    'OProductLine.highlightedAttributes',
    'OReturnMethod.uspsConnected',
    'OShippingMethod.uspsConnected',
    'uspsHeader.uspsConnected',
    'pathInfoDetailPage.detailPageConnected',
    'splashPage.connectedImage'
];
