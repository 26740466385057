import { defaultCountry, defaultLanguage } from './constants/countries';
import { uiSiteConfig } from './constants/uiConfig';

export interface Locale {
    country: string;
    language: string;
}

const localeRegExp = new RegExp(
    `^/([a-z]{2,3})${uiSiteConfig.urlStructure.localeSeparator}([a-z]{2})($|/)`
);

export default (path: string): Locale => {
    const matches = localeRegExp.exec(path);
    return matches
        ? { country: matches[1].toUpperCase(), language: matches[2] }
        : { country: defaultCountry, language: defaultLanguage }; // default to avoid null checks
};
