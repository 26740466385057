import { Countries, Languages, StoryblokAdditionalLanguages } from '../types/Countries';

export const countries: Countries = [
    'NL', // Default country
    'DK',
    'BE',
];

export const languages: Languages = {
    NL: [
        'nl', // Default country/language
    ],
    DK: ['da'],
    BE: ['nl', 'fr'],
};

// Override the above country/language codes with specific codes for Storyblok
export const storyblokAdditionalLanguages: StoryblokAdditionalLanguages = {};
