import React, { FunctionComponent, SVGAttributes } from 'react';

type IProps = SVGAttributes<SVGElement>;

const UIconInfoBlue: FunctionComponent<IProps> = props => (
    <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 16 16" fill="none">
        <rect width="16" height="16" rx="8" fill="#1244CA" />
        <path
            d="M8.00017 5.24807C8.29546 5.24807 8.53608 5.13782 8.72138 4.91807C8.90735 4.69831 9 4.42081 9 4.08406C9 3.77355 8.90668 3.51555 8.72138 3.30855C8.53608 3.10155 8.29546 2.99805 8.00017 2.99805C7.70489 2.99805 7.46427 3.10605 7.27897 3.3213C7.18729 3.42723 7.11541 3.55275 7.06756 3.69046C7.0197 3.82816 6.99685 3.97525 7.00035 4.12306C7.00035 4.44181 7.09367 4.70956 7.27897 4.92482C7.46427 5.14007 7.70489 5.24807 8.00017 5.24807Z"
            fill="#EFF6FF"
        />
        <path d="M8.75 6.09183V11.998H7.25V6.09183H8.75Z" fill="#EFF6FF" />
    </svg>
);

export default UIconInfoBlue;
