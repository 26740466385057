import React, { ReactNode } from 'react';
import { Document } from '../types/storyBlokComponentTypes';
import ContentRenderer from './ContentRenderer';
import UContainer from './ui/UGrid/UContainer';
import styled from 'styled-components';

interface Props {
    unhandledError: Document;
    children: ReactNode;
}

class ErrorBoundary extends React.Component<Props, { hasError: boolean }> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    constructor(props: any) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
        // eslint-disable-next-line no-console
        console.error(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return (
                <StyledUContainer>
                    <ContentRenderer document={this.props.unhandledError} />
                </StyledUContainer>
            );
        }

        return this.props.children;
    }
}

const StyledUContainer = styled(UContainer)`
    padding-top: 6rem;
    padding-bottom: 6rem;
`;

export default ErrorBoundary;
