import { createContext, useContext } from 'react';

export interface Translations {
    // translations are structured per component
    [component: string]: {
        [key: string]: string;
    };
}

export interface TranslationContext {
    translations: Translations;
    translationsUsed: Set<string>;
}

const TranslateContext = createContext<TranslationContext>({
    translations: {},
    translationsUsed: new Set(),
});

export const TranslationsProvider = TranslateContext.Provider;

export default (component: string): { [key: string]: string } => {
    const context = useContext<TranslationContext>(TranslateContext);
    context.translationsUsed.add(component);
    return context.translations[component] ?? {};
};
