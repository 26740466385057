import { fieldsSelector } from './fields.helpers';
import {
    ALL_FIELDS_PRODUCT,
    ALL_FIELDS_PRODUCT_VARIANT,
    ShopwareProductFieldsKeys,
    ShopwareProductVariantFieldsKeys,
} from './fields';

// PRODUCT
const MINIMAL_PRODUCT_FIELD_LIST: ShopwareProductFieldsKeys[] = [
    'objectID',
    'inactive',
    'sku',
    'ean',
    'name',
    'brand',
    'type',
    'categories',
    'price',
    'listPrice',
    'img',
    'imgThumbnails',
    'imgAlt',
    'customFields',
    'metaTitle',
    'metaDescription',
    'metaKeywords',
    'variantIds',
    'legalNotes', // TODO: this is a workaround , we need to do it via CLIENT_PDP_BELOW_FOLD_PRODUCT_FIELD_LIST
    'attributes',
];
export const MINIMAL_PRODUCT_FIELDS = fieldsSelector(
    ALL_FIELDS_PRODUCT,
    MINIMAL_PRODUCT_FIELD_LIST
);

// PRODUCT VARIANT
const MINIMAL_PRODUCT_VARIANT_FIELD_LIST: ShopwareProductVariantFieldsKeys[] = [
    'objectID',
    'inactive',
    'sku',
    'ean',
    'name',
    'options',
    'shape',
    'color',
    'customFields',
    'metaTitle',
    'metaDescription',
    'metaKeywords',
    'product',
];

/* **************************************
 *              CREATE PAGES            *
 ************************************** */

// PRODUCT
const CREATE_PAGES_PRODUCT_FIELD_LIST: ShopwareProductFieldsKeys[] = MINIMAL_PRODUCT_FIELD_LIST;

// PRODUCT VARIANT
export const CREATE_PAGES_PRODUCT_VARIANT_FIELD_LIST: ShopwareProductVariantFieldsKeys[] =
    MINIMAL_PRODUCT_VARIANT_FIELD_LIST;

// PRODUCT WITH VARIANTS
const CREATE_PAGES_PRODUCT_WITH_VARIANTS_OPTIONS_FIELD_LIST: (
    | ShopwareProductFieldsKeys
    | ShopwareProductVariantFieldsKeys
)[] = [
    ...CREATE_PAGES_PRODUCT_FIELD_LIST,
    // AND variants
    'variantsOnlyOptions',
    // AND make sure all fields for variants child product are included
    ...CREATE_PAGES_PRODUCT_VARIANT_FIELD_LIST,
];

export const CREATE_PAGES_PRODUCT_WITH_VARIANTS_OPTIONS_FIELDS = fieldsSelector(
    { ...ALL_FIELDS_PRODUCT, ...ALL_FIELDS_PRODUCT_VARIANT },
    CREATE_PAGES_PRODUCT_WITH_VARIANTS_OPTIONS_FIELD_LIST
);

/* **************************************
 *           CREATE PAGES END           *
 ************************************** */

/* **************************************
 *                PDP SSR               *
 ************************************** */

// PRODUCT SSR
export const SERVER_PDP_PRODUCT_FIELD_LIST: ShopwareProductFieldsKeys[] = [
    ...MINIMAL_PRODUCT_FIELD_LIST,
    'hero',
    'heroAlt',
    'images',
    'subtitle',
    'description',
    'modelLogo',
    'bikeLogo',
];
export const SERVER_PDP_PRODUCT_FIELDS = fieldsSelector(
    ALL_FIELDS_PRODUCT,
    SERVER_PDP_PRODUCT_FIELD_LIST
);

// PRODUCT VARIANT SSR
export const SERVER_PDP_VARIANT_FIELD_LIST: ShopwareProductVariantFieldsKeys[] = [
    ...MINIMAL_PRODUCT_VARIANT_FIELD_LIST,
    'hero',
    'heroAlt',
    'img',
    'imgThumbnails',
    'imgAlt',
    'images',
    'price',
    'listPrice',
    'description',
];
export const SERVER_PDP_VARIANT_FIELDS = fieldsSelector(
    ALL_FIELDS_PRODUCT_VARIANT,
    SERVER_PDP_VARIANT_FIELD_LIST
);

/* **************************************
 *              PDP SSR END             *
 ************************************** */

/* **************************************
 *                  PDP                 *
 ************************************** */

// PRODUCT
export const CLIENT_PDP_PRODUCT_FIELD_LIST: ShopwareProductFieldsKeys[] = [
    ...MINIMAL_PRODUCT_FIELD_LIST,
    'hero',
    'heroAlt',
    'images',
    'subtitle',
    'description',
    'modelLogo',
    'bikeLogo',
];
export const CLIENT_PDP_PRODUCT_FIELDS = fieldsSelector(
    ALL_FIELDS_PRODUCT,
    CLIENT_PDP_PRODUCT_FIELD_LIST
);

// PRODUCT VARIANT
export const CLIENT_PDP_PRODUCT_VARIANT_FIELD_LIST: ShopwareProductVariantFieldsKeys[] = [
    ...MINIMAL_PRODUCT_VARIANT_FIELD_LIST,
    'hero',
    'heroAlt',
    'img',
    'imgThumbnails',
    'imgAlt',
    'images',
    'price',
    'listPrice',
    'description',
];
export const CLIENT_PDP_VARIANT_FIELDS = fieldsSelector(
    ALL_FIELDS_PRODUCT_VARIANT,
    CLIENT_PDP_PRODUCT_VARIANT_FIELD_LIST
);

/* **************************************
 *                PDP END               *
 ************************************** */

/* **************************************
 *           PDP Below the fold         *
 ************************************** */

// PRODUCT
export const CLIENT_PDP_BELOW_FOLD_PRODUCT_FIELD_LIST: ShopwareProductFieldsKeys[] = [
    'objectID',
    // Fields only for fetching additional info for PDP, not required for initial loading
    'stockStatus',
    'attributes',
    'keyFeatures',
    'halfords',
    'legalNotes',
    'geometry',
    'geometryAlt',
];
export const CLIENT_PDP_BELOW_FOLD_PRODUCT_FIELDS = fieldsSelector(
    ALL_FIELDS_PRODUCT,
    CLIENT_PDP_BELOW_FOLD_PRODUCT_FIELD_LIST
);

// PRODUCT VARIANT
export const CLIENT_PDP_BELOW_FOLD_PRODUCT_VARIANT_FIELD_LIST: ShopwareProductVariantFieldsKeys[] =
    [
        'objectID',
        // Fields only for fetching additional info for PDP, not required for initial loading
        'stockStatus',
        'attributes',
        'keyFeatures',
        'secondaryColor',
        'halfords',
        'geometry',
        'geometryAlt',
    ];
export const CLIENT_PDP_BELOW_FOLD_VARIANT_FIELDS = fieldsSelector(
    ALL_FIELDS_PRODUCT_VARIANT,
    CLIENT_PDP_BELOW_FOLD_PRODUCT_VARIANT_FIELD_LIST
);

/* **************************************
 *        PDP Below the fold END        *
 ************************************** */

/* ***************************************
 * Shopware (only) Product Listing START *
 *************************************** */

const CLIENT_SHOPWARE_PRODUCT_LISTING_FIELD_LIST: ShopwareProductFieldsKeys[] = [
    ...MINIMAL_PRODUCT_FIELD_LIST,
    'images',
    'attributes',
    'subtitle',
    'description',
    'halfords',
    'inactive',
    'stockStatus',
    'createdAt',
    'updatedAt',
];
export const CLIENT_SHOPWARE_PRODUCT_LISTING_VARIANT_FIELD_LIST: ShopwareProductVariantFieldsKeys[] =
    [
        ...MINIMAL_PRODUCT_VARIANT_FIELD_LIST,
        'ean',
        'name',
        'price',
        'listPrice',
        'img',
        'imgThumbnails',
        'imgAlt',
        'images',
        'description',
        'secondaryColor',
        'halfords',
        'inactive',
        'stockStatus',
        'createdAt',
        'updatedAt',
    ];

export const CLIENT_SHOPWARE_PRODUCT_LISTING_WITH_VARIANTS_FIELD_LIST: (
    | ShopwareProductFieldsKeys
    | ShopwareProductVariantFieldsKeys
)[] = Array.from(
    new Set([
        ...CLIENT_SHOPWARE_PRODUCT_LISTING_FIELD_LIST,
        // AND variants
        'variants',
        // AND make sure all fields for variants child product are included
        ...CLIENT_SHOPWARE_PRODUCT_LISTING_VARIANT_FIELD_LIST,
    ])
);
export const CLIENT_SHOPWARE_PRODUCT_LISTING_WITH_VARIANTS_FIELDS = fieldsSelector(
    { ...ALL_FIELDS_PRODUCT, ...ALL_FIELDS_PRODUCT_VARIANT },
    CLIENT_SHOPWARE_PRODUCT_LISTING_WITH_VARIANTS_FIELD_LIST
);

/* **************************************
 * Shopware (only) Product Listing END  *
 ************************************** */

/* **************************************
 *            PLP with ES START         *
 ************************************** */

const CLIENT_PLP_ES_PRODUCT_FIELD_LIST: ShopwareProductFieldsKeys[] = [
    ...MINIMAL_PRODUCT_FIELD_LIST,
    'images',
    'attributes',
    'subtitle',
    'description',
    'halfords',
    'inactive',
    'stockStatus',
    'createdAt',
    'updatedAt',
];
export const CLIENT_PLP_ES_PRODUCT_VARIANT_FIELD_LIST: ShopwareProductVariantFieldsKeys[] = [
    ...MINIMAL_PRODUCT_VARIANT_FIELD_LIST,
    'ean',
    'name',
    'price',
    'listPrice',
    'img',
    'imgThumbnails',
    'imgAlt',
    'images',
    'description',
    'secondaryColor',
    'halfords',
    'inactive',
    'stockStatus',
    'createdAt',
    'updatedAt',
];

export const CLIENT_PLP_ES_PRODUCT_WITH_VARIANTS_FIELD_LIST: (
    | ShopwareProductFieldsKeys
    | ShopwareProductVariantFieldsKeys
)[] = Array.from(
    new Set([
        ...CLIENT_PLP_ES_PRODUCT_FIELD_LIST,
        // AND variants
        'variants',
        // AND make sure all fields for variants child product are included
        ...CLIENT_PLP_ES_PRODUCT_VARIANT_FIELD_LIST,
    ])
);
export const CLIENT_PLP_ES_PRODUCT_WITH_VARIANTS_FIELDS = fieldsSelector(
    { ...ALL_FIELDS_PRODUCT, ...ALL_FIELDS_PRODUCT_VARIANT },
    CLIENT_PLP_ES_PRODUCT_WITH_VARIANTS_FIELD_LIST
);

/* **************************************
 *            PLP with ES END           *
 ************************************** */

/* **************************************
 *             BikeComparison           *
 ************************************** */

// PRODUCT LISTING
export const CLIENT_BIKE_COMPARE_PRODUCT_LISTING_FIELD_LIST: ShopwareProductFieldsKeys[] = [
    ...MINIMAL_PRODUCT_FIELD_LIST,
    'stockStatus',
];
export const CLIENT_BIKE_COMPARE_PRODUCT_LISTING_FIELDS = fieldsSelector(
    ALL_FIELDS_PRODUCT,
    CLIENT_BIKE_COMPARE_PRODUCT_LISTING_FIELD_LIST
);

// PRODUCT
export const CLIENT_BIKE_COMPARE_PRODUCT_FIELD_LIST: ShopwareProductFieldsKeys[] = [
    ...MINIMAL_PRODUCT_FIELD_LIST,
    'attributes',
    'subtitle',
    'inactive',
    'stockStatus',
];
export const CLIENT_BIKE_COMPARE_PRODUCT_FIELDS = fieldsSelector(
    ALL_FIELDS_PRODUCT,
    CLIENT_BIKE_COMPARE_PRODUCT_FIELD_LIST
);

// PRODUCT VARIANT
export const CLIENT_BIKE_COMPARE_PRODUCT_VARIANT_FIELD_LIST: ShopwareProductVariantFieldsKeys[] = [
    ...MINIMAL_PRODUCT_VARIANT_FIELD_LIST,
    'ean',
    'name',
    'price',
    'listPrice',
    'img',
    'imgThumbnails',
    'imgAlt',
    'inactive',
    'stockStatus',
];
export const CLIENT_BIKE_COMPARE_PRODUCT_VARIANT_FIELDS = fieldsSelector(
    ALL_FIELDS_PRODUCT_VARIANT,
    CLIENT_BIKE_COMPARE_PRODUCT_VARIANT_FIELD_LIST
);

/* **************************************
 *           BikeComparison END         *
 ************************************** */

/* **************************************
 *  CHECKOUT + ORDER CONFIRM START      *
 ************************************** */

export const CLIENT_CHECKOUT_ORDER_FIELD_LIST: (
    | ShopwareProductFieldsKeys
    | ShopwareProductVariantFieldsKeys
)[] = [...MINIMAL_PRODUCT_FIELD_LIST, 'product'];
export const CHECKOUT_ORDER_FIELDS = fieldsSelector(
    { ...ALL_FIELDS_PRODUCT, ...ALL_FIELDS_PRODUCT_VARIANT },
    CLIENT_CHECKOUT_ORDER_FIELD_LIST
);

/* **************************************
 *    CHECKOUT + ORDER CONFIRM END      *
 ************************************** */
