import { createContext, useContext } from 'react';
import { defaultCountry, defaultLanguage } from '../constants/countries';

export interface Locale {
    country: string;
    language: string;
}

const defaultLocale: Locale = { country: defaultCountry, language: defaultLanguage };

const LocaleContext = createContext<Locale>(defaultLocale);
export const LocaleProvider = LocaleContext.Provider;

export default (): Locale => useContext(LocaleContext);
