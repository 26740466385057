import { IGatsbyImageData } from 'gatsby-plugin-image';

export interface GetGatsbyImageOptions {
    layout: 'fixed' | 'constrained' | 'fullWidth';
    // width & height can be auto detect as Storyblok have calc & mark it in the URL
    width?: number;
    height?: number;
    quality?: number;
    outputPixelDensities?: number[];
    breakpoints?: number[];
    fitIn?: boolean;
    fitInColor?: string;
    backgroundColor?: string;
    smartCrop?: boolean;
    focus?: string;
    // Simply disable the fallback image when the low resolution image is not used
    fallback?: false;
    fallbackQuality?: number;
}

interface MergedGetGatsbyImageOptions extends GetGatsbyImageOptions {
    outputPixelDensities: number[];
    breakpoints: number[];
    smartCrop: boolean;
    fitInColor: string;
    fitIn: boolean;
    fallbackQuality: number;
}

interface Image
    extends Partial<
        Pick<
            MergedGetGatsbyImageOptions,
            | 'width'
            | 'height'
            | 'smartCrop'
            | 'focus'
            | 'quality'
            | 'fitIn'
            | 'fitInColor'
            | 'fallbackQuality'
        >
    > {}

const STORYBLOK_BASE_URL = 'https://a.storyblok.com';
const TRANSPARENT = 'transparent';

const isValidImageUrlRegex =
    /^(https?:)?\/\/a.storyblok.com\/f\/*[0-9]+\/*[0-9]*x*[0-9]*\/[A-Za-z0-9]+\/[\S]+\.[a-zA-Z]+/;
const isStoryblokUrlRegex = /^(https?:)?\/\/a.storyblok.com\//;
const isFullUrlRegex = /(http?:|)\/\//;

const defaultOptions: Omit<MergedGetGatsbyImageOptions, 'layout'> = {
    smartCrop: true,
    outputPixelDensities: [1, 2, 3],
    breakpoints: [750, 1080, 1366, 1920],
    fitIn: false,
    fitInColor: 'transparent',
    fallbackQuality: 70,
};

function getBasicImageProps(url: string) {
    if (!url || !isValidImageUrlRegex.test(url)) {
        return null;
    }

    const originalPath = url.replace(isStoryblokUrlRegex, '');

    const [, , dimensions, , filename] = originalPath.split('/');
    const [width, height] = dimensions.split('x').map(num => Number(num));
    const [, extension] = filename.split('.');

    const aspectRatio = width / height;
    const metadata = { dimensions: { width, height, aspectRatio } };

    return {
        originalPath,
        extension,
        metadata,
    };
}

function getWidths(
    options: MergedGetGatsbyImageOptions,
    fixedWidth: number,
    resourceWidth: number
): number[] {
    const widths: number[] = [];
    switch (options.layout) {
        case 'fixed':
            widths.push(
                ...options.outputPixelDensities.map(scale => Math.round(fixedWidth * scale))
            );
            break;
        case 'fullWidth':
            widths.push(...options.breakpoints);
            break;
        case 'constrained':
            widths.push(
                ...options.outputPixelDensities.map(scale => Math.round(fixedWidth * scale)),
                ...options.breakpoints
            );
            break;
        default:
            break;
    }

    // For better matching of the image, we should add the original resource size when is not fixed layout
    if (['fullWidth', 'constrained'].includes(options.layout)) {
        widths.push(resourceWidth);
    }

    return [...new Set(widths.filter(width => width <= resourceWidth && width > 0))].sort(
        (a, b) => a - b
    );
}

function applyFilters(filters: string[]): string {
    if (filters.length === 0) {
        return '';
    }
    return `/filters:${filters.join(':')}`;
}

function buildImageUrl(originalPath: string, image: Image): string {
    const { width, height, smartCrop, quality, fitIn, fitInColor, focus } = image;

    // base url
    let url = '';

    let expandedFocus;

    // compat path is a full url or just path
    if (!isFullUrlRegex.test(originalPath)) {
        url += STORYBLOK_BASE_URL;
        if (!originalPath.startsWith('/')) {
            url += '/';
        }
    }

    url += `${originalPath}/m`;

    if (fitIn) {
        url += '/fit-in';
    }

    if (!!width || !!height) {
        url += `/${width ?? 0}x${height ?? 0}`;
    }

    if (smartCrop) {
        url += `/smart`;
    }

    // expand the focal point area
    if (focus) {
        const [leftTopFocus, rightBottom] = focus.split(':');
        let [left, top] = leftTopFocus.split('x');

        left = (+left - 100 > 0 ? +left - 100 : 0).toString();
        top = (+top - 100 > 0 ? +top - 100 : 0).toString();

        expandedFocus = `${left}x${top}:${rightBottom}`;
    }

    const filters = [
        quality && `quality(${quality})`,
        // format && format !== extension && `format(${format})`,
        fitIn && `fill(${(fitInColor ?? TRANSPARENT).replace('#', '')})`,
        focus && `focal(${expandedFocus})`,
    ].filter(Boolean) as string[];

    url += applyFilters(filters);

    // fullfil the url format when no any filters or size config
    if (url.endsWith('/m')) {
        url += '/';
    }
    return url;
}

function buildLowFiUrl(originalPath: string, opt: Image = {}): string {
    const width = 20;
    let height: undefined | number;

    // Compat case when croping with different aspect ratio
    if (opt.height && opt.width) {
        height = Math.round((opt.height / opt.width) * width);
    }

    return buildImageUrl(originalPath, {
        width,
        height,
        quality: opt.fallbackQuality,
        fitIn: opt.fitIn,
        focus: opt.focus,
    });
}

function getStoryblokImageGatsbyImageData(
    imageRaw: string,
    args: GetGatsbyImageOptions
): IGatsbyImageData | null {
    if (imageRaw.endsWith('.svg')) {
        return null;
    }
    // reset when url containing image config
    const image = imageRaw.replace(/\/m\/[^.]*$/, '');

    const imageProps = getBasicImageProps(image);

    // url not identified as Storyblok image
    if (!imageProps) {
        return null;
    }

    const options: MergedGetGatsbyImageOptions = {
        ...defaultOptions,
        ...args,
    };
    const {
        metadata: { dimensions },
        originalPath,
    } = imageProps;

    const width = options.width ?? 0;
    const height = options.height ?? 0;

    const desiredAspectRatio = width && height ? width / height : dimensions.aspectRatio;

    let outputWidth: number;
    let outputHeight: number;
    let sizes: string;

    // Get output width and height

    switch (true) {
        case options.layout === 'fullWidth':
            outputWidth = desiredAspectRatio;
            outputHeight = 1;
            break;
        case width && !height:
            outputWidth = width;
            outputHeight = Math.round(width / desiredAspectRatio);
            break;
        case Boolean(!width && height):
            outputWidth = Math.round(height * desiredAspectRatio);
            outputHeight = height;
            break;
        case Boolean(width && height):
            outputWidth = width;
            outputHeight = height;
            break;
        case !width && !height:
        default:
            outputWidth = dimensions.width;
            outputHeight = dimensions.height;
            break;
    }

    switch (options.layout) {
        case 'constrained':
            sizes = `(min-width: ${outputWidth}px) ${outputWidth}px, 100vw`;
            break;
        case 'fullWidth':
            sizes = '100vw';
            break;
        case 'fixed':
        default:
            sizes = `${outputWidth}px`;
            break;
    }

    const widths = getWidths(options, outputWidth, dimensions.width);
    const srcSetArr = widths.map(currentWidth => {
        const resolution = `${currentWidth}w`;

        const currentHeight = Math.round(currentWidth / desiredAspectRatio);

        // Workaround for Storyblok has bug when crop with width larger than 4000 (eg: crop 5000 will get 4000)
        // Not to set crop when size is same to source
        const sameToSource =
            currentWidth === dimensions.width && currentHeight === dimensions.height;

        const url = buildImageUrl(originalPath, {
            ...options,
            width: sameToSource ? undefined : currentWidth,
            height: sameToSource ? undefined : currentHeight,
        });

        return `${url} ${resolution}`;
    });
    const src = buildImageUrl(originalPath, { ...options, width, height });

    return {
        layout: options.layout,
        placeholder: {
            sources: [],
            fallback: options.fallback === false ? undefined : buildLowFiUrl(originalPath, options),
        },
        backgroundColor: options.backgroundColor ?? 'transparent',
        images: {
            fallback: {
                src,
                srcSet: srcSetArr.join(',\n') ?? undefined,
                sizes,
            },
            sources: [],
        },
        width: outputWidth,
        height: outputHeight,
    };
}

export default getStoryblokImageGatsbyImageData;
