import { Translations } from '../hooks/useTranslations';
import { CaseFormArgs, CaseFormConfig } from './caseForm';
import worldCountries, { getCountryInfo } from './worldCountries';

const countryOptions = worldCountries.map(ct => ({
    value: ct.name,
    label: ct.name,
}));

const getTypeOptions = (translations: Translations['salesForceForm']) => {
    const options = [
        {
            value: '',
            label: translations.typePlaceHolder,
        },
        {
            value: 'Information Request',
            label: translations.typeInformationRequest,
        },
        {
            value: 'Order-,delivery- or invoice',
            label: translations.typeOrderDeliveryOrInvoice,
        },
        {
            value: 'Shipment and Transport',
            label: translations.typeShipmentAndTransport,
        },
        {
            value: 'Warranty Claims',
            label: translations.typeWarrantyClaims,
        },
        {
            value: 'Warranty Claims',
            label: translations.typeWarrantyReporting,
        },
        {
            value: 'Dealer-Service',
            label: translations.typeDealerService,
        },
        {
            value: 'Suggestion Box',
            label: translations.typeSuggestionBox,
        },
        {
            value: 'Requests',
            label: translations.typeRequests,
        },
    ];

    return options.filter(Boolean);
};

const getHaibikeCaseFormConfig = ({ country, translations }: CaseFormArgs): CaseFormConfig => ({
    brand: 'Haibike',
    webSource: 'Haibike Website',
    captchaKey: 'Haibike',
    fields: [
        {
            id: 'type',
            salesforceId: 'type',
            type: 'dropdown',
            options: getTypeOptions(translations),
            validation: {
                required: true,
                requiredMessage: translations.requiredType,
            },
        },
        {
            id: 'subject',
            salesforceId: 'subject',
            type: 'text',
            hideValidation: true,
            validation: {
                required: true,
                requiredMessage: translations.requiredSubject,
            },
        },
        {
            id: 'description',
            salesforceId: 'description',
            type: 'textarea',
            hideValidation: true,
            validation: {
                required: true,
                requiredMessage: translations.requiredDescription,
            },
        },
        {
            id: 'firstName',
            salesforceId: '00N0X00000AkIVn',
            type: 'text',
            hideValidation: true,
            validation: {
                required: true,
                requiredMessage: translations.requiredFirstName,
            },
        },
        {
            id: 'lastName',
            salesforceId: '00N0X00000AkIVo',
            type: 'text',
            hideValidation: true,
            validation: {
                required: true,
                requiredMessage: translations.requiredLastName,
            },
        },
        {
            id: 'email',
            salesforceId: 'email',
            type: 'text',
            inputMode: 'email',
            hideValidation: true,
            validation: {
                required: true,
                requiredMessage: translations.requiredEmail,
                email: true,
                emailMessage: translations.validEmail,
            },
        },
        {
            id: 'phone',
            salesforceId: 'phone',
            type: 'text',
            inputMode: 'tel',
            autoComplete: 'tel',
            hideValidation: true,
        },
        {
            id: 'country',
            salesforceId: '00N0X00000AjXxu',
            type: 'dropdown',
            options: countryOptions,
            defaultValue: getCountryInfo(country)?.name,
            validation: {
                required: true,
                requiredMessage: translations.validCountry,
            },
        },
    ],
});

export default getHaibikeCaseFormConfig;
