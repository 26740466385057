// Generated file, do not edit.
// Edit the json files in the designTokens directory and run the build-design-tokens.js script.

export default {
    optionsBrandColorBlack: '#000000',
    optionsBrandColorBlack2: '#1A1A1A',
    optionsBrandColorWhite: '#fff',
    optionsBrandColorSand: '#dfd8d4',
    optionsBrandColorOrange: '#ff6b00',
    optionsBrandColorGrey: '#808080',
    optionsBrandColorYellow: '#e4fd01',
    optionsBrandColorLightGrey: '#EFEFEF',
    optionsBrandColorSand1: '#e9e3de',
    optionsBrandColorOrange1: '#eb5700',
    optionsBrandColorSecondary1: '#9e9e9e',
    optionsBrandColorYellow1: '#98A900',
    optionsBrandColorDarkBlue: '#060F16',
    optionsNeutralColorBlack: '#000',
    optionsNeutralColorGrey900: '#121212',
    optionsNeutralColorGrey800: '#181818',
    optionsNeutralColorGrey750: '#1E1e1e',
    optionsNeutralColorGrey700: '#424547',
    optionsNeutralColorGrey600: '#626667',
    optionsNeutralColorGrey500: '#727272',
    optionsNeutralColorGrey400: '#888C8D',
    optionsNeutralColorGrey300: '#B3B5B5',
    optionsNeutralColorGrey200: '#D1D4D2',
    optionsNeutralColorGrey100: '#f3f3f3',
    optionsNeutralColorGrey50: '#F8f8f8',
    optionsNeutralColorWhite: '#FFFFFF',
    optionsFormColorCheck: '#11B034',
    optionsStatusColorSuccessLight: '#F0FDF5',
    optionsStatusColorSuccessMain: '#11b034',
    optionsStatusColorSuccessDark: '#21A153',
    optionsStatusColorWarningLight: '#FEFCE9',
    optionsStatusColorWarningMain: '#ffb75d',
    optionsStatusColorWarningDark: '#975A12',
    optionsStatusColorErrorLight: '#FEF3F2',
    optionsStatusColorErrorMain: '#BE3C3C',
    optionsStatusColorErrorDark: '#AE201A',
    optionsStatusColorInfoLight: '#EFF6FF',
    optionsStatusColorInfoMain: '#0073BE',
    optionsStatusColorInfoDark: '#1244CA',
    optionsFontFamilySansSerifOne: 'Input Mono',
    optionsFontFamilySansSerifTwo: 'Druk',
    optionsFontFamilySansSerifThree: 'Druk',
    optionsFontSizesHeadlineSizeOneSmall: '4rem',
    optionsFontSizesHeadlineSizeOneMedium: '8rem',
    optionsFontSizesHeadlineSizeTwoSmall: '3.2rem',
    optionsFontSizesHeadlineSizeTwoMedium: '6.4rem',
    optionsFontSizesHeadlineSizeThreeSmall: '2.4rem',
    optionsFontSizesHeadlineSizeThreeMedium: '4.8rem',
    optionsFontSizesHeadlineSizeFourSmall: '2rem',
    optionsFontSizesHeadlineSizeFourMedium: '2.2rem',
    optionsFontSizesHeadlineSizeFiveSmall: '1.8rem',
    optionsFontSizesHeadlineSizeFiveMedium: '2rem',
    optionsFontSizesHeadlineSizeSixSmall: '1.6rem',
    optionsFontSizesHeadlineSizeSixMedium: '1.8rem',
    optionsFontSizesOverlayTitleExtraSmall: '2.2rem',
    optionsFontSizesOverlayTitleSmall: '4rem',
    optionsFontSizesOverlayTitleMedium: '5.6rem',
    optionsFontSizesOverlayTitleLarge: '7.2rem',
    optionsFontSizesBodyExtraSmall: '1.2rem',
    optionsFontSizesBodySmall: '1.4rem',
    optionsFontSizesBodyMedium: '1.6rem',
    optionsFontSizesBodyLarge: '1.8rem',
    optionsFontSizesBodyExtraLarge: '2.4rem',
    optionsFontWeightLight: '300',
    optionsFontWeightRegular: '400',
    optionsFontWeightBold: '700',
    optionsLineHeightsExtraSmall: '0.875',
    optionsLineHeightsSmall: '1',
    optionsLineHeightsMedium: '1',
    optionsLineHeightsLarge: '1.1',
    optionsTextTransformNormal: 'none',
    optionsTextTransformLowercase: 'lowercase',
    optionsTextTransformUppercase: 'uppercase',
    optionsLetterSpacingNormal: '0',
    optionsLetterSpacingWide: '.075em',
    optionsLetterSpacingMediumWide: '.1em',
    optionsLetterSpacingExtraWide: '.125em',
    optionsTextDecorationNone: 'none',
    optionsTextDecorationUnderline: 'underline',
    optionsParagraphIndentNone: '0',
    optionsParagraphSpacingNone: '0',
    optionsMediaQueryXs: 576,
    optionsMediaQueryS: 768,
    optionsMediaQueryM: 992,
    optionsMediaQueryL: 1200,
    optionsMediaQueryXl: 1600,
    additionalMotionEaseOut: 'cubic-bezier(0, 0, 0.25, 1)',
    additionalMotionEaseIn: 'cubic-bezier(0.50, 0, 1, 1)',
    additionalMotionEaseInOut: 'cubic-bezier(0.25, 0.75, 0.25, 0.75)',
    additionalCarouselMixBlendMode: 'multiply',
    additionalSmartfitCtaTextColor: '#FFFFFF',
    additionalSmartfitPrimaryColor: '#000000',
    additionalProductDescriptionTextColor: 'inherit',
    additionalProductDescriptionBackgroundColor: 'transparent',
    additionalKeyFeatureListSupertitleBorderColor: 'transparent',
    breakpointExtraSmall: 576,
    breakpointSmall: 768,
    breakpointMedium: 992,
    breakpointLarge: 1200,
    breakpointExtraLarge: 1600,
    fontTitleFamily: 'Druk',
    fontTitleWeight: '700',
    fontTitleTransform: 'uppercase',
    fontTitleLetterSpacing: '0',
    fontTitleHighlightFamily: 'Druk',
    fontTitleHighlightWeight: '700',
    fontTitleHighlightTransform: 'uppercase',
    fontTitleHighlightLetterSpacing: '0',
    fontHeadlineOneFamily: 'Druk',
    fontHeadlineOneWeight: '700',
    fontHeadlineOneTransform: 'none',
    fontHeadlineOneLetterSpacing: '0',
    fontHeadlineTwoFamily: 'Druk',
    fontHeadlineTwoWeight: '700',
    fontHeadlineTwoTransform: 'uppercase',
    fontHeadlineTwoLetterSpacing: '0',
    fontHeadlineThreeFamily: 'Druk',
    fontHeadlineThreeWeight: '700',
    fontHeadlineThreeTransform: 'uppercase',
    fontHeadlineThreeLetterSpacing: '0',
    fontSubHeadlineFamily: 'Input Mono',
    fontSubHeadlineWeight: '700',
    fontSubHeadlineTransform: 'none',
    fontSubHeadlineLetterSpacing: '0',
    fontTitleOverlayFamily: 'Druk',
    fontTitleOverlayWeight: '400',
    fontTitleOverlayTransform: 'none',
    fontTitleOverlayLetterSpacing: '0',
    fontMainNavItemFamily: 'Input Mono',
    fontMainNavItemWeight: '700',
    fontMainNavItemTransform: 'none',
    fontMainNavItemLetterSpacing: '0',
    fontBodyFamily: 'Input Mono',
    fontBodyWeight: '400',
    fontBodyTransform: 'none',
    fontBodyLetterSpacing: '0',
    fontCaptionFamily: 'Input Mono',
    fontCaptionWeight: '400',
    fontCaptionTransform: 'none',
    fontCaptionLetterSpacing: '0',
    fontButtonFamily: 'Input Mono',
    fontButtonWeight: '700',
    fontButtonWeightSmall: '400',
    fontButtonTransform: 'none',
    fontButtonLetterSpacing: '0',
    fontLinkFamily: 'Input Mono',
    fontLinkWeight: '400',
    fontLinkTransform: 'none',
    fontLinkLetterSpacing: '0',
    fontPriceFamily: 'Input Mono',
    fontPriceWeight: '400',
    fontPriceTransform: 'none',
    fontPriceLetterSpacing: '0',
    fontHeroTitleLetterSpacing: '0',
    fontLineHeightExtraSmall: '0.875',
    fontLineHeightSmall: '1',
    fontLineHeightMedium: '1',
    fontLineHeightLarge: '1.1',
    fontPlpCategoryLabelsTextTransform: 'none',
    fontPlpCategoryLabelsFontWeight: '400',
    fontPlpUpillSortingTextTransform: 'none',
    fontPlpUpillSortingFontWeight: '400',
    sizeTextHeadlineSizeOneSmall: '4rem',
    sizeTextHeadlineSizeOneMedium: '8rem',
    sizeTextHeadlineSizeTwoSmall: '3.2rem',
    sizeTextHeadlineSizeTwoMedium: '6.4rem',
    sizeTextHeadlineSizeThreeSmall: '2.4rem',
    sizeTextHeadlineSizeThreeMedium: '4.8rem',
    sizeTextHeadlineSizeFourSmall: '2rem',
    sizeTextHeadlineSizeFourMedium: '2.2rem',
    sizeTextHeadlineSizeFiveSmall: '1.8rem',
    sizeTextHeadlineSizeFiveMedium: '2rem',
    sizeTextHeadlineSizeSixSmall: '1.6rem',
    sizeTextHeadlineSizeSixMedium: '1.8rem',
    sizeTextOverlayTitleExtraSmall: 'calc((1rem + 0.25vw) * 2.2)',
    sizeTextOverlayTitleSmall: 'calc((1rem + 0.25vw) * 4)',
    sizeTextOverlayTitleMedium: 'calc((1rem + 0.25vw) * 5.6)',
    sizeTextOverlayTitleLarge: 'calc((1rem + 0.25vw) * 7.2)',
    sizeTextBodyExtraSmall: '1.2rem',
    sizeTextBodySmall: '1.4rem',
    sizeTextBodyMedium: '1.6rem',
    sizeTextBodyLarge: '1.8rem',
    sizeTextBodyExtraLarge: '2.4rem',
    sizeGridSpacingBlockMedium: '4rem',
    sizeGridSpacingBlockLarge: '8rem',
    sizeGridSpacingRem2: '0.2rem',
    sizeGridSpacingRem4: '0.4rem',
    sizeGridSpacingRem6: '0.6rem',
    sizeGridSpacingRem8: '0.8rem',
    sizeGridSpacingRem10: '1rem',
    sizeGridSpacingRem12: '1.2rem',
    sizeGridSpacingRem14: '1.4rem',
    sizeGridSpacingRem16: '1.6rem',
    sizeGridSpacingRem20: '2rem',
    sizeGridSpacingRem24: '2.4rem',
    sizeGridSpacingRem28: '2.8rem',
    sizeGridSpacingRem32: '3.2rem',
    sizeGridSpacingRem36: '3.6rem',
    sizeGridSpacingRem40: '4rem',
    sizeGridSpacingRem44: '4.4rem',
    sizeGridSpacingRem48: '4.8rem',
    sizeGridSpacingRem52: '5.2rem',
    sizeGridSpacingRem56: '5.6rem',
    sizeGridSpacingRem60: '6rem',
    sizeGridSpacingRem64: '6.4rem',
    sizeGridLayoutRem24: '2.4rem',
    sizeGridLayoutRem32: '3.2rem',
    sizeGridLayoutRem40: '4rem',
    sizeGridLayoutRem48: '4.8rem',
    sizeGridLayoutRem56: '5.6rem',
    sizeGridLayoutRem64: '6.4rem',
    sizeGridLayoutRem72: '7.2rem',
    sizeGridLayoutRem80: '8rem',
    sizeGridLayoutRem88: '8.8rem',
    sizeGridLayoutRem96: '9.6rem',
    sizeGridLayoutRem104: '10.4rem',
    sizeGridLayoutRem120: '12rem',
    sizeGridLayoutMaxWidthXxs: '32rem',
    sizeGridLayoutMaxWidthXs: '37.5rem',
    sizeGridLayoutMaxWidthS: '37.5rem',
    sizeGridLayoutMaxWidthM: '61.6rem',
    sizeGridLayoutMaxWidthL: '86.4rem',
    sizeGridLayoutMaxWidthXl: '160rem',
    sizeGridLayoutMaxWidthXxl: '160rem',
    sizeOtherIconXxs: '1.2rem',
    sizeOtherIconXs: '1.6rem',
    sizeOtherIconS: '2rem',
    sizeOtherIconM: '2.4rem',
    sizeOtherIconL: '3.2rem',
    sizeOtherIconXl: '4rem',
    sizeOtherBorderRadiusS: '0rem',
    sizeOtherBorderRadiusM: '0rem',
    sizeOtherBorderRadiusL: '0.8rem',
    sizeOtherBorderRadiusFull: '99rem',
    sizeOtherBorderRadiusButton: '0rem',
    sizeOtherBorderWidthS: '0.1rem',
    sizeOtherBorderWidthXs: '0.1rem',
    sizeOtherLogoWidth: '15.8rem',
    sizeOtherLogoHeight: '1.6rem',
    svgRatingsStarPath:
        'M5.29912851,4.57520277 L0.552479625,5.25223982 L0.47915698,5.26674626 C0.00513697679,5.38775526 -0.168956388,5.98274547 0.195319258,6.33131796 L3.63001442,9.61792143 L2.81928166,14.2590186 L2.81088573,14.3286826 C2.77905682,14.8103298 3.30304684,15.1588353 3.75433964,14.9259255 L8,12.7349048 L12.2456604,14.9259255 L12.3105574,14.9552915 C12.7673486,15.133845 13.2669076,14.7523294 13.1807183,14.2590186 L12.3693411,9.61792143 L15.8046807,6.33131796 L15.8553141,6.277274 C16.166349,5.90586074 15.9509369,5.3240497 15.4475204,5.25223982 L10.700227,4.57520277 L8.57789761,0.352651397 C8.34150615,-0.117550466 7.65849385,-0.117550466 7.42210239,0.352651397 L5.29912851,4.57520277 Z',
    svgRatingsStarViewBox: '0 0 16 15',
    svgRatingsStarRatedColor: '#F0CA00',
    svgRatingsStarEmptyColor: '#D1D4D2',
    svgRatingsStarWidgetSpacing: '0.2rem',
    svgQuoteIconPath:
        'M64.3644 5.34058e-05L62.1307 13.0398C61.4868 16.7425 60.1385 20.5256 58.086 24.3893C55.9932 28.2529 53.4778 31.8952 50.5398 35.3161C47.5616 38.737 44.4626 41.5945 41.2429 43.8885L31.1009 37.1876C33.9584 33.6459 36.4335 29.9432 38.5263 26.0796C40.6191 22.216 42.0479 17.9096 42.8125 13.1606L45.0462 5.34058e-05H64.3644ZM33.8779 5.34058e-05L31.6442 13.0398C31.0003 16.7425 29.652 20.5256 27.5995 24.3893C25.5067 28.2529 22.9913 31.8952 20.0533 35.3161C17.0751 38.737 13.9761 41.5945 10.7564 43.8885L0.614384 37.1876C3.47186 33.6459 5.947 29.9432 8.03981 26.0796C10.1326 22.216 11.5613 17.9096 12.326 13.1606L14.5597 5.34058e-05H33.8779Z',
    svgQuoteIconViewBox: '0 0 65 44',
    svgQuoteIconWidth: '65',
    svgQuoteIconHeight: '44',
    svgQuoteIconFill: '#000',
    svgMyAccountPath:
        'M5.517,6.252q.338.364.628.7l4.656.549,5.63,2.072V14.16c0,1.871-5.61,3.339-7.184,3.7L4.83,16.236c-.193.237-.364.449-.5.623L5,18.628,6.462,21.6c3.514-.962,10.711-2.552,13.606-4.572,2.76-1.926,3.481-5.095,2.962-8.277-1.037-6.358-3.978-7.509-10.157-7.305C9.666,1.546,4.617,3,1.529,4c0,.247.035.385.035.493,1.935.039,2.035.113,3.97.176M7.29,8.525a4.555,4.555,0,0,1,.663,1.95c.141,1.782-1.079,3.317-2.161,4.6L9.341,16.38c1.037-.248,5.665-1.4,5.665-2.221V10.57L10.469,8.9,7.29,8.525Zm-3.2-2.54c-.961-.031-1.915-.076-2.878-.076a5.252,5.252,0,0,1-1.07-.044C.1,5.143,0,3.908,0,3.145c0-.12.653-.361,1.095-.5C4.325,1.6,9.468.131,12.828.02c6.923-.228,10.454,1.438,11.606,8.5.61,3.743-.324,7.418-3.552,9.67C17.39,20.624,10,22.1,5.7,23.276L2.535,16.828c1.164-1.5,4.136-4.474,4-6.241C6.424,9.243,4.979,7.752,4.091,6.813V5.985Z',
    svgMyAccountViewBox: '0 0 24.609 23.275',
    svgMyAccountWidth: '24.609',
    svgMyAccountHeight: '23.275',
    svgMyAccountFill: 'currentColor',
    svgCartPath:
        'M19.896,7.79423077 L15.44,0 L13.36,1.18730769 L17.136,7.79423077 L6.864,7.79423077 L10.64,1.18730769 L8.56,0 L4.104,7.79423077 L0,7.79423077 L0,10.2173077 L1.44,10.2173077 L3.632,20.0630769 C3.75840467,20.611453 4.24233195,20.9996402 4.8,21 L19.2,21 C19.7576681,20.9996402 20.2415953,20.611453 20.368,20.0630769 L22.56,10.2011538 L24,10.2011538 L24,7.77807692 L19.896,7.79423077 Z M8.4,17.3976923 L6,17.3976923 L6,11.3965385 L8.4,11.3965385 L8.4,17.3976923 Z M13.2,17.3976923 L10.8,17.3976923 L10.8,11.3965385 L13.2,11.3965385 L13.2,17.3976923 Z M18,17.3976923 L15.6,17.3976923 L15.6,11.3965385 L18,11.3965385 L18,17.3976923 Z',
    svgCartViewBox: '0 0 24 21',
    svgCartWidth: '24',
    svgCartHeight: '21',
    svgCartFill: 'currentColor',
    svgSearchPath:
        'M6.47619048,0 C10.0528917,0 12.952381,2.89948924 12.952381,6.47619048 C12.952381,8.08184217 12.3680501,9.55101606 11.4004383,10.6826621 C11.6006024,10.6451975 11.8114518,10.6783226 11.9945136,10.782419 L12.1007085,10.8533965 L12.1840949,10.9270162 L15.7396505,14.4825718 C16.0867832,14.8297045 16.0867832,15.3925178 15.7396505,15.7396505 C15.4507072,16.0285937 15.0081634,16.079327 14.6517434,15.8690295 L14.5476409,15.7980865 L14.4825718,15.7396505 L10.9270162,12.1840949 C10.713477,11.9705557 10.6312967,11.6754005 10.6804755,11.3990417 C9.55101606,12.3680501 8.08184217,12.952381 6.47619048,12.952381 C2.89948924,12.952381 0,10.0528917 0,6.47619048 C0,2.89948924 2.89948924,0 6.47619048,0 Z M6.47619048,1.77777778 C3.88132879,1.77777778 1.77777778,3.88132879 1.77777778,6.47619048 C1.77777778,9.07105216 3.88132879,11.1746032 6.47619048,11.1746032 C9.07105216,11.1746032 11.1746032,9.07105216 11.1746032,6.47619048 C11.1746032,3.88132879 9.07105216,1.77777778 6.47619048,1.77777778 Z',
    svgSearchViewBox: '0 0 16 16',
    svgSearchWidth: '16',
    svgSearchHeight: '16',
    themeColorsPrimaryHeaderNavLink: '#fff',
    themeColorsPrimaryHeaderNavLinkHover: '#000000',
    themeColorsPrimaryHeaderNavLinkActive: '#000000',
    themeColorsPrimaryHeaderNavLinkBackground: 'unset',
    themeColorsPrimaryHeaderNavLinkHoverBackground: '#fff',
    themeColorsPrimaryHeaderNavLinkActiveBackground: '#fff',
    themeColorsPrimaryBackgroundColor: '#000000',
    themeColorsPrimaryBackgroundColorSecondary: '#000000',
    themeColorsPrimaryTextColor: '#fff',
    themeColorsPrimaryTextColorSecondary: '#e4fd01',
    themeColorsPrimaryHeroQuoteTextColor: '#fff',
    themeColorsPrimaryBorderDividerColor: '#424547',
    themeColorsPrimaryButtonBackgroundColor: '#e4fd01',
    themeColorsPrimaryButtonBackgroundColorHover: '#98A900',
    themeColorsPrimaryButtonTextColor: '#000000',
    themeColorsPrimaryButtonTextColorHover: '#000000',
    themeColorsSecondaryHeaderNavLink: '#000000',
    themeColorsSecondaryHeaderNavLinkHover: '#fff',
    themeColorsSecondaryHeaderNavLinkActive: '#fff',
    themeColorsSecondaryHeaderNavLinkBackground: 'unset',
    themeColorsSecondaryHeaderNavLinkHoverBackground: '#000000',
    themeColorsSecondaryHeaderNavLinkActiveBackground: '#000000',
    themeColorsSecondaryBackgroundColor: '#fff',
    themeColorsSecondaryBackgroundColorSecondary: '#fff',
    themeColorsSecondaryTextColor: '#000000',
    themeColorsSecondaryTextColorSecondary: '#000000',
    themeColorsSecondaryHeroQuoteTextColor: '#000000',
    themeColorsSecondaryBorderDividerColor: '#B3B5B5',
    themeColorsSecondaryButtonBackgroundColor: '#000000',
    themeColorsSecondaryButtonBackgroundColorHover: '#1A1A1A',
    themeColorsSecondaryButtonTextColor: '#fff',
    themeColorsSecondaryButtonTextColorHover: '#fff',
    themeColorsTertiaryHeaderNavLink: '#000000',
    themeColorsTertiaryHeaderNavLinkHover: '#fff',
    themeColorsTertiaryHeaderNavLinkActive: '#fff',
    themeColorsTertiaryHeaderNavLinkBackground: 'unset',
    themeColorsTertiaryHeaderNavLinkHoverBackground: '#000000',
    themeColorsTertiaryHeaderNavLinkActiveBackground: '#000000',
    themeColorsTertiaryBackgroundColor: '#808080',
    themeColorsTertiaryBackgroundColorSecondary: '#808080',
    themeColorsTertiaryTextColor: '#000000',
    themeColorsTertiaryTextColorSecondary: '#e4fd01',
    themeColorsTertiaryHeroQuoteTextColor: '#000000',
    themeColorsTertiaryBorderDividerColor: '#626667',
    themeColorsTertiaryButtonBackgroundColor: '#000000',
    themeColorsTertiaryButtonBackgroundColorHover: '#1A1A1A',
    themeColorsTertiaryButtonTextColor: '#fff',
    themeColorsTertiaryButtonTextColorHover: '#fff',
    themeColorsQuaternaryHeaderNavLink: '#000000',
    themeColorsQuaternaryHeaderNavLinkHover: '#fff',
    themeColorsQuaternaryHeaderNavLinkActive: '#fff',
    themeColorsQuaternaryHeaderNavLinkBackground: 'unset',
    themeColorsQuaternaryHeaderNavLinkHoverBackground: '#000000',
    themeColorsQuaternaryHeaderNavLinkActiveBackground: '#000000',
    themeColorsQuaternaryBackgroundColor: '#e4fd01',
    themeColorsQuaternaryBackgroundColorSecondary: '#e4fd01',
    themeColorsQuaternaryTextColor: '#000000',
    themeColorsQuaternaryTextColorSecondary: '#000000',
    themeColorsQuaternaryHeroQuoteTextColor: '#000000',
    themeColorsQuaternaryBorderDividerColor: '#888C8D',
    themeColorsQuaternaryButtonBackgroundColor: '#000000',
    themeColorsQuaternaryButtonBackgroundColorHover: '#1A1A1A',
    themeColorsQuaternaryButtonTextColor: '#fff',
    themeColorsQuaternaryButtonTextColorHover: '#fff',
    themeColorsQuinaryHeaderNavLink: '#fff',
    themeColorsQuinaryHeaderNavLinkHover: '#000000',
    themeColorsQuinaryHeaderNavLinkActive: '#000000',
    themeColorsQuinaryHeaderNavLinkBackground: 'unset',
    themeColorsQuinaryHeaderNavLinkHoverBackground: '#fff',
    themeColorsQuinaryHeaderNavLinkActiveBackground: '#fff',
    themeColorsQuinaryBackgroundColor: '#060F16',
    themeColorsQuinaryBackgroundColorSecondary: '#060F16',
    themeColorsQuinaryTextColor: '#fff',
    themeColorsQuinaryTextColorSecondary: '#e4fd01',
    themeColorsQuinaryHeroQuoteTextColor: '#fff',
    themeColorsQuinaryBorderDividerColor: '#424547',
    themeColorsQuinaryButtonBackgroundColor: '#e4fd01',
    themeColorsQuinaryButtonBackgroundColorHover: '#98A900',
    themeColorsQuinaryButtonTextColor: '#060F16',
    themeColorsQuinaryButtonTextColorHover: '#060F16',
    themeColorsSenaryHeaderNavLink: '#000000',
    themeColorsSenaryHeaderNavLinkHover: '#fff',
    themeColorsSenaryHeaderNavLinkActive: '#fff',
    themeColorsSenaryHeaderNavLinkBackground: 'unset',
    themeColorsSenaryHeaderNavLinkHoverBackground: '#000000',
    themeColorsSenaryHeaderNavLinkActiveBackground: '#000000',
    themeColorsSenaryBackgroundColor: '#dfd8d4',
    themeColorsSenaryBackgroundColorSecondary: '#dfd8d4',
    themeColorsSenaryTextColor: '#000000',
    themeColorsSenaryTextColorSecondary: '#000000',
    themeColorsSenaryHeroQuoteTextColor: '#000000',
    themeColorsSenaryBorderDividerColor: '#B3B5B5',
    themeColorsSenaryButtonBackgroundColor: '#000000',
    themeColorsSenaryButtonBackgroundColorHover: '#000000',
    themeColorsSenaryButtonTextColor: '#fff',
    themeColorsSenaryButtonTextColorHover: '#fff',
    lightTheme: {
        actionPrimaryText: '#FFFFFF',
        actionPrimaryTextHover: '#FFFFFF',
        actionPrimaryBackground: '#000000',
        actionPrimaryBackgroundHover: '#424547',
        actionPrimaryBorder: 'transparent',
        actionPrimaryBorderHover: 'transparent',
        actionSecondaryText: '#FFFFFF',
        actionSecondaryTextHover: '#FFFFFF',
        actionSecondaryBackground: '#808080',
        actionSecondaryBackgroundHover: '#9e9e9e',
        actionSecondaryBorder: 'transparent',
        actionSecondaryBorderHover: 'transparent',
        actionTertiaryText: '#424547',
        actionTertiaryTextHover: '#424547',
        actionTertiaryBackground: '#e4fd01',
        actionTertiaryBackgroundHover: '#98A900',
        actionTertiaryBorder: 'transparent',
        actionTertiaryBorderHover: 'transparent',
        actionMonochromeText: '#FFFFFF',
        actionMonochromeBackground: '#424547',
        actionMonochromeBackgroundHover: '#727272',
        actionMonochromeBorder: 'transparent',
        actionMonochromeBorderHover: 'transparent',
        actionSecondaryWhiteText: '#000',
        actionSecondaryWhiteTextHover: '#000',
        actionSecondaryWhiteBackground: '#e4fd01',
        actionSecondaryWhiteBackgroundHover: '#98A900',
        actionSecondaryWhiteBorder: 'transparent',
        actionSecondaryWhiteBorderHover: 'transparent',
        actionSecondaryBlackText: '#FFFFFF',
        actionSecondaryBlackTextHover: '#FFFFFF',
        actionSecondaryBlackBackground: '#000',
        actionSecondaryBlackBackgroundHover: '#181818',
        actionSecondaryBlackBorder: 'transparent',
        actionSecondaryBlackBorderHover: 'transparent',
        actionOutlineText: '#000',
        actionOutlineTextHover: '#000',
        actionOutlineBackground: 'transparent',
        actionOutlineBackgroundHover: 'transparent',
        actionOutlineBorder: '#888C8D',
        actionOutlineBorderHover: '#424547',
        actionTextLink: '#626667',
        actionTextLinkHover: '#181818',
        actionTextLinkBorder: '#D1D4D2',
        actionHeadlineLink: '#000',
        actionHeadlineLinkHover: '#181818',
        actionNavLink: '#000',
        actionNavLinkBackground: '#424547',
        actionNavLinkHover: '#FFFFFF',
        actionNavLinkHoverBackground: '#424547',
        actionNavLinkActive: '#FFFFFF',
        actionNavLinkActiveBackground: '#424547',
        actionDisabledText: '#FFFFFF',
        actionDisabledBackground: '#B3B5B5',
        actionDisabledBackgroundHover: '#B3B5B5',
        actionDisabledBorder: '#B3B5B5',
        actionDisabledBorderHover: '#B3B5B5',
        actionRadioSquareBackground: '#FFFFFF',
        actionRadioSquareActiveBackground: '#FFFFFF',
        actionRadioSquareColor: '#000',
        actionRadioSquareActiveColor: '',
        textTitle: '#000',
        textHeadline: '#000',
        textSubHeadline: '#000',
        textTitleOverlay: '#e4fd01',
        textBody: '#000',
        textCaption: '#888C8D',
        textPrice: '#000',
        formInputLabelText: '#424547',
        formInputFocusLabelText: '#424547',
        formInputInputText: '#424547',
        formInputInputTextDisabled: '#626667',
        formInputPlaceholderText: '#888C8D',
        formInputBorder: '#888C8D',
        formInputBorderFocus: '#424547',
        formInputBorderError: '#BE3C3C',
        formInputCheckbox: '#11B034',
        formInputBackground: '#FFFFFF',
        formInputBackgroundSecondary: '#FFFFFF',
        formInputBorderSecondary: '#888C8D',
        formInputBackgroundDisabled: '#F8f8f8',
        formRadioEnabled: '#424547',
        formRadioDisabled: '#B3B5B5',
        formRadioBorderDisabled: '#D1D4D2',
        formRadioBorderEnabled: '#B3B5B5',
        formRadioBorderActive: '#000',
        formListItemBackground: '#f3f3f3',
        formListItemBackgroundHover: '#f3f3f3',
        formSliderVerticalBars: '#B3B5B5',
        formSliderHandleBackground: '#FFFFFF',
        formSliderHandleBorder: '#B3B5B5',
        formSliderProgressBar: '#e4fd01',
        formStatusErrorLight: '#FEF3F2',
        formStatusErrorMain: '#BE3C3C',
        formStatusErrorDark: '#AE201A',
        formStatusSuccessLight: '#F0FDF5',
        formStatusSuccessMain: '#11b034',
        formStatusSuccessDark: '#21A153',
        formStatusWarningLight: '#FEFCE9',
        formStatusWarningMain: '#ffb75d',
        formStatusWarningDark: '#975A12',
        formStatusInfoLight: '#EFF6FF',
        formStatusInfoMain: '#0073BE',
        formStatusInfoDark: '#1244CA',
        headerBackground: '#FFFFFF',
        headerStickyBorderBottom: '#D1D4D2',
        headerStickyBoxShadow: 'undefined',
        headerStickyBoxShadowNoNotification: 'undefined',
        headerStickyBackground: '#FFFFFF',
        headerStickyBackgroundScrolled: '#FFFFFF',
        headerStickyBorderBottomScrolled: '#D1D4D2',
        headerSearchInputBorder: '#D1D4D2',
        headerMegamenu: '#F8f8f8',
        headerPlpSidebarTitle: '#000',
        footerBackground: '#FFFFFF',
        footerTextColor: '#000',
        footerHeadingColor: '',
        tableThead: '#F8f8f8',
        tableTh: '#F8f8f8',
        tableTd: '#FFFFFF',
        iconPrimary: '#000',
        iconSecondary: '#727272',
        iconTertiary: '#424547',
        iconLoader: '#FFFFFF',
        iconRoundedBackground: '#f3f3f3',
        iconRoundedIcon: '#424547',
        iconRoundedBorder: '#424547',
        iconRoundedActiveBackground: '#424547',
        iconRoundedActiveIcon: '#f3f3f3',
        iconRoundedActiveBorder: '#424547',
        logo: '#000',
        borderDivider1: '#D1D4D2',
        borderDivider2: '#D1D4D2',
        pillActiveTextColor: '#FFFFFF',
        pillActiveTextColorHover: '#FFFFFF',
        pillActiveBorderColor: '#000',
        pillActiveBorderColorHover: '#000',
        pillActiveBackground: '#000',
        pillActiveBackgroundHover: '#626667',
        pillDefaultTextColor: '#000',
        pillDefaultTextColorHover: '#000',
        pillDefaultBorderColor: '#D1D4D2',
        pillDefaultBorderColorHover: '#000',
        pillDefaultBackground: '#FFFFFF',
        pillDefaultBackgroundHover: '#FFFFFF',
        pillBadgeBackground: '#424547',
        pillBadgeBorderColorHover: '#888C8D',
        wrapperBody: '#FFFFFF',
        wrapperPublication: 'undefined',
        wrapperCategory: 'undefined',
        wrapperProduct: 'undefined',
        wrapperTileColor: 'undefined',
        wrapperTileColorHover: 'undefined',
        wrapperSection: '#e4fd01',
        wrapperBodyHover: '#F8f8f8',
        wrapperWell: '#FFFFFF',
        wrapperCard: '#f3f3f3',
        wrapperCardHover: '#F8f8f8',
        wrapperCardGradientColor: '#f3f3f3',
        wrapperCardGradientColorHover: '#F8f8f8',
        wrapperCardBorderColor: 'transparent',
        wrapperModal: '#888C8D',
        wrapperBanner: '#000',
        wrapperBannerBorder: '#000',
        wrapperUsp: '#F8f8f8',
        wrapperUspColor: '#000',
        oBikeComparisonCompareButtonBackground: '#f3f3f3',
        oBikeComparisonCompareButtonBorderColor: '#D1D4D2',
        uListItemHighlightColor: '#424547',
        uListItemTextColorHover: '#888C8D',
        uCarouselBackgroundColor: '#f3f3f3',
        uPullUpToggleBackground: '#000',
        uNewsletterTitleColor: '#FFFFFF',
        uKeyFeatureListBackgroundColor: '#EFEFEF',
        fallbackWhite: '#FFFFFF',
        fallbackGrey50: '#F8f8f8',
        fallbackGrey100: '#f3f3f3',
        fallbackGrey200: '#D1D4D2',
        fallbackGrey300: '#B3B5B5',
        fallbackGrey400: '#888C8D',
        fallbackGrey500: '#727272',
        fallbackGrey600: '#626667',
        fallbackGrey700: '#424547',
        fallbackGrey750: '#1E1e1e',
        fallbackGrey800: '#181818',
        fallbackGrey900: '#121212',
        fallbackBlack: '#000',
    },
    darkTheme: {
        actionPrimaryText: '#000',
        actionPrimaryTextHover: '#000',
        actionPrimaryBackground: '#e4fd01',
        actionPrimaryBackgroundHover: '#98A900',
        actionPrimaryBorder: 'transparent',
        actionPrimaryBorderHover: 'transparent',
        actionSecondaryText: '#000',
        actionSecondaryTextHover: '#000',
        actionSecondaryBackground: '#fff',
        actionSecondaryBackgroundHover: '#D1D4D2',
        actionSecondaryBorder: 'transparent',
        actionSecondaryBorderHover: 'transparent',
        actionTertiaryText: '#424547',
        actionTertiaryTextHover: '#424547',
        actionTertiaryBackground: '#e4fd01',
        actionTertiaryBackgroundHover: '#98A900',
        actionTertiaryBorder: 'transparent',
        actionTertiaryBorderHover: 'transparent',
        actionMonochromeText: '#424547',
        actionMonochromeBackground: '#000',
        actionMonochromeBackgroundHover: '#F8f8f8',
        actionMonochromeBorder: 'transparent',
        actionMonochromeBorderHover: 'transparent',
        actionSecondaryWhiteText: '#424547',
        actionSecondaryWhiteTextHover: '#424547',
        actionSecondaryWhiteBackground: '#000',
        actionSecondaryWhiteBackgroundHover: '#F8f8f8',
        actionSecondaryWhiteBorder: 'transparent',
        actionSecondaryWhiteBorderHover: 'transparent',
        actionSecondaryBlackText: '#000',
        actionSecondaryBlackTextHover: '#000',
        actionSecondaryBlackBackground: '#e4fd01',
        actionSecondaryBlackBackgroundHover: '#98A900',
        actionSecondaryBlackBorder: 'transparent',
        actionSecondaryBlackBorderHover: 'transparent',
        actionOutlineText: '#FFFFFF',
        actionOutlineTextHover: '#f3f3f3',
        actionOutlineBackground: 'transparent',
        actionOutlineBackgroundHover: 'transparent',
        actionOutlineBorder: '#FFFFFF',
        actionOutlineBorderHover: '#888C8D',
        actionTextLink: '#FFFFFF',
        actionTextLinkHover: '#f3f3f3',
        actionTextLinkBorder: '#f3f3f3',
        actionHeadlineLink: '#000',
        actionHeadlineLinkHover: '#f3f3f3',
        actionNavLink: '#F8f8f8',
        actionNavLinkBackground: '#000',
        actionNavLinkHover: '#000',
        actionNavLinkHoverBackground: '#000',
        actionNavLinkActive: '#000',
        actionNavLinkActiveBackground: '#000',
        actionDisabledText: '#000',
        actionDisabledBackground: '#B3B5B5',
        actionDisabledBackgroundHover: '#B3B5B5',
        actionDisabledBorder: '#B3B5B5',
        actionDisabledBorderHover: '#B3B5B5',
        actionRadioSquareBackground: '#000',
        actionRadioSquareActiveBackground: '#000',
        actionRadioSquareColor: '#000',
        actionRadioSquareActiveColor: '',
        textTitle: '#FFFFFF',
        textHeadline: '#FFFFFF',
        textSubHeadline: '#F8f8f8',
        textTitleOverlay: '#FFFFFF',
        textBody: '#FFFFFF',
        textCaption: '#F8f8f8',
        textPrice: '#F8f8f8',
        formInputLabelText: '#424547',
        formInputFocusLabelText: '#424547',
        formInputInputText: '#D1D4D2',
        formInputInputTextDisabled: '#626667',
        formInputPlaceholderText: '#888C8D',
        formInputBorder: '#424547',
        formInputBorderFocus: '#D1D4D2',
        formInputBorderError: '#BE3C3C',
        formInputCheckbox: '#11B034',
        formInputBackground: '#FFFFFF',
        formInputBackgroundSecondary: '#FFFFFF',
        formInputBorderSecondary: '#888C8D',
        formInputBackgroundDisabled: '#F8f8f8',
        formRadioEnabled: '#424547',
        formRadioDisabled: '#B3B5B5',
        formRadioBorderDisabled: '#D1D4D2',
        formRadioBorderEnabled: '#B3B5B5',
        formRadioBorderActive: '#D1D4D2',
        formListItemBackground: '#181818',
        formListItemBackgroundHover: '#181818',
        formSliderVerticalBars: '#B3B5B5',
        formSliderHandleBackground: '#000',
        formSliderHandleBorder: '#B3B5B5',
        formSliderProgressBar: '#e4fd01',
        formStatusErrorLight: '#FEF3F2',
        formStatusErrorMain: '#BE3C3C',
        formStatusErrorDark: '#AE201A',
        formStatusSuccessLight: '#F0FDF5',
        formStatusSuccessMain: '#11b034',
        formStatusSuccessDark: '#21A153',
        formStatusWarningLight: '#FEFCE9',
        formStatusWarningMain: '#ffb75d',
        formStatusWarningDark: '#975A12',
        formStatusInfoLight: '#EFF6FF',
        formStatusInfoMain: '#0073BE',
        formStatusInfoDark: '#1244CA',
        headerBackground: '#121212',
        headerStickyBorderBottom: 'rgba(227, 227, 227, 0.1)',
        headerStickyBoxShadow: 'rgba(0, 0, 0, 0.03)',
        headerStickyBoxShadowNoNotification: 'rgba(0, 0, 0, 0.25)',
        headerStickyBackground: '#121212',
        headerStickyBackgroundScrolled: '#121212',
        headerStickyBorderBottomScrolled: '#424547',
        headerSearchInputBorder: '#D1D4D2',
        headerMegamenu: '#F8f8f8',
        headerPlpSidebarTitle: '#FFFFFF',
        footerBackground: '#121212',
        footerTextColor: '#FFFFFF',
        footerHeadingColor: '',
        tableThead: '#F8f8f8',
        tableTh: '#F8f8f8',
        tableTd: '#121212',
        iconPrimary: '#FFFFFF',
        iconSecondary: '#727272',
        iconTertiary: '#D1D4D2',
        iconLoader: '#FFFFFF',
        iconRoundedBackground: '#FFFFFF',
        iconRoundedIcon: '#FFFFFF',
        iconRoundedBorder: '#FFFFFF',
        iconRoundedActiveBackground: '#FFFFFF',
        iconRoundedActiveIcon: '#FFFFFF',
        iconRoundedActiveBorder: '#FFFFFF',
        logo: '#FFFFFF',
        borderDivider1: '#424547',
        borderDivider2: '#424547',
        pillActiveTextColor: '#181818',
        pillActiveTextColorHover: '#181818',
        pillActiveBorderColor: '#000',
        pillActiveBorderColorHover: '#000',
        pillActiveBackground: '#000',
        pillActiveBackgroundHover: '#000',
        pillDefaultTextColor: '#000',
        pillDefaultTextColorHover: '#000',
        pillDefaultBorderColor: '#424547',
        pillDefaultBorderColorHover: '#424547',
        pillDefaultBackground: '#424547',
        pillDefaultBackgroundHover: '#424547',
        pillBadgeBackground: '#424547',
        pillBadgeBorderColorHover: '#424547',
        wrapperBody: '#121212',
        wrapperPublication: 'undefined',
        wrapperCategory: 'undefined',
        wrapperProduct: 'undefined',
        wrapperTileColor: 'undefined',
        wrapperTileColorHover: 'undefined',
        wrapperSection: '#121212',
        wrapperBodyHover: '#1E1e1e',
        wrapperWell: '#424547',
        wrapperCard: '#181818',
        wrapperCardHover: '#121212',
        wrapperCardGradientColor: '#181818',
        wrapperCardGradientColorHover: '#121212',
        wrapperCardBorderColor: 'transparent',
        wrapperModal: '#181818',
        wrapperBanner: '#000',
        wrapperBannerBorder: '#000',
        wrapperUsp: '#1E1e1e',
        wrapperUspColor: '#000',
        oBikeComparisonCompareButtonBackground: '#f3f3f3',
        oBikeComparisonCompareButtonBorderColor: '#B3B5B5',
        uListItemHighlightColor: '#000',
        uListItemTextColorHover: '#424547',
        uCarouselBackgroundColor: '#FFFFFF',
        uPullUpToggleBackground: '#000',
        uNewsletterTitleColor: '#FFFFFF',
        uKeyFeatureListBackgroundColor: '#EFEFEF',
        fallbackWhite: '#FFFFFF',
        fallbackGrey50: '#F8f8f8',
        fallbackGrey100: '#f3f3f3',
        fallbackGrey200: '#D1D4D2',
        fallbackGrey300: '#B3B5B5',
        fallbackGrey400: '#888C8D',
        fallbackGrey500: '#727272',
        fallbackGrey600: '#626667',
        fallbackGrey700: '#424547',
        fallbackGrey750: '#1E1e1e',
        fallbackGrey800: '#181818',
        fallbackGrey900: '#121212',
        fallbackBlack: '#000',
    },
};
