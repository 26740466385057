import React, { useEffect, useReducer, ReactNode, useState } from 'react';
import { Provider } from 'react-redux';
import { GatsbyBrowser } from 'gatsby';
import '../yupExtensions';
import {
    BikeCompareContext,
    bikeCompareReducer,
    initialBikeCompareState,
} from '../hooks/bikeCompare/useBikeCompare';
import {
    DealerOffCanvasPanelContext,
    dealerOffCanvasPanelReducer,
    initialDealerOffCanvasPanelState,
} from '../hooks/useDealerOffCanvasPanel';
import {
    BreadcrumbHistoryContext,
    breadcrumbHistoryReducer,
    initialBreadcrumbHistoryState,
} from '../hooks/useBreadcrumbHistory';
import initStoryblokClient from '../services/storyblok/initStoryblokClient';
import createReduxStore from '../services/createReduxStore';
import { COOKIE_CONSENT_ID } from '../constants/tokens';
import { CookieConsentContext, cookieConsentReducer } from '../hooks/useCookieConsentStatus';
import isBrowser from '../services/isBrowser';

const store = createReduxStore();

initStoryblokClient();

export const RootComponent: React.FC<{ children?: ReactNode }> = ({ children }) => {
    const [bikeCompareState, bikeCompareDispatch] = useReducer(
        bikeCompareReducer,
        initialBikeCompareState
    );
    const [dealerOffCanvasPanelState, dealerOffCanvasPanelDispatch] = useReducer(
        dealerOffCanvasPanelReducer,
        initialDealerOffCanvasPanelState
    );
    const [breadcrumbHistoryState, breadcrumbHistoryDispatch] = useReducer(
        breadcrumbHistoryReducer,
        initialBreadcrumbHistoryState
    );
    const [cookieConsentState, cookieConsentDispatch] = useReducer(cookieConsentReducer, []);
    const [oneTrustLoaded, setOneTrustLoaded] = useState<boolean>(false);

    // On intial load with clean cookies OneTrust is still undefined causing all component with restrictions not to be loaded even when cookies are accepted
    const oneTrustHasLoaded = (tries: number) => {
        if (tries > 10) return;
        if (
            !isBrowser() ||
            typeof window.OneTrust === 'undefined' ||
            typeof window.OnetrustActiveGroups !== 'string'
        ) {
            setTimeout(() => oneTrustHasLoaded(tries + 1), 100);
        } else {
            setOneTrustLoaded(true);
        }
    };

    const updateConsentContext = () => {
        if (
            isBrowser() &&
            typeof window.OneTrust !== 'undefined' &&
            typeof window.OnetrustActiveGroups === 'string'
        ) {
            const arrayOfConsentKeys = window.OnetrustActiveGroups
                // split the string with comma separation into an array
                .split(',')
                // filter out empty strings
                .filter(c => !!c);

            cookieConsentDispatch({
                type: 'UPDATE',
                payload: arrayOfConsentKeys,
            });
        }
    };

    useEffect(() => {
        oneTrustHasLoaded(1); // First try
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (oneTrustLoaded) {
            // Set initial consent values
            updateConsentContext();

            // Globally listen for the OneTrust "ConsentChanged" event
            if (!!COOKIE_CONSENT_ID && isBrowser() && typeof window.OneTrust !== 'undefined') {
                window.OneTrust.OnConsentChanged(() => {
                    updateConsentContext();
                });
            }
        }
    }, [oneTrustLoaded]);

    return (
        <Provider store={store}>
            <CookieConsentContext.Provider
                // eslint-disable-next-line react/jsx-no-constructed-context-values
                value={{ state: cookieConsentState, dispatch: cookieConsentDispatch }}
            >
                <BikeCompareContext.Provider
                    // eslint-disable-next-line react/jsx-no-constructed-context-values
                    value={{ state: bikeCompareState, dispatch: bikeCompareDispatch }}
                >
                    <DealerOffCanvasPanelContext.Provider
                        // eslint-disable-next-line react/jsx-no-constructed-context-values
                        value={{
                            state: dealerOffCanvasPanelState,
                            dispatch: dealerOffCanvasPanelDispatch,
                        }}
                    >
                        <BreadcrumbHistoryContext.Provider
                            // eslint-disable-next-line react/jsx-no-constructed-context-values
                            value={{
                                state: breadcrumbHistoryState,
                                dispatch: breadcrumbHistoryDispatch,
                            }}
                        >
                            {children}
                        </BreadcrumbHistoryContext.Provider>
                    </DealerOffCanvasPanelContext.Provider>
                </BikeCompareContext.Provider>
            </CookieConsentContext.Provider>
        </Provider>
    );
};

export default (({ element }) => (
    <RootComponent>{element}</RootComponent>
)) as GatsbyBrowser['wrapRootElement'];
