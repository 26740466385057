import { Countries, Languages, StoryblokAdditionalLanguages } from '../types/Countries';

export const countries: Countries = [
    'DE', // Default country
    'AT',
    'CH',
    'CZ',
    'ES',
    'FR',
    'IT',
    'NL',
    'PL',
];

export const languages: Languages = {
    DE: [
        'en', // Default country/language
        'de',
    ],
    AT: ['de', 'en'],
    CH: ['de', 'en', 'fr'],
    CZ: ['en'],
    ES: ['es', 'en'],
    FR: ['fr', 'en'],
    IT: ['it', 'en'],
    NL: ['en'],
    PL: ['en'],
};

// Override the above country/language codes with specific codes for Storyblok
export const storyblokAdditionalLanguages: StoryblokAdditionalLanguages = {};
