// note: due to its size, prevent this from being added tot he app or common bundle through code splitting
// (be careful where you import this)

export interface CountryInfo {
    name: string; // country name
    tel: string; // phone number prefix
    iso: string; // country code
}

export interface CountryInfoIndex {
    [iso: string]: CountryInfo;
}

const worldCountries: CountryInfo[] = [
    {
        name: 'Afghanistan',
        tel: '+93',
        iso: 'AF',
    },
    {
        name: 'Åland Islands',
        tel: '+358',
        iso: 'AX',
    },
    {
        name: 'Albania',
        tel: '+355',
        iso: 'AL',
    },
    {
        name: 'Algeria',
        tel: '+213',
        iso: 'DZ',
    },
    {
        name: 'American Samoa',
        tel: '+1684',
        iso: 'AS',
    },
    {
        name: 'Andorra',
        tel: '+376',
        iso: 'AD',
    },
    {
        name: 'Angola',
        tel: '+244',
        iso: 'AO',
    },
    {
        name: 'Anguilla',
        tel: '+1264',
        iso: 'AI',
    },
    {
        name: 'Antarctica',
        tel: '+672',
        iso: 'AQ',
    },
    {
        name: 'Antigua and Barbuda',
        tel: '+1268',
        iso: 'AG',
    },
    {
        name: 'Argentina',
        tel: '+54',
        iso: 'AR',
    },
    {
        name: 'Armenia',
        tel: '+374',
        iso: 'AM',
    },
    {
        name: 'Aruba',
        tel: '+297',
        iso: 'AW',
    },
    {
        name: 'Australia',
        tel: '+61',
        iso: 'AU',
    },
    {
        name: 'Austria',
        tel: '+43',
        iso: 'AT',
    },
    {
        name: 'Azerbaijan',
        tel: '+994',
        iso: 'AZ',
    },
    {
        name: 'Bahamas',
        tel: '+1242',
        iso: 'BS',
    },
    {
        name: 'Bahrain',
        tel: '+973',
        iso: 'BH',
    },
    {
        name: 'Bangladesh',
        tel: '+880',
        iso: 'BD',
    },
    {
        name: 'Barbados',
        tel: '+1246',
        iso: 'BB',
    },
    {
        name: 'Belarus',
        tel: '+375',
        iso: 'BY',
    },
    {
        name: 'Belgium',
        tel: '+32',
        iso: 'BE',
    },
    {
        name: 'Belize',
        tel: '+501',
        iso: 'BZ',
    },
    {
        name: 'Benin',
        tel: '+229',
        iso: 'BJ',
    },
    {
        name: 'Bermuda',
        tel: '+1441',
        iso: 'BM',
    },
    {
        name: 'Bhutan',
        tel: '+975',
        iso: 'BT',
    },
    {
        name: 'Bolivia, Plurinational State of bolivia',
        tel: '+591',
        iso: 'BO',
    },
    {
        name: 'Bonaire, Sint Eustatius and Saba',
        tel: '+599',
        iso: 'BQ',
    },
    {
        name: 'Bosnia and Herzegovina',
        tel: '+387',
        iso: 'BA',
    },
    {
        name: 'Botswana',
        tel: '+267',
        iso: 'BW',
    },
    {
        name: 'Bouvet Island',
        tel: '+47',
        iso: 'BV',
    },
    {
        name: 'Brazil',
        tel: '+55',
        iso: 'BR',
    },
    {
        name: 'British Indian Ocean Territory',
        tel: '+246',
        iso: 'IO',
    },
    {
        name: 'Brunei Darussalam',
        tel: '+673',
        iso: 'BN',
    },
    {
        name: 'Bulgaria',
        tel: '+359',
        iso: 'BG',
    },
    {
        name: 'Burkina Faso',
        tel: '+226',
        iso: 'BF',
    },
    {
        name: 'Burundi',
        tel: '+257',
        iso: 'BI',
    },
    {
        name: 'Cambodia',
        tel: '+855',
        iso: 'KH',
    },
    {
        name: 'Cameroon',
        tel: '+237',
        iso: 'CM',
    },
    {
        name: 'Canada',
        tel: '+1',
        iso: 'CA',
    },
    {
        name: 'Cape Verde',
        tel: '+238',
        iso: 'CV',
    },
    {
        name: 'Cayman Islands',
        tel: '+ 345',
        iso: 'KY',
    },
    {
        name: 'Central African Republic',
        tel: '+236',
        iso: 'CF',
    },
    {
        name: 'Chad',
        tel: '+235',
        iso: 'TD',
    },
    {
        name: 'Chile',
        tel: '+56',
        iso: 'CL',
    },
    {
        name: 'China',
        tel: '+86',
        iso: 'CN',
    },
    {
        name: 'Christmas Island',
        tel: '+61',
        iso: 'CX',
    },
    {
        name: 'Cocos (Keeling) Islands',
        tel: '+61',
        iso: 'CC',
    },
    {
        name: 'Colombia',
        tel: '+57',
        iso: 'CO',
    },
    {
        name: 'Comoros',
        tel: '+269',
        iso: 'KM',
    },
    {
        name: 'Congo',
        tel: '+242',
        iso: 'CG',
    },
    {
        name: 'Congo, The Democratic Republic of the Congo',
        tel: '+243',
        iso: 'CD',
    },
    {
        name: 'Cook Islands',
        tel: '+682',
        iso: 'CK',
    },
    {
        name: 'Costa Rica',
        tel: '+506',
        iso: 'CR',
    },
    {
        name: "Cote d'Ivoire",
        tel: '+225',
        iso: 'CI',
    },
    {
        name: 'Croatia',
        tel: '+385',
        iso: 'HR',
    },
    {
        name: 'Cuba',
        tel: '+53',
        iso: 'CU',
    },
    {
        name: 'Curaçao',
        tel: '+599',
        iso: 'CW',
    },
    {
        name: 'Cyprus',
        tel: '+357',
        iso: 'CY',
    },
    {
        name: 'Czech Republic',
        tel: '+420',
        iso: 'CZ',
    },
    {
        name: 'Denmark',
        tel: '+45',
        iso: 'DK',
    },
    {
        name: 'Djibouti',
        tel: '+253',
        iso: 'DJ',
    },
    {
        name: 'Dominica',
        tel: '+1767',
        iso: 'DM',
    },
    {
        name: 'Dominican Republic',
        tel: '+1849',
        iso: 'DO',
    },
    {
        name: 'Ecuador',
        tel: '+593',
        iso: 'EC',
    },
    {
        name: 'Egypt',
        tel: '+20',
        iso: 'EG',
    },
    {
        name: 'El Salvador',
        tel: '+503',
        iso: 'SV',
    },
    {
        name: 'Equatorial Guinea',
        tel: '+240',
        iso: 'GQ',
    },
    {
        name: 'Eritrea',
        tel: '+291',
        iso: 'ER',
    },
    {
        name: 'Estonia',
        tel: '+372',
        iso: 'EE',
    },
    {
        name: 'Ethiopia',
        tel: '+251',
        iso: 'ET',
    },
    {
        name: 'Falkland Islands (Malvinas)',
        tel: '+500',
        iso: 'FK',
    },
    {
        name: 'Faroe Islands',
        tel: '+298',
        iso: 'FO',
    },
    {
        name: 'Fiji',
        tel: '+679',
        iso: 'FJ',
    },
    {
        name: 'Finland',
        tel: '+358',
        iso: 'FI',
    },
    {
        name: 'France',
        tel: '+33',
        iso: 'FR',
    },
    {
        name: 'French Guiana',
        tel: '+594',
        iso: 'GF',
    },
    {
        name: 'French Polynesia',
        tel: '+689',
        iso: 'PF',
    },
    {
        name: 'French Southern Territories',
        tel: '+262',
        iso: 'TF',
    },
    {
        name: 'Gabon',
        tel: '+241',
        iso: 'GA',
    },
    {
        name: 'Gambia',
        tel: '+220',
        iso: 'GM',
    },
    {
        name: 'Georgia',
        tel: '+995',
        iso: 'GE',
    },
    {
        name: 'Germany',
        tel: '+49',
        iso: 'DE',
    },
    {
        name: 'Ghana',
        tel: '+233',
        iso: 'GH',
    },
    {
        name: 'Gibraltar',
        tel: '+350',
        iso: 'GI',
    },
    {
        name: 'Greece',
        tel: '+30',
        iso: 'GR',
    },
    {
        name: 'Greenland',
        tel: '+299',
        iso: 'GL',
    },
    {
        name: 'Grenada',
        tel: '+1473',
        iso: 'GD',
    },
    {
        name: 'Guadeloupe',
        tel: '+590',
        iso: 'GP',
    },
    {
        name: 'Guam',
        tel: '+1671',
        iso: 'GU',
    },
    {
        name: 'Guatemala',
        tel: '+502',
        iso: 'GT',
    },
    {
        name: 'Guernsey',
        tel: '+44',
        iso: 'GG',
    },
    {
        name: 'Guinea',
        tel: '+224',
        iso: 'GN',
    },
    {
        name: 'Guinea-Bissau',
        tel: '+245',
        iso: 'GW',
    },
    {
        name: 'Guyana',
        tel: '+592',
        iso: 'GY',
    },
    {
        name: 'Haiti',
        tel: '+509',
        iso: 'HT',
    },
    {
        name: 'Heard Island and Mcdonald Islands',
        tel: '+0',
        iso: 'HM',
    },
    {
        name: 'Holy See (Vatican City State)',
        tel: '+379',
        iso: 'VA',
    },
    {
        name: 'Honduras',
        tel: '+504',
        iso: 'HN',
    },
    {
        name: 'Hong Kong',
        tel: '+852',
        iso: 'HK',
    },
    {
        name: 'Hungary',
        tel: '+36',
        iso: 'HU',
    },
    {
        name: 'Iceland',
        tel: '+354',
        iso: 'IS',
    },
    {
        name: 'India',
        tel: '+91',
        iso: 'IN',
    },
    {
        name: 'Indonesia',
        tel: '+62',
        iso: 'ID',
    },
    {
        name: 'Iran, Islamic Republic of Persian Gulf',
        tel: '+98',
        iso: 'IR',
    },
    {
        name: 'Iraq',
        tel: '+964',
        iso: 'IQ',
    },
    {
        name: 'Ireland',
        tel: '+353',
        iso: 'IE',
    },
    {
        name: 'Isle of Man',
        tel: '+44',
        iso: 'IM',
    },
    {
        name: 'Israel',
        tel: '+972',
        iso: 'IL',
    },
    {
        name: 'Italy',
        tel: '+39',
        iso: 'IT',
    },
    {
        name: 'Jamaica',
        tel: '+1876',
        iso: 'JM',
    },
    {
        name: 'Japan',
        tel: '+81',
        iso: 'JP',
    },
    {
        name: 'Jersey',
        tel: '+44',
        iso: 'JE',
    },
    {
        name: 'Jordan',
        tel: '+962',
        iso: 'JO',
    },
    {
        name: 'Kazakhstan',
        tel: '+7',
        iso: 'KZ',
    },
    {
        name: 'Kenya',
        tel: '+254',
        iso: 'KE',
    },
    {
        name: 'Kiribati',
        tel: '+686',
        iso: 'KI',
    },
    {
        name: "Korea, Democratic People's Republic of Korea",
        tel: '+850',
        iso: 'KP',
    },
    {
        name: 'Korea, Republic of South Korea',
        tel: '+82',
        iso: 'KR',
    },
    {
        name: 'Kosovo',
        tel: '+383',
        iso: 'XK',
    },
    {
        name: 'Kuwait',
        tel: '+965',
        iso: 'KW',
    },
    {
        name: 'Kyrgyzstan',
        tel: '+996',
        iso: 'KG',
    },
    {
        name: 'Laos',
        tel: '+856',
        iso: 'LA',
    },
    {
        name: 'Latvia',
        tel: '+371',
        iso: 'LV',
    },
    {
        name: 'Lebanon',
        tel: '+961',
        iso: 'LB',
    },
    {
        name: 'Lesotho',
        tel: '+266',
        iso: 'LS',
    },
    {
        name: 'Liberia',
        tel: '+231',
        iso: 'LR',
    },
    {
        name: 'Libyan Arab Jamahiriya',
        tel: '+218',
        iso: 'LY',
    },
    {
        name: 'Liechtenstein',
        tel: '+423',
        iso: 'LI',
    },
    {
        name: 'Lithuania',
        tel: '+370',
        iso: 'LT',
    },
    {
        name: 'Luxembourg',
        tel: '+352',
        iso: 'LU',
    },
    {
        name: 'Macao',
        tel: '+853',
        iso: 'MO',
    },
    {
        name: 'Macedonia',
        tel: '+389',
        iso: 'MK',
    },
    {
        name: 'Madagascar',
        tel: '+261',
        iso: 'MG',
    },
    {
        name: 'Malawi',
        tel: '+265',
        iso: 'MW',
    },
    {
        name: 'Malaysia',
        tel: '+60',
        iso: 'MY',
    },
    {
        name: 'Maldives',
        tel: '+960',
        iso: 'MV',
    },
    {
        name: 'Mali',
        tel: '+223',
        iso: 'ML',
    },
    {
        name: 'Malta',
        tel: '+356',
        iso: 'MT',
    },
    {
        name: 'Marshall Islands',
        tel: '+692',
        iso: 'MH',
    },
    {
        name: 'Martinique',
        tel: '+596',
        iso: 'MQ',
    },
    {
        name: 'Mauritania',
        tel: '+222',
        iso: 'MR',
    },
    {
        name: 'Mauritius',
        tel: '+230',
        iso: 'MU',
    },
    {
        name: 'Mayotte',
        tel: '+262',
        iso: 'YT',
    },
    {
        name: 'Mexico',
        tel: '+52',
        iso: 'MX',
    },
    {
        name: 'Micronesia, Federated States of Micronesia',
        tel: '+691',
        iso: 'FM',
    },
    {
        name: 'Moldova',
        tel: '+373',
        iso: 'MD',
    },
    {
        name: 'Monaco',
        tel: '+377',
        iso: 'MC',
    },
    {
        name: 'Mongolia',
        tel: '+976',
        iso: 'MN',
    },
    {
        name: 'Montenegro',
        tel: '+382',
        iso: 'ME',
    },
    {
        name: 'Montserrat',
        tel: '+1664',
        iso: 'MS',
    },
    {
        name: 'Morocco',
        tel: '+212',
        iso: 'MA',
    },
    {
        name: 'Mozambique',
        tel: '+258',
        iso: 'MZ',
    },
    {
        name: 'Myanmar',
        tel: '+95',
        iso: 'MM',
    },
    {
        name: 'Namibia',
        tel: '+264',
        iso: 'NA',
    },
    {
        name: 'Nauru',
        tel: '+674',
        iso: 'NR',
    },
    {
        name: 'Nepal',
        tel: '+977',
        iso: 'NP',
    },
    {
        name: 'Netherlands',
        tel: '+31',
        iso: 'NL',
    },
    {
        name: 'New Caledonia',
        tel: '+687',
        iso: 'NC',
    },
    {
        name: 'New Zealand',
        tel: '+64',
        iso: 'NZ',
    },
    {
        name: 'Nicaragua',
        tel: '+505',
        iso: 'NI',
    },
    {
        name: 'Niger',
        tel: '+227',
        iso: 'NE',
    },
    {
        name: 'Nigeria',
        tel: '+234',
        iso: 'NG',
    },
    {
        name: 'Niue',
        tel: '+683',
        iso: 'NU',
    },
    {
        name: 'Norfolk Island',
        tel: '+672',
        iso: 'NF',
    },
    {
        name: 'Northern Mariana Islands',
        tel: '+1670',
        iso: 'MP',
    },
    {
        name: 'Norway',
        tel: '+47',
        iso: 'NO',
    },
    {
        name: 'Oman',
        tel: '+968',
        iso: 'OM',
    },
    {
        name: 'Pakistan',
        tel: '+92',
        iso: 'PK',
    },
    {
        name: 'Palau',
        tel: '+680',
        iso: 'PW',
    },
    {
        name: 'Palestinian Territory, Occupied',
        tel: '+970',
        iso: 'PS',
    },
    {
        name: 'Panama',
        tel: '+507',
        iso: 'PA',
    },
    {
        name: 'Papua New Guinea',
        tel: '+675',
        iso: 'PG',
    },
    {
        name: 'Paraguay',
        tel: '+595',
        iso: 'PY',
    },
    {
        name: 'Peru',
        tel: '+51',
        iso: 'PE',
    },
    {
        name: 'Philippines',
        tel: '+63',
        iso: 'PH',
    },
    {
        name: 'Pitcairn',
        tel: '+64',
        iso: 'PN',
    },
    {
        name: 'Poland',
        tel: '+48',
        iso: 'PL',
    },
    {
        name: 'Portugal',
        tel: '+351',
        iso: 'PT',
    },
    {
        name: 'Puerto Rico',
        tel: '+1939',
        iso: 'PR',
    },
    {
        name: 'Qatar',
        tel: '+974',
        iso: 'QA',
    },
    {
        name: 'Romania',
        tel: '+40',
        iso: 'RO',
    },
    {
        name: 'Russia',
        tel: '+7',
        iso: 'RU',
    },
    {
        name: 'Rwanda',
        tel: '+250',
        iso: 'RW',
    },
    {
        name: 'Reunion',
        tel: '+262',
        iso: 'RE',
    },
    {
        name: 'Saint Barthelemy',
        tel: '+590',
        iso: 'BL',
    },
    {
        name: 'Saint Helena, Ascension and Tristan Da Cunha',
        tel: '+290',
        iso: 'SH',
    },
    {
        name: 'Saint Kitts and Nevis',
        tel: '+1869',
        iso: 'KN',
    },
    {
        name: 'Saint Lucia',
        tel: '+1758',
        iso: 'LC',
    },
    {
        name: 'Saint Martin',
        tel: '+590',
        iso: 'MF',
    },
    {
        name: 'Saint Pierre and Miquelon',
        tel: '+508',
        iso: 'PM',
    },
    {
        name: 'Saint Vincent and the Grenadines',
        tel: '+1784',
        iso: 'VC',
    },
    {
        name: 'Samoa',
        tel: '+685',
        iso: 'WS',
    },
    {
        name: 'San Marino',
        tel: '+378',
        iso: 'SM',
    },
    {
        name: 'Sao Tome and Principe',
        tel: '+239',
        iso: 'ST',
    },
    {
        name: 'Saudi Arabia',
        tel: '+966',
        iso: 'SA',
    },
    {
        name: 'Senegal',
        tel: '+221',
        iso: 'SN',
    },
    {
        name: 'Serbia',
        tel: '+381',
        iso: 'RS',
    },
    {
        name: 'Seychelles',
        tel: '+248',
        iso: 'SC',
    },
    {
        name: 'Sierra Leone',
        tel: '+232',
        iso: 'SL',
    },
    {
        name: 'Singapore',
        tel: '+65',
        iso: 'SG',
    },
    {
        name: 'Sint Maarten (Dutch part)',
        tel: '+599',
        iso: 'SX',
    },
    {
        name: 'Slovakia',
        tel: '+421',
        iso: 'SK',
    },
    {
        name: 'Slovenia',
        tel: '+386',
        iso: 'SI',
    },
    {
        name: 'Solomon Islands',
        tel: '+677',
        iso: 'SB',
    },
    {
        name: 'Somalia',
        tel: '+252',
        iso: 'SO',
    },
    {
        name: 'South Africa',
        tel: '+27',
        iso: 'ZA',
    },
    {
        name: 'South Sudan',
        tel: '+211',
        iso: 'SS',
    },
    {
        name: 'South Georgia and the South Sandwich Islands',
        tel: '+500',
        iso: 'GS',
    },
    {
        name: 'Spain',
        tel: '+34',
        iso: 'ES',
    },
    {
        name: 'Sri Lanka',
        tel: '+94',
        iso: 'LK',
    },
    {
        name: 'Sudan',
        tel: '+249',
        iso: 'SD',
    },
    {
        name: 'Suriname',
        tel: '+597',
        iso: 'SR',
    },
    {
        name: 'Svalbard and Jan Mayen',
        tel: '+47',
        iso: 'SJ',
    },
    {
        name: 'Swaziland',
        tel: '+268',
        iso: 'SZ',
    },
    {
        name: 'Sweden',
        tel: '+46',
        iso: 'SE',
    },
    {
        name: 'Switzerland',
        tel: '+41',
        iso: 'CH',
    },
    {
        name: 'Syrian Arab Republic',
        tel: '+963',
        iso: 'SY',
    },
    {
        name: 'Taiwan',
        tel: '+886',
        iso: 'TW',
    },
    {
        name: 'Tajikistan',
        tel: '+992',
        iso: 'TJ',
    },
    {
        name: 'Tanzania, United Republic of Tanzania',
        tel: '+255',
        iso: 'TZ',
    },
    {
        name: 'Thailand',
        tel: '+66',
        iso: 'TH',
    },
    {
        name: 'Timor-Leste',
        tel: '+670',
        iso: 'TL',
    },
    {
        name: 'Togo',
        tel: '+228',
        iso: 'TG',
    },
    {
        name: 'Tokelau',
        tel: '+690',
        iso: 'TK',
    },
    {
        name: 'Tonga',
        tel: '+676',
        iso: 'TO',
    },
    {
        name: 'Trinidad and Tobago',
        tel: '+1868',
        iso: 'TT',
    },
    {
        name: 'Tunisia',
        tel: '+216',
        iso: 'TN',
    },
    {
        name: 'Turkey',
        tel: '+90',
        iso: 'TR',
    },
    {
        name: 'Turkmenistan',
        tel: '+993',
        iso: 'TM',
    },
    {
        name: 'Turks and Caicos Islands',
        tel: '+1649',
        iso: 'TC',
    },
    {
        name: 'Tuvalu',
        tel: '+688',
        iso: 'TV',
    },
    {
        name: 'Uganda',
        tel: '+256',
        iso: 'UG',
    },
    {
        name: 'Ukraine',
        tel: '+380',
        iso: 'UA',
    },
    {
        name: 'United Arab Emirates',
        tel: '+971',
        iso: 'AE',
    },
    {
        name: 'United Kingdom',
        tel: '+44',
        iso: 'GB',
    },
    {
        name: 'United States',
        tel: '+1',
        iso: 'US',
    },
    {
        name: 'United States Minor Outlying Islands',
        tel: '+599',
        iso: 'UM',
    },
    {
        name: 'Uruguay',
        tel: '+598',
        iso: 'UY',
    },
    {
        name: 'Uzbekistan',
        tel: '+998',
        iso: 'UZ',
    },
    {
        name: 'Vanuatu',
        tel: '+678',
        iso: 'VU',
    },
    {
        name: 'Venezuela, Bolivarian Republic of Venezuela',
        tel: '+58',
        iso: 'VE',
    },
    {
        name: 'Vietnam',
        tel: '+84',
        iso: 'VN',
    },
    {
        name: 'Virgin Islands, British',
        tel: '+1284',
        iso: 'VG',
    },
    {
        name: 'Virgin Islands, U.S.',
        tel: '+1340',
        iso: 'VI',
    },
    {
        name: 'Wallis and Futuna',
        tel: '+681',
        iso: 'WF',
    },
    {
        name: 'Western Sahara',
        tel: '+599',
        iso: 'EH',
    },
    {
        name: 'Yemen',
        tel: '+967',
        iso: 'YE',
    },
    {
        name: 'Zambia',
        tel: '+260',
        iso: 'ZM',
    },
    {
        name: 'Zimbabwe',
        tel: '+263',
        iso: 'ZW',
    },
];

let indexed: CountryInfoIndex | void;

const buildIndex = (): CountryInfoIndex => {
    const index: CountryInfoIndex = {};
    for (const country of worldCountries) {
        index[country.iso] = country;
    }
    return index;
};

export const getCountryInfo = (country: string): CountryInfo => {
    indexed = indexed ?? buildIndex();
    return (indexed as CountryInfoIndex)[country];
};

export default worldCountries;
