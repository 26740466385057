// Generated file, do not edit.
// Edit the json files in the designTokens directory and run the build-design-tokens.js script.

export default {
    colorBaseNeutralBlack: '#000000',
    colorBaseNeutralGrey900: '#121212',
    colorBaseNeutralGrey800: '#181818',
    colorBaseNeutralGrey750: '#1E1E1E',
    colorBaseNeutralGrey700: '#333333',
    colorBaseNeutralGrey600: '#4F4F4F',
    colorBaseNeutralGrey500: '#727272',
    colorBaseNeutralGrey400: '#999999',
    colorBaseNeutralGrey300: '#C6C6C6',
    colorBaseNeutralGrey200: '#E3E3E3',
    colorBaseNeutralGrey100: '#F3F3F3',
    colorBaseNeutralGrey50: '#F9F9F9',
    colorBaseNeutralWhite: '#FFFFFF',
    mediaQueryS: 576,
    mediaQueryM: 768,
    mediaQueryL: 992,
    mediaQueryXl: 1200,
    mediaQueryXxl: 1600,
    motionEaseOut: 'cubic-bezier(0, 0, 0.25, 1)',
    motionEaseIn: 'cubic-bezier(0.50, 0, 1, 1)',
    motionEaseInOut: 'cubic-bezier(0.25, 0.75, 0.25, 0.75)',
    lightTheme: {},
    darkTheme: {},
};
